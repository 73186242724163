import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
//import Table from '@material-ui/core/Table';
//import TableBody from '@material-ui/core/TableBody';
//import TableCell from '@material-ui/core/TableCell';
//import TableContainer from '@material-ui/core/TableContainer';
//import TableHead from '@material-ui/core/TableHead';
//import TableRow from '@material-ui/core/TableRow';
//import Paper from '@material-ui/core/Paper';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
//import ButtonNewRegister from '../button/ButtonNewRegister'
import EditIcon from '@material-ui/icons/Edit';
import AuditDialog from '../dialogs/AuditDialog';
import { Grid, Typography } from '@material-ui/core';
import Progress from '../progress/Progress';
import AlertDialog from '../dialogs/AlertDialog';
//import ButtonFilter from '../button/ButtonFilter';
import './Table.css';
//import { Redirect } from 'react-router-dom';
import ButtonSearchAmountPersons from '../button/ButtonSearchAmountPersons';
//import ButtonNewPersonBudgeted from '../button/ButtonNewPersonBudgeted';
import KeyValue from '../../config/KeyValue';
import UnauthorizedDialog from '../dialogs/UnauthorizedDialog';
import PersonBudgetedDialog from '../dialogs/PersonBudgetedDialog';
import ButtonAddRegisterBudgeted from '../button/ButtonAddRegisterBudgeted';
import MaterialTable, {MTablePagination } from "material-table";
import Tooltip from '@material-ui/core/Tooltip';
import ButtonDownloadBudgeted from '../button/ButtonDownloadBudgeted';
import { EmojiObjectsRounded } from '@material-ui/icons';

const useStyles = makeStyles({
  table: {
    minWidth: '100%',
  },
  tableContainer:{
    borderRadius: 10,
    backgroundColor: "#efefef",
    padding: 19,
    display: "block !important"
  }
});

export default function TableBudgeted(props) {       

  const classes = useStyles();
  //---
  const [listRegister, setListRegister] = React.useState([]);
  const [listDetailAudit, setListDetailAudit] = React.useState([]);
  const [codeBudgeted, setCodeBudgeted] = React.useState('');
  const [amountBudgeted, setAmountBudgeted] = React.useState('');
  const [showPersonBudgetedDialog, setShowPersonBudgetedDialog] = React.useState(false);
  const [initEditBudgetedDialog, setInitEditBudgetedDialog] = React.useState(0);
  const [auditShow, setAuditShow] = React.useState(false)
  //------------------------flag vencimiento de token------------------------------
  const [showUnauthorized, setShowUnauthorized] = React.useState(false);
  //----loader----
  const [loading, setLoading] = React.useState(false);
  //---message error-----
  const [show, setShow] = React.useState(false);
  const [messageError, setMessageError] = React.useState(false);    
  //---------
  const handleClose = () => {
      setShow(false)       
  };
  const ShowDialogMessage = (message) =>{
      setShow(true);
      setMessageError(message);
  }
  //----------- impl method async ------------------------------------
  const asyncCallMethod = (typeCall,obj,methodAction,typeAction) =>{
      const URL_BASE = process.env.REACT_APP_URL_BASE_PORT;
      const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN) 

      const paramsHeadersPost = {
          method:typeCall,
          mode: 'cors',
          headers: {
              'Accept':'application/json',
              'Authorization': 'Bearer ' + tokenLocalStorage,
              'Content-type': 'application/json'
          },
          body: JSON.stringify(obj)
      }
      const paramsHeadersGet = {
          method:typeCall,
          mode: 'cors',
          headers: {
              'Accept':'application/json',
              'Authorization': 'Bearer ' + tokenLocalStorage,
              'Content-type': 'application/json'
          }
      }
      const header = obj !== null ? paramsHeadersPost : paramsHeadersGet ;
      const URL = URL_BASE+methodAction;    
      setLoading(true);    
          try{
              fetch(URL,header).then(function(res){

                if(res.status === 401){
                    return 401;
                }
                return res.json();

              }).then(function(response){    
              setLoading(false);

              if(response === 401){
                  setShowUnauthorized(true);

              }else if(response.Code === 0){
                  SetConfigResponse(response,typeAction);                    

              }else if (response.Code === 1){
                  errorCode1(response);
          
              }else if (response.Code === 2){
                  errorCode2(response);
              }
              }).catch((error) =>{
              setLoading(false);
              console.log(error);
              })
          }catch(e){
          setLoading(false);
          console.log(e);
          }                 
  }
  const asyncCallMethodDownloadExcel = (typeCall,obj,methodAction,typeAction) =>{
    const URL_BASE = process.env.REACT_APP_URL_BASE_PORT;
    const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN) 

    const paramsHeadersPost = {
        method:typeCall,
        mode: 'cors',
        headers: {
            //'Accept':'application/json',
            'Authorization': 'Bearer ' + tokenLocalStorage,
            'Content-type': 'application/json'
        },
        body: JSON.stringify(obj)
    }
    const header = paramsHeadersPost
    const URL = URL_BASE+methodAction;    
    setLoading(true);    
        try{
            fetch(URL,header).then(function(res){
              //console.log(res.blob());
              if(res.status === 401){
                  return 401;
              }
              return res.blob();
            }).then(function(response){                
                setLoading(false);                
                if(response === 401){
                    setShowUnauthorized(true);
                }else {
                    ResponseDownloadExcelBudgeted(response);
                }
            }).catch((error) =>{
              setLoading(false);
              //ShowDialogMessage(error)
              console.log(error);
            })
        }catch(e){
            setLoading(false);
            console.log(e);
        }                 
  }
  const SetConfigResponse = (response,typeAction)=>{
      if(typeAction === KeyValue.ACTION_GET_LIST_BUDGETED){
          ResponseGetListBudgeted(response);
      }else if (typeAction === KeyValue.ACTION_GET_DETAIL_AUDIT){
        ResponseGetAuditDetail(response);
      } else if (typeAction === KeyValue.ACTION_DOWNLOAD_EXCEL_BUDGETED){
        ResponseDownloadExcelBudgeted(response)
      }
  }
  const errorCode1 = (response) =>{
      var message = "";
      if(response.Data.StatusCode === 500){
          ShowDialogMessage(response.Data.Message);
      }
      else if(response.Data.Errors.length > 0){
          response.Data.Errors.forEach(element => {
              //console.log(element);
              message = message ` ${element}.`
          });
          ShowDialogMessage(message);                        
      }
  }
  const errorCode2 = (response) =>{
      var message = "";
      if(response.Data.Errors.length > 0){
          response.Data.Errors.forEach(element => {
              //console.log(element);
              message = message +  ` ${element.FieldName}. ${element.Message}. `
          });
          ShowDialogMessage(message);                        
      }
  }
  //-----------------------------------------
/*
  const eventListner = React.useCallback(() => {
    //console.log(state); // use the stateful variable in event listener
  }, [props.AreaId]);*/
  
  React.useEffect(() =>{
    
    if(localStorage.getItem(KeyValue.KEY_ACCESS_SERVICE_RESPONSE) === KeyValue.KEY_APP_ACCESS_BUDGET_AUTHORIZED){
      var obj = {
        FechaDesde: props.dateRegisterInit.toString(), 
        FechaHasta: props.dateRegisterFinal.toString(), 
        AreaId:props.AreaId,
        EmpresaId:props.EmpresaId,
        DiaId: parseInt(props.DiaId)
      }
      console.log("use effect table budgeted")
      console.log(obj);
      GetListPersonBudgeted(obj);
    }    
  },[/*eventListner*/])
  
  /** LISTADO DE PERSONAS PRESUPUESTADAS */
  const GetListPersonBudgeted = (obj) =>{
    if(localStorage.getItem(KeyValue.KEY_ACCESS_SERVICE_RESPONSE) === KeyValue.KEY_APP_ACCESS_BUDGET_AUTHORIZED){
      const methodAction = process.env.REACT_APP_API_GET_LIST_BUDGETED;
      asyncCallMethod("post",obj,methodAction,KeyValue.ACTION_GET_LIST_BUDGETED);
    }    
  }
  const ResponseGetListBudgeted = (response) => {
    console.log(response);
    setListRegister(response.Data);
  }
  /** EDICION DE CANTIDAD DE PERSONAS PRESUPUESTADAS */
  const handleEditBudgeted = (row) => {
    //console.log('code');
    //console.log(row.PresupuestoCode);
    setCodeBudgeted(row.PresupuestoCode);
    setAmountBudgeted(row.NroPresupuesto);
    setInitEditBudgetedDialog(1);
    setShowPersonBudgetedDialog(true);    
  }

  /** REALIZAR FILTRO */
  const handleFilter = () =>{
    if(props.dateRegisterInit > props.dateRegisterFinal){
      ShowDialogMessage('La fecha inicial es mayor que la fecha final.');
    }else{
      var obj = {
        FechaDesde: props.dateRegisterInit.toString(), 
        FechaHasta: props.dateRegisterFinal.toString(), 
        AreaId:props.AreaId,
        EmpresaId:props.EmpresaId,
        DiaId: parseInt(props.DiaId)
      }
      console.log(obj);
      GetListPersonBudgeted(obj);
    }    
  }
  /********* */
  const handleCloseEditBudgetedDialog = () =>{
    setShowPersonBudgetedDialog(false);
    setInitEditBudgetedDialog(0)
    handleFilter();
  };
  const handleShowAudit = (row) => {
    console.log(row.PresupuestoCode)
    GetAuditDetail(row.PresupuestoCode);
    setAuditShow(true)
  }
  const GetAuditDetail = (code) => {
    const methodAction = process.env.REACT_APP_API_GET_AUDIT_DETAIL + code;
    asyncCallMethod('get',null,methodAction,KeyValue.ACTION_GET_DETAIL_AUDIT);
  }
  const ResponseGetAuditDetail = (response) =>{
    setListDetailAudit(response.Data);
  }
  const handleCloseAuditDialog = () =>{
    setAuditShow(false)
  }
  const toBool = (string) =>{
    if(string === 'true'){
        return true;
      } else {
        return false;
      }
  }
  /*** descarga de excel */
  const handleDownloadExcelBudgeted = () =>{
    if(props.dateRegisterInit > props.dateRegisterFinal){
      ShowDialogMessage('La fecha inicial es mayor que la fecha final.');
    }else{
      const obj = {
        FechaDesde: props.dateRegisterInit.toString(), 
        FechaHasta: props.dateRegisterFinal.toString(), 
        AreaId:props.AreaId,
        EmpresaId:props.EmpresaId,
        DiaId: parseInt(props.DiaId)
      }
      console.log(obj);
      const methodAction = process.env.REACT_APP_API_DOWNLOAD_EXCEL_BUDGETED;
      asyncCallMethodDownloadExcel('post',obj,methodAction,KeyValue.ACTION_DOWNLOAD_EXCEL_BUDGETED);
      //asyncCallMethod('post',obj,methodAction,KeyValue.ACTION_DOWNLOAD_EXCEL_BUDGETED);
    }    
  }

  const ResponseDownloadExcelBudgeted = (blob) =>{
     // Create blob link to download

     console.log("response download excel")
     console.log(blob)
     const url = window.URL.createObjectURL(
      new Blob([blob]),
    );
    console.log(url);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      'ListaPresupuestoExcel.xlsx',
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    console.log("Clean up and remove the link");
    link.parentNode.removeChild(link);

  }

  /********* */
  

  return (
    <Grid container >

      {
        toBool(localStorage.getItem(KeyValue.KEY_APP_ES_USUARIO_VISOR)) ? (
          <Grid item xs={5} md={8}></Grid>
        ):(
          <Grid item xs={12} md={5}></Grid>
        )
      }

      <Grid item xs={7} md={4} style={{marginTop:5}}>
        <ButtonSearchAmountPersons handleClick={handleFilter} />
      </Grid>
      {
        toBool(localStorage.getItem(KeyValue.KEY_APP_ES_USUARIO_VISOR)) ? (
          ''
        ):(
          <Grid item xs={5} md={3} style={{marginTop:5}}>
            <ButtonAddRegisterBudgeted name={"Registrar"} handleClick={props.handleClickPersonNewBudgeted} />
          </Grid>
        )
      }      

      <Grid item xs={12} className="style-bettween-div" style={{marginTop:10}}>
        <Typography variant="body2" className="alignSubTitle">
            Cantidad de Registros: {listRegister.length}
        </Typography>
      </Grid>

      {/**tabla material */}
      <Grid item xs={12} style={{marginTop:10}}>
        <Grid id={"mdmdmdd"} container style={{overflow: 'auto', minHeight:150}} className={classes.tableContainer /*+ " heightCustom2" */ } >
          
          {
            toBool(localStorage.getItem(KeyValue.KEY_APP_ES_USUARIO_VISOR)) ? (
              
              <MaterialTable 
            columns={[
              { title: 'Fecha', field: 'CFechaPresupuesto',align: 'left',
                cellStyle: {
                  fontSize:'11pt'
                },
              },
              { title: 'Dia', field: 'DiaNombre',align: 'left',
                cellStyle: {
                  fontSize:'11pt'
                },
              },
              { title: 'Area', field: 'AreaNombre' ,align: 'left',
                cellStyle: {
                  fontSize:'11pt'
                },
              },
              { title: 'Empresa', field: 'EmpresaNombre',align: 'left' ,
                cellStyle: {
                  fontSize:'11pt'
                },
              },
              { title: 'Cantidad', field: 'NroPresupuesto',align: 'left' ,
                cellStyle: {
                  fontSize:'11pt'
                },
              },
              {
                //field: 'rowData',
                title: 'Auditoría',
                //tooltip: 'Delete User',
                align: 'left',
                render: rowData => <Tooltip title="Ver" arrow><RemoveRedEyeIcon style={{color:'#009DE4',cursor:'pointer'}} onClick={e => handleShowAudit(rowData)}/></Tooltip>
              }             
            ]}
            data={listRegister}

            options={{
              actionsColumnIndex: -1,
              search: false,
              headerStyle: {
                backgroundColor: '#009DE4',
                color: '#FFF'
              }
            }}
            localization={{
              body: {
                emptyDataSourceMessage: 'No hay registros para mostrar.'
              },
              toolbar: {
                searchTooltip: 'Buscar'
              },
              pagination: {
                labelRowsSelect: 'Filas',
                labelDisplayedRows: '{count} de {from} - {to}',
                firstTooltip: 'Primera página',
                previousTooltip: 'Página anterior',
                nextTooltip: 'Próxima página',
                lastTooltip: 'Última página'
              }
            }}
          />



            ):(


              <MaterialTable 
            columns={[
              { title: 'Fecha', field: 'CFechaPresupuesto',align: 'left',
                cellStyle: {
                  fontSize:'11pt'
                },
              },
              { title: 'Dia', field: 'DiaNombre',align: 'left',
                cellStyle: {
                  fontSize:'11pt'
                },
              },
              { title: 'Area', field: 'AreaNombre' ,align: 'left',
                cellStyle: {
                  fontSize:'11pt'
                },
              },
              { title: 'Empresa', field: 'EmpresaNombre',align: 'left' ,
                cellStyle: {
                  fontSize:'11pt'
                },
              },
              { title: 'Cantidad', field: 'NroPresupuesto',align: 'left' ,
                cellStyle: {
                  fontSize:'11pt'
                },
              },
              {
                //field: 'rowData',
                title: 'Editar',
                //tooltip: 'Delete User',
                align: 'left',
                render: rowData => <Tooltip title="Ver" arrow><EditIcon style={{color:'#009DE4',cursor:'pointer'}} onClick={e => handleEditBudgeted(rowData)}/></Tooltip>
              },
              {
                //field: 'rowData',
                title: 'Auditoría',
                //tooltip: 'Delete User',
                align: 'left',
                render: rowData => <Tooltip title="Ver" arrow><RemoveRedEyeIcon style={{color:'#009DE4',cursor:'pointer'}} onClick={e => handleShowAudit(rowData)}/></Tooltip>
              }             
            ]}
            data={listRegister}

            options={{
              actionsColumnIndex: -1,
              search: false,
              headerStyle: {
                backgroundColor: '#009DE4',
                color: '#FFF'
              }
            }}
            localization={{
              body: {
                emptyDataSourceMessage: 'No hay registros para mostrar.'
              },
              toolbar: {
                searchTooltip: 'Buscar'
              },
              pagination: {
                labelRowsSelect: 'Filas',
                labelDisplayedRows: '{count} de {from} - {to}',
                firstTooltip: 'Primera página',
                previousTooltip: 'Página anterior',
                nextTooltip: 'Próxima página',
                lastTooltip: 'Última página'
              }
            }}
          />
            )
          }         
          
          
        </Grid>         
      </Grid>      
      <Grid item xs={12} md={8} className="style-bettween-div" style={{marginTop:10}}>
        <Typography variant="body2" className="alignSubTitle">
            Cantidad de Registros: {listRegister.length}
        </Typography>
      </Grid>
      
      <Grid item xs={12} md={4} className="style-bettween-div" style={{marginTop:10}}>
        <ButtonDownloadBudgeted onClick={handleDownloadExcelBudgeted}/>
      </Grid>

      <AlertDialog open={show} message={messageError} handleClose={handleClose}/>
      <Progress open={loading} />
      <UnauthorizedDialog open={showUnauthorized} />
      <PersonBudgetedDialog 
        open={showPersonBudgetedDialog}
        amount={amountBudgeted} 
        code={codeBudgeted} 
        init={initEditBudgetedDialog}
        handleClose={handleCloseEditBudgetedDialog} />
      <AuditDialog 
        open={auditShow} 
        handleClose={handleCloseAuditDialog}
        list={listDetailAudit}/>  
    </Grid>
      
    );
}
