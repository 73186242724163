import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import TableFilter from '../table/TableFilter';
import DateFilterCustom from './DateFilterCustom';
import Progress from '../progress/Progress';
import AlertDialog from '../dialogs/AlertDialog';
import moment from 'moment'
import './Record.css';
import TokenExpired from '../constantes/TokenExpired';

const Record = () => {
    //---local storage
    const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN) 
    //--- para obtener fecha actual 
    var fechaActual = moment().format("YYYY-MM-DD[T]HH:mm:ss");
    var fechJson = JSON.stringify(fechaActual);
    var fechaString = JSON.parse(fechJson)

    //---fecha filtro por defecto---------
    var month = moment().format('M'); // date es un objeto moment
    var intMonth = parseInt(month);
    if (intMonth < 10){
        month= "0"+month;
    }
    var day   = moment().format('D');
    var dayInt = parseInt(day);
    if(dayInt < 10){
        day = "0"+day;
    }
    var year  = moment().format('YYYY'); 
    var dateFilter = year +"-"+month+"-"+day;
    // The first commit of Material-UI
    const [selectedDate, setSelectedDate] = React.useState(new Date(fechaString));

    const handleDateChange = (date) => {    
        //console.log('handleDateChange month');
        //var fechaActual = new Date();
        //console.log(fechaActual)
        //moment()
        //console.log(date);
        //console.log(moment());
        //console.log(moment().format("YYYY-MM-DD[T]HH:mm:ss"))
        //console.log(date.format("YYYY-MM-DD[T]HH:mm:ss"))
        var month = date.format('M'); // date es un objeto moment
        var intMonth = parseInt(month);
        if (intMonth < 10){
            month= "0"+month;
        }
        var day   = date.format('D');
        var intDay = parseInt(day);
        if(intDay < 10){
            day = "0" + day;
        }
        var year  = date.format('YYYY'); 
        var dateFilter = year +"-"+month+"-"+day;
        setDateRegisterFilter(dateFilter.toString());
        setSelectedDate(date);
    };

    const [listArea, setListArea] =React.useState([]);
    const [listCompany, setListCompany] = React.useState([]);
    //--object post      
    const [areaId, setAreaId] = React.useState(0);
    const [companyId, setCompanyId] = React.useState(0);
    const [dateRegisterFilter, setDateRegisterFilter] = React.useState(dateFilter.toString())
    //--progress
    const [showProgress, setShowProgress] = React.useState(false);
    //---message error
    const [show, setShow] = React.useState(false);
    const [messageError, setMessageError] = React.useState(false);
    
    const handleCloseDialog = () =>{
        setShow(false);
    }

    const showDialogError = (msg) =>{
        setMessageError(msg);
        setShow(true);
    }

    React.useEffect(() =>{
        var y = "Total height: " + window.screen.height;
        console.log(y);
        GetListArea();        
    },[]);

    const GetListCompany = (id) =>{
        if(id !== 0){
            setCompanyId(0);
            var parsId = parseInt(id);
            setShowProgress(true)
            const URL_BASE = process.env.REACT_APP_URL_BASE_PORT;
            const GET_LIST_COMPANY = process.env.REACT_APP_API_GET_LIST_COMPANY;
            const URL = URL_BASE+GET_LIST_COMPANY;
            const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN)        
          
            try{
                fetch(URL,{
                    method:'post',
                    mode: 'cors',
                    headers: {
                        'Accept':'application/json',                                    
                        'Authorization': 'Bearer ' + tokenLocalStorage,
                        'Content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        AreaId: parsId
                    })
    
                }).then(function(res){
                    return res.json();
                }).then(function(response){
                    //console.log(response);
                    setShowProgress(false);
    
                    if(response.Code === 0){
                        setListCompany(response.Data.UsuarioEmpresas);
    
                    }else if(response.Code === 2){
                        //console.log(response.Data.Errors[0].FieldName)
                        showDialogError(response.Data.Errors[0].Message)
                    }
    
                }).catch((error) =>{
                    showDialogError(error);
                })
            }catch(e){
                showDialogError(e);
            }
        }else{
            setCompanyId(0);
            setListCompany([]);
        }        
    }

    const GetListArea = () =>{
        setShowProgress(true);
        const URL_BASE = process.env.REACT_APP_URL_BASE_PORT;
        const GET_LIST_AREA = process.env.REACT_APP_API_GET_LIST_AREA;
        const URL = URL_BASE+GET_LIST_AREA;
        const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN)        
      
        try{
            fetch(URL,{
                method:'get',
                mode: 'cors',
                headers: {
                    'Accept':'application/json',                                    
                    'Authorization': 'Bearer ' + tokenLocalStorage,
                    'Content-type': 'application/json'
                }

            }).then(function(res){
                return res.json();
            }).then(function(response){
                //console.log(response);                
                setShowProgress(false);

                if(response.Code === 0){
                    setListArea(response.Data.UsuarioAreas);

                }else if(response.Code === 2){
                    //console.log(response.Data.Errors[0].FieldName)
                    showDialogError(response.Data.Errors[0].Message)
                }

            }).catch((error) =>{
                showDialogError(error);
            })
        }catch(e){
            showDialogError(e);
        }
    }
    

    const handleChangeArea = (e) =>{
        var area = e.target.value;
        if(area.toString() !== ""){            
            setAreaId(parseInt(area))
            GetListCompany(parseInt(area))
        }else{
            setAreaId(0)
            GetListCompany(0)
        }        
    }

    const handleChangeCompany = (e) =>{
        var company = e.target.value;
        if(company !== ""){
            setCompanyId(parseInt(e.target.value))
        }else{
            setCompanyId(0)
        }        
    }

    
    return (
        <Grid container >
            <TokenExpired />
            <Grid item xs={12} >
                <Typography variant="body2" style={{textAlign:'left'}}>
                    Fecha de registro
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <div className="form-group" >                    
                    <DateFilterCustom selectedDate={selectedDate} handleDateChange={handleDateChange}/>
                </div>
            </Grid>

            <Grid item xs={12} className="alignSubTitle">
                <Typography variant="body2" >
                    Área
                </Typography>
            </Grid>

            <Grid item xs={12} className="">
                <div className="form-group" >                    
                    <select 
                        className="form-control selectNoBorder" 
                        id="selectListArea2"
                        onChange={(e) =>handleChangeArea(e)}
                        value={areaId}
                    >
                        <option key='25' value="" style={{textAlign:"center"}} >Todos</option>
                        {
                            listArea.map((item,key) =>
                            <option key={item.AreaId} 
                                    value={item.AreaId}
                            >
                            {item.AreaNombre}
                            </option>
                        )
                        }                      
                    </select>
                </div>
            </Grid>

            <Grid item xs={12} className="alignSubTitle" >
                <Typography variant="body2" >
                    Empresa
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <div className="form-group" >                    
                    <select 
                        className="form-control selectNoBorder" 
                        id="selectListEmpresa3"
                        onChange={(e) =>handleChangeCompany(e)}
                        value={companyId}
                    >
                        <option key='24' value="" style={{textAlign:"center"}} >Todos</option>
                        {
                            listCompany.map(item => 
                            <option key={item.EmpresaId} value={item.EmpresaId} style={{textAlign:"center"}}>{item.EmpresaNombre}</option>
                            )
                        }
                    </select>
                </div>
            </Grid>
       
            <Grid item xs={12}>
                <TableFilter FechaRegistro={dateRegisterFilter} AreaId={areaId} EmpresaId={companyId} />
            </Grid>
            <AlertDialog open={show} message={messageError} handleClose={handleCloseDialog}/>
            <Progress open={showProgress} />
        </Grid>
    )
}

export default Record
