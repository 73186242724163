import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
//import DateFnsUtils from '@date-io/date-fns';
//import deLocale from "date-fns/locale/de";
import {
  MuiPickersUtilsProvider,
  //KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import moment from 'moment'
import 'moment/locale/es';


export default function DateFilterCustom(props) {
/*
  var fechaActual = moment().format("YYYY-MM-DD[T]HH:mm:ss");
  var fechJson = JSON.stringify(fechaActual);
  var fechaString = JSON.parse(fechJson)
  console.log(fechaString);

  // The first commit of Material-UI
  const [selectedDate, setSelectedDate] = React.useState(new Date(fechaString));

  const handleDateChange = (date) => {    
    //console.log('handleDateChange month');
    //var fechaActual = new Date();
    //console.log(fechaActual)
    //moment()
    //console.log(date);
    //console.log(moment());
    //console.log(moment().format("YYYY-MM-DD[T]HH:mm:ss"))
    //console.log(date.format("YYYY-MM-DD[T]HH:mm:ss"))
    var month = date.format('M'); // date es un objeto moment
    var day   = date.format('D');
    var year  = date.format('YYYY'); 
    setSelectedDate(date);
  };*/

  moment.locale('es'); 

  return (
    <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
      <Grid container justify="space-around">
        <KeyboardDatePicker 
          disableToolbar
          autoOk
          //variant="inline"
          //format={(date) => moment(date).format('DD-MM-YYYY')}
          format="dddd, DD [de] MMMM [del] YYYY"
          margin="normal"
          id="date-picker-inline"
          //label="Date picker inline"
          disableFuture={true}
          value={props.selectedDate}
          style={{width:'100%',margin:0}}
          onChange={props.handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );

  /**
   * return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justify="space-around">
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          id="date-picker-inline"
          label="Date picker inline"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog"
          label="Date picker dialog"
          format="MM/dd/yyyy"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <KeyboardTimePicker
          margin="normal"
          id="time-picker"
          label="Time picker"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
   */
}
