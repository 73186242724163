import React from 'react'
import {Grid, Typography, TextField, Card, CardContent, CardActions, Button} from '@material-ui/core'
import {TimeLocal, TimeLocalText} from '../time/TimeLocal';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import './Register.css';
import moment from 'moment'
import ButtonPlusImage from '../button/ButtonPlusImage';
import ButtonSaveRegister from '../button/ButtonSaveRegister';
import ButtonDeleteItem from '../button/ButtonDeleteItem';
import Progress from '../progress/Progress';
import AlertDialog from '../dialogs/AlertDialog';
import SelectPictureType from '../dialogs/SelectPictureType';
import { Redirect } from 'react-router-dom';
import Message from './Message';
import MessageConfirm from './MessageConfirm';
import UnauthorizedDialog from '../dialogs/UnauthorizedDialog';

const RegisterData = (props) => {

    const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN);

    const [listArea, setListArea] =React.useState([]);
    const [listCompany, setListCompany] = React.useState([]);
    const [listTurn, setListTurn] = React.useState([]); 
    //--object post 
    const [dateRegister, setDateRegister] = React.useState('');
    const [listImages, setListImages] = React.useState([]);    
    const [areaId, setAreaId] = React.useState('0');
    const [companyId, setCompanyId] = React.useState('0');
    const [turnId, setTurnId] = React.useState('');
    const [asignados, setAsignados] = React.useState(0);
    const [presentes, setPresentes] = React.useState(0);
    const [faltos, setFaltos] = React.useState(0);
    const [calpresen, setCalPresen] = React.useState(0);
    const [tardanzas, setTardanzas] = React.useState(0);
    const [novedades, setNovedades] = React.useState('')
    //----ocultar mostrar lineas
    const showBorder = 'showBorder';
    const hideBorder = 'hideBorder'
    const [flagAreaId, setFlagAreaId] = React.useState(false);
    const [flagCompany, setFlagCompany] = React.useState(false);
    const [flagTurn, setFlagTurn] = React.useState(false);
    const [flagAsignados, setFlagAsignados] = React.useState(false);
    const [flagPresentes, setFlagPresentes] = React.useState(false);
    const [flagTardanzas, setFlagTardanzas] = React.useState(false);
    const [flagPhotos, setFlagPhotos]  = React.useState(false);
    //---
    const [returnListRegister, setReturnListRegister]=React.useState(false);
    const [returnListConfirm, setReturnListConfirm]=React.useState(false);
    const [takePicture, setTakePicture] = React.useState(false);    
    //--progress
    const [showProgress, setShowProgress] = React.useState(false);
    //---message error
    const [show, setShow] = React.useState(false);
    const [messageError, setMessageError] = React.useState(false);
    //--show take picture options
    const [showTakePicture, setShowTakePicture] = React.useState(false);
    //---
    const [edit, setEdit] = React.useState(0);
    const [editView, setEditView] = React.useState(0);
    const [code, setCode] = React.useState('');
    //--
    const [showUnauthorizedDialog, setShowUnauthorizedDialog] = React.useState(false);    
    
    //---moment options--
    moment.updateLocale('en', {
        weekdays : [
            "Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"
        ],
        months: [
            'Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'
        ]
    });
    const getNameMonth = (val) =>{
        switch(val){
            case 0:
                return 'Enero';
            case 1: 
                return 'Febrero'
            case 2:
                return 'Marzo';
            case 3:
                return 'Abril'
            case 4:
                return 'Mayo'
            case 5:
                return 'Junio'
            case 6:
                return 'Julio'
            case 7:
                return 'Agosto'
            case 8:
                return 'Septiembre'
            case 9:
                return 'Octubre'
            case 10:
                return 'Noviembre'
            case 11:
                return 'Diciembre'
        }
    }
    //----moment options--
    const handleCloseDialog = () =>{
        setShow(false);
    }
    const handleCloseDialogTakePicture = () =>{
        setShowTakePicture(false);
    }
    const showDialogError = (msg) =>{
        setMessageError(msg);
        setShow(true);
    }

    React.useEffect(() =>{
        GetListArea();
        GetListTurn();

        var edit = localStorage.getItem('edit')
        var editV = localStorage.getItem('editView')
        var saveDate = localStorage.getItem('save-date-register');
        setDateRegister(saveDate)
        setEdit(parseInt(edit));
        setEditView(parseInt(editV))

        var code = ''
        if(localStorage.getItem('code')!== undefined & localStorage.getItem('code')!== ''&localStorage.getItem('code')!==NaN)      
            code = JSON.parse(localStorage.getItem('code'));
        
        setCode(code)
        
        if (parseInt(edit) === 1){
            var code = JSON.parse(localStorage.getItem('code'));
            setCode(code)    
            GetDataRegisterEdition(code);
        }else{
            GetData();
        }        
    },[]);

    const GetDataRegisterEdition = (code) =>{
        var cod = code.toString();
        setShowProgress(true);
        const URL_BASE = process.env.REACT_APP_URL_BASE_PORT;
        const POST_DETAIL_REGISTER = process.env.REACT_APP_API_POST_DETAIL_REGISTER;
        const URL = URL_BASE+POST_DETAIL_REGISTER;
        try{
            fetch(URL,{
                method:'post',
                mode: 'cors',
                headers: {
                    'Accept':'application/json',                                    
                    'Authorization': 'Bearer ' + tokenLocalStorage,
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({
                    CodeTercero: cod
                })

            }).then(function(res){
                if(res.status === 401){
                    return 401;
                }
                return res.json();
            }).then(function(response){
                //console.log(response);
                setShowProgress(false);

                if (response !== 401){
                    if(response.Code === 0){
                        setDataDetail(response.Data);
    
                    }else if(response.Code === 2){
                        //console.log(response.Data.Errors[0].FieldName)
                        showDialogError(response.Data.Errors[0].Message)
                    }
    
                }else{
                    setShowUnauthorizedDialog(true);
                }
                
            }).catch((error) =>{
                showDialogError(error);
            })
        }catch(e){
            showDialogError(e);
        }
    }

    const setDataDetail = (detail) =>{
        //fecha hora cliente
        var mydate = new Date(detail.FechaHoraCliente);
        var day = mydate.getDate();
        var anio = mydate.getFullYear();
        var newDate = moment(mydate);
        var form = newDate.format('dddd') + ", "+day+" de "+getNameMonth(newDate.month())+" del "+anio
        
        
        setDateRegister(form);

        setAreaId(detail.AreaId);
        localStorage.setItem('areaId',detail.AreaId);

        setCompanyId(detail.EmpresaId);
        localStorage.setItem('companyId',detail.EmpresaId);

        setTurnId(detail.CodeTurno);
        localStorage.setItem('turnId',detail.CodeTurno);

        setAsignados(detail.NroAsignados);
        localStorage.setItem('asignados',detail.NroAsignados);

        setPresentes(detail.NroPresentes);
        localStorage.setItem('presentes',detail.NroPresentes);

        setFaltos(detail.NroFaltas);
        localStorage.setItem('faltos',detail.NroFaltas);

        setCalPresen(detail.NroCalPresen);
        localStorage.setItem('calpresen',detail.NroCalPresen);

        setTardanzas(detail.NroTardanzas);
        localStorage.setItem('tardanzas',detail.NroTardanzas);

        if(detail.Novedades !== null){
            localStorage.setItem('novedades',detail.Novedades);
            setNovedades(detail.Novedades);
        }
        else{
            localStorage.setItem('novedades','');
            setNovedades('');
        }

        var arrayEdit = [];
        detail.ListaTerceroFoto.forEach(function(item) {
            arrayEdit.push({
                FechaHoraCliente: item.FechaHoraCliente,
                PathFoto: item.PathFoto,
                CFechaHoraFoto: item.CFechaHoraFoto,
                TipoFoto: item.TipoFoto,
                DescripcionTipoFoto: item.DescripcionTipoFoto,
                id:CreateGuid()
            })
        });
        localStorage.setItem('list-images',arrayEdit);//observado

        
        setListImages(arrayEdit);
        localStorage.setItem('edit',0);
        GetListCompany(detail.AreaId)
    }

    const CreateGuid = ()=> {  
        const _p8 = (s) => {  
           var p = (Math.random().toString(16)+"000000000").substr(2,8);  
           return s ? "-" + p.substr(0,4) + "-" + p.substr(4,4) : p ;  
        }  
        return _p8() + _p8(true) + _p8(true) + _p8();  
    }

    const GetData = () =>{
        var IdArea = localStorage.getItem('areaId');
        
        if(IdArea !== "" & IdArea !== undefined & IdArea !== NaN){
            GetListCompany(IdArea);
        }else{
            IdArea=""
        }
        setAreaId(IdArea);

        var idcompany = localStorage.getItem('companyId');
        setCompanyId(idcompany);

        var idturno = localStorage.getItem('turnId');
        setTurnId(idturno);

        var asign = localStorage.getItem('asignados');
        if(asign === ""){
            asign=0;
        }            
        setAsignados(asign);

        var pres = localStorage.getItem('presentes');
        if(pres === "")
            pres = 0;
        setPresentes(pres);

        var falt = localStorage.getItem('faltos');
        if(falt === "")
            falt = 0;
        setFaltos(falt);

        var calpre = localStorage.getItem('calpresen');
        if(calpre === "")
            calpre = 0;
        setCalPresen(calpre);

        var tard = localStorage.getItem('tardanzas');
        if(tard === "")
        tard = 0;
        setTardanzas(tard);

        var nov = localStorage.getItem('novedades');
        setNovedades(nov);

        console.log('get data');

        if( localStorage.getItem(process.env.REACT_APP_JSON_LIST_IMAGE) !== 'list-images'){
            var list = JSON.parse(localStorage.getItem(process.env.REACT_APP_JSON_LIST_IMAGE));

            if(list.length !== 0){
                setFlagPhotos(false)
            }else{
                setFlagPhotos(true);
            }
            
            setListImages(list)

        }
    }

    const GetListCompany = (id) =>{
        var parsId = parseInt(id);
        setShowProgress(true)
        const URL_BASE = process.env.REACT_APP_URL_BASE_PORT;
        const GET_LIST_COMPANY = process.env.REACT_APP_API_GET_LIST_COMPANY;
        const URL = URL_BASE+GET_LIST_COMPANY;        
      
        try{
            fetch(URL,{
                method:'post',
                mode: 'cors',
                headers: {
                    'Accept':'application/json',                                    
                    'Authorization': 'Bearer ' + tokenLocalStorage,
                    'Content-type': 'application/json'
                },
                body: JSON.stringify({
                    AreaId: parsId
                })

            }).then(function(res){
                if(res.status === 401){
                    return 401;
                }
                return res.json();
            }).then(function(response){
                //console.log(response);
                setShowProgress(false);
                if(response !== 401){
                    if(response.Code === 0){
                        setListCompany(response.Data.UsuarioEmpresas);
    
                    }else if(response.Code === 2){
                        //console.log(response.Data.Errors[0].FieldName)
                        showDialogError(response.Data.Errors[0].Message)
                    }
                }else{
                    setShowUnauthorizedDialog(true);
                }                

            }).catch((error) =>{
                showDialogError(error);
            })
        }catch(e){
            showDialogError(e);
        }
    }

    const GetListTurn = () =>{
        setShowProgress(true)
        const URL_BASE = process.env.REACT_APP_URL_BASE_PORT;
        const GET_LIST_TURN = process.env.REACT_APP_API_GET_LIST_TURN;
        const URL = URL_BASE+GET_LIST_TURN;
        try{
            fetch(URL,{
                method:'get',
                mode: 'cors',
                headers: {
                    'Accept':'application/json',                                    
                    'Authorization': 'Bearer ' + tokenLocalStorage,
                    'Content-type': 'application/json'
                }

            }).then(function(res){
                if(res.status === 401){
                    return 401;
                }
                return res.json();
            }).then(function(response){
                //console.log(response);                
                setShowProgress(false);

                if(response !== 401){

                    if(response.Code === 0){
                        setListTurn(response.Data.ListaTurno)

                    }else if(response.Code === 2){
                        //console.log(response.Data.Errors[0].FieldName)
                        showDialogError(response.Data.Errors[0].Message)
                    }
                }else{
                    setShowUnauthorizedDialog(true);
                }

            }).catch((error) =>{
                showDialogError(error);
            })
        }catch(e){
            showDialogError(e);
        }
    }

    const GetListArea = () =>{
        setShowProgress(true);
        const URL_BASE = process.env.REACT_APP_URL_BASE_PORT;
        const GET_LIST_AREA = process.env.REACT_APP_API_GET_LIST_AREA;
        const URL = URL_BASE+GET_LIST_AREA;
        try{
            fetch(URL,{
                method:'get',
                mode: 'cors',
                headers: {
                    'Accept':'application/json',                                    
                    'Authorization': 'Bearer ' + tokenLocalStorage,
                    'Content-type': 'application/json'
                }

            }).then(function(res){
                if(res.status === 401){
                    return 401;
                }
                return res.json();
            }).then(function(response){
                //console.log(response);                
                if(response !== 401){
                    if(response.Code === 0){
                        setListArea(response.Data.UsuarioAreas);

                    }else if(response.Code === 2){
                        //console.log(response.Data.Errors[0].FieldName)
                        showDialogError(response.Data.Errors[0].Message)
                    }
                }else{
                    setShowUnauthorizedDialog(true);
                }

            }).catch((error) =>{
                showDialogError(error);
            })
        }catch(e){
            showDialogError(e);
        }
    }

    const setCalculate = (a,b,c) =>{
        var result = parseInt(a) - (parseInt(b) + parseInt(c));
        if(result < 0){
            localStorage.setItem('faltos',0)
            setFaltos(0)
        }else{
            localStorage.setItem('faltos',result)
            setFaltos(result)
        }        
    }
    
    const setCalculatePresentes = (a,b) =>{
        var result = parseInt(a) + parseInt(b);
        if(result < 0){
            localStorage.setItem('calpresen',0)
            setCalPresen(0)
        }else{
            localStorage.setItem('calpresen',result)
            setCalPresen(result)
        }        
    }

    const handleChangeAsignados = (e) =>{        
        
        if(e.target.value === "" ){
            localStorage.setItem('asignados',0);
            setAsignados("")
            setFlagAsignados(true);
            setCalculate(0,presentes,0)
        }else{
            var tempAsign = parseInt(e.target.value)
            localStorage.setItem('asignados',tempAsign);
            setAsignados(tempAsign)
            setFlagAsignados(false)
            setCalculate(tempAsign,presentes,tardanzas)
        }
        if(e.target.value === "0"){
            setFlagAsignados(true)
        }else{
            setFlagAsignados(false)
        }
    }
    const handleChangePresentes = (e) =>{
        if(e.target.value === "" ){
            setPresentes("");
            localStorage.setItem('presentes',0);
            setFlagPresentes(true);
            setCalculate(asignados,0,0);
            setCalculatePresentes(0,tardanzas);
        }else{
            var tempPres = parseInt(e.target.value);
            setPresentes(tempPres);
            localStorage.setItem('presentes',tempPres);
            setFlagPresentes(false);
            setCalculate(asignados,tempPres,tardanzas);
            setCalculatePresentes(tempPres,tardanzas);
        }
        if(e.target.value === ""){
            setFlagPresentes(true)
        }else{
            setFlagPresentes(false)
        }   
    }
    const handleChangeTardanzas = (e) =>{
        if(e.target.value === "" ){
            setTardanzas("");
            localStorage.setItem('tardanzas',0);
            setFlagTardanzas(true);
            setCalculate(0,0,tardanzas);
            setCalculatePresentes(presentes,0);
        }else{
            var tempTard = parseInt(e.target.value);
            setTardanzas(tempTard);
            localStorage.setItem('tardanzas',tempTard);
            setFlagTardanzas(false);
            setCalculate(asignados,presentes,tempTard);
            setCalculatePresentes(presentes,tempTard);
        }  
        
        if(e.target.value === ""){
            setFlagTardanzas(true)
        }else{
            setFlagTardanzas(false)
        }
    }
    
    const handleChangeFaltos = (e) =>{
        localStorage.setItem('faltos',e.target.value);
        setFaltos(e.target.value)
    }
    const handleChangeCalPresen = (e) =>{
        localStorage.setItem('calpresen',e.target.value);
        setCalPresen(e.target.value)
    }

    const handleSaveRegister = () =>{
        if(areaId !== ''){
            if(companyId !== ''){
                if(turnId !== ""){
                    if(asignados !== 0 & asignados !== ""){
                        if(presentes !== ""){
                            if (tardanzas !== ""){
                            
                                if(listImages.length > 0 ){
                                    
                                    console.log(listImages)
                                    var amountTardanzas = parseInt(tardanzas);
                                    var amountATiempo = parseInt(presentes);

                                    var isFoundPhotoTardanza = false;
                                    var isFoundPhotoATiempo = false;
                                    listImages.forEach(element =>{
                                        if (element.TipoFoto === process.env.REACT_APP_TIPOFOTO_TARDANZA.toString()){
                                            isFoundPhotoTardanza = true;
                                        }
                                        if (element.TipoFoto === process.env.REACT_APP_TIPOFOTO_A_TIEMPO.toString()){
                                            isFoundPhotoATiempo = true;
                                        }
                                    });

                                    if (isFoundPhotoTardanza){
                                        if (amountTardanzas > 0){
                                            if(isFoundPhotoATiempo){
                                                if(amountATiempo > 0){
                                                    console.log("post editar o guardar registro 1")
                                                    
                                                    if(editView === 0){
                                                        //console.log('save new register');
                                                        PostSaveRegister();
                                                    }else{
                                                        //console.log('edit save register');
                                                        PostSaveEditRegister();
                                                    } 
                                                }else{
                                                    showDialogError('La cantidad de A Tiempo no coinciden con las fotos de A tiempo.')
                                                }

                                            }else{
                                                if(amountATiempo === 0){
                                                    console.log("post editar o guardar registro 2")
                                                    
                                                    if(editView === 0){
                                                        //console.log('save new register');
                                                        PostSaveRegister();
                                                    }else{
                                                        //console.log('edit save register');
                                                        PostSaveEditRegister();
                                                    } 
                                                }else{
                                                    showDialogError('Debe tomar al menos una foto de A tiempo.')
                                                }
                                            }
                                            
                                        }else{
                                            showDialogError('La cantidad de tardanzas no coinciden con las fotos de tardanzas.')
                                        }
                                    }else {
                                        if (amountTardanzas === 0){
                                            console.log("post editar o guardar registro 3")
                                            
                                            if(editView === 0){
                                                //console.log('save new register');
                                                PostSaveRegister();
                                            }else{
                                                //console.log('edit save register');
                                                PostSaveEditRegister();
                                            } 
                                        }else{
                                            showDialogError('Debe tomar al menos una foto de tardanza.')
                                        }
                                    }
                                                                      
                                }else{
                                    drawBorder()   //showDialogError('Debe cargar al menos una foto.')
                                }
                            }else{
                                console.log("Tardanzas")
                                drawBorder() //Ingrese cantidad de tardanzas
                            }
                        }else{
                            console.log("presentes " + presentes.toString())
                            drawBorder() //showDialogError('Ingrese cantidad de presentes.')
                        }
                    }else
                        drawBorder() //showDialogError('Ingrese cantidad de asignados.')
                }else
                    drawBorder()//showDialogError('Seleccione un turno.')
            }else
                drawBorder()//showDialogError('Seleccione una empresa.')
        }else
            drawBorder()//showDialogError('Seleccione un área.')
    }

    const drawBorder = () =>{
        if(areaId === ""){
            setFlagAreaId(true)
        }else{
            setFlagAreaId(false)
        }
        if(companyId === "")
            setFlagCompany(true);
        else
            setFlagCompany(false);
        
        if(turnId === "")
            setFlagTurn(true);
        else
            setFlagTurn(false)
        
        if(asignados!== 0 & asignados !== "" &asignados !=="0" ){
            setFlagAsignados(false)
        }else{
            setFlagAsignados(true)
        }

        if(presentes !== ""){
            setFlagPresentes(false)
        }else{
            setFlagPresentes(true)
        }

        if(tardanzas !== ""){
            setFlagTardanzas(false)
        }else{
            setFlagTardanzas(true)
        }

        if(listImages.length > 0 ){
            setFlagPhotos(false)
        }else{
            setFlagPhotos(true);
        }

    }

    const PostSaveRegister = () =>{
        //cleanLocalStorage()//limpiar localStorage
        var obj = {
            AreaId: parseInt(areaId),
            EmpresaId: parseInt(companyId),
            CodeTurno: turnId,
            NroAsignados: parseInt(asignados),
            NroPresentes: parseInt(presentes),
            NroFaltas: parseInt(faltos),
            NroCalPresen:parseInt(calpresen),
            NroTardanzas: parseInt(tardanzas),
            Novedades: novedades,
            FechaHoraCliente: moment().format(),
            ListaTerceroFotoSaveRequestBE: listImages
        }
        
        setShowProgress(true);        
        const URL_BASE = process.env.REACT_APP_URL_BASE_PORT;
        const POST_SAVE_REGISTER = process.env.REACT_APP_API_POST_SAVE_REGISTER;
        const URL = URL_BASE+POST_SAVE_REGISTER;        
        
        try{
            fetch(URL,{
                method:'post',
                mode: 'cors',
                headers: {
                    'Accept':'application/json',                                   
                    'Authorization': 'Bearer ' + tokenLocalStorage,
                    'Content-type': 'application/json'
                },
                body:JSON.stringify(obj)

            }).then(function(res){
                if(res.status === 401){
                    return 401;
                }
                return res.json();
            }).then(function(response){
                //console.log(response);
                setShowProgress(false);
                
                if (response !== 401){                
                    if(response.Code === 0){
                        if(response.Data.Success){
                            cleanLocalStorage()//limpiar localStorage
                            //showErrorMessage(response.Data.Message)                        
                            setReturnListRegister(true);                        
                            
                        }else{
                            showDialogError(response.Data.Message)
                        }                    

                    }else if(response.Code === 2){
                        //console.log(response.Data.Errors[0].FieldName)
                        showDialogError(response.Data.Errors[0].Message)
                        
                    }else if(response.Code === 1){
                        showDialogError(response.Data.Message)
                    }
                }else{
                    setShowUnauthorizedDialog(true)
                }

            }).catch((error) =>{
                showDialogError(error);
            })
        }catch(e){
            showDialogError(e);
        }
    }

    const PostSaveEditRegister = () =>{
        var obj = {
            CodeTercero: code,
            AreaId: parseInt(areaId),
            EmpresaId: parseInt(companyId),
            CodeTurno: turnId,
            NroAsignados: parseInt(asignados),
            NroPresentes: parseInt(presentes),
            NroFaltas: parseInt(faltos),
            NroCalPresen: parseInt(calpresen),
            NroTardanzas:parseInt(tardanzas),
            Novedades: novedades,
            FechaHoraCliente: moment().format(),
            ListaTerceroFotoUpdateRequestBE: listImages
        }
        
        setShowProgress(true);        
        const URL_BASE = process.env.REACT_APP_URL_BASE_PORT;
        const POST_SAVE_EDIT_REGISTER = process.env.REACT_APP_API_POST_SAVE_EDIT_REGISTER;
        const URL = URL_BASE+POST_SAVE_EDIT_REGISTER;
        
        try{
            fetch(URL,{
                method:'post',
                mode: 'cors',
                headers: {
                    'Accept':'application/json',                                   
                    'Authorization': 'Bearer ' + tokenLocalStorage,
                    'Content-type': 'application/json'
                },
                body:JSON.stringify(obj)

            }).then(function(res){
                if(res.status === 401){
                    return 401;
                }
                return res.json();
            }).then(function(response){
                
                setShowProgress(false);
                
                if(response !== 401){
                    if(response.Code === 0){
                        if(response.Data.Success){
                            cleanLocalStorage()//limpiar localStorage
                            //showErrorMessage(response.Data.Message)                        
                            setReturnListRegister(true);                       
                            
                        }else{
                            showDialogError(response.Data.Message)
                        }                    

                    }else if(response.Code === 2){
                        //console.log(response.Data.Errors[0].FieldName)
                        showDialogError(response.Data.Errors[0].Message)
                        
                    }else if(response.Code === 1){
                        showDialogError(response.Data.Message)
                    }
                }else{
                    setShowUnauthorizedDialog(true);
                }

            }).catch((error) =>{
                showDialogError(error);
            })
        }catch(e){
            showDialogError(e);
        }
    }
    
    

    const handleChangeArea = (e) =>{
        setAreaId(parseInt(e.target.value))
        localStorage.setItem('areaId',parseInt(e.target.value));
        if(e.target.value !== ''){
            setFlagAreaId(false);
            GetListCompany(e.target.value);
            setCompanyId('')
        }else{
            setAreaId('');
            setFlagAreaId(true);
        }
    }

    const handleChangeTurn = (e) =>{
        localStorage.setItem('turnId',e.target.value);
        if(e.target.value === ""){
            setFlagTurn(true)
            setTurnId("")
        }else{
            setTurnId(e.target.value)
            setFlagTurn(false)
        }
        
    }

    const handleChangeCompany = (e) =>{
        localStorage.setItem('companyId',parseInt(e.target.value));
        if(e.target.value !== ""){
            setFlagCompany(false)
            setCompanyId(parseInt(e.target.value))
        }else{
            setCompanyId("");
            setFlagCompany(true)
        }        
    }

    const handleNovedades = (e) =>{
        localStorage.setItem('novedades',e.target.value);
        setNovedades(e.target.value)
    }

    const handleClickTakePicture = () => {
        console.log(presentes)
        console.log(tardanzas)
        console.log(Number.isNaN(presentes))
        console.log(Number.isNaN(tardanzas))

        if(tardanzas === ""){           
            validFieldPresentes(tardanzas);

        }else if (tardanzas === undefined) {
            validFieldPresentes(tardanzas);

        }else if (tardanzas === null) {
            validFieldPresentes(tardanzas);

        }else if (parseInt(tardanzas) === 0){
            validFieldPresentes(tardanzas);

        }else{
            console.log('pasó validacion de tardanzas')
            validFieldPresentes();
        }      
    }

    const validFieldPresentes = (val) =>{
        
        if (presentes === "" && val === ""){
            console.log('entró dentro de vacíos')
            showDialogError("Debe ingresar una cantidad de Presentes o de Tardanzas.")
        }else if (presentes === undefined && val === undefined) {
            showDialogError("Debe ingresar una cantidad de Presentes o de Tardanzas.")
        }else if (presentes === null && val === null) {
            showDialogError("Debe ingresar una cantidad de Presentes o de Tardanzas.")
        }else if (parseInt(presentes) === 0 && parseInt(val) === 0){
            showDialogError("Debe ingresar una cantidad de Presentes o de Tardanzas.")
        }else{
            console.log('mostrar botones verdes')
            setShowTakePicture(true)
        }
    }

    const handleATiempo = () =>{
        console.log('A Tiempo: ' + process.env.REACT_APP_TIPOFOTO_A_TIEMPO)
        handleClick(process.env.REACT_APP_TIPOFOTO_A_TIEMPO, process.env.REACT_APP_TIPOFOTO_DESCRIP_A_TIEMPO);
    }

    const handleTardanza = () =>{
        handleClick(process.env.REACT_APP_TIPOFOTO_TARDANZA, process.env.REACT_APP_TIPOFOTO_DESCRIP_TARDANZA);
    }

    const handleClick = (typePhoto, typePhotoDescription) => {
        console.log(typePhoto)
        console.log(typePhotoDescription)
        //Agregar alert para escojer tipo de imagen

        /*
        setListImages([...listImages,{
            id:CreateGuid(),
            name:'https://oc7.ocstatic.com/images/logo_meta.png'
        }]);*/
        var jsonListImage = JSON.stringify(listImages);
        localStorage.setItem('save-date-register',dateRegister);
        localStorage.setItem('type-photo-register',typePhoto);
        localStorage.setItem('type-photo-description',typePhotoDescription);
        localStorage.setItem(process.env.REACT_APP_JSON_LIST_IMAGE,jsonListImage);
        setTakePicture(true);
    }

    const cleanLocalStorage = () =>{
        localStorage.setItem('edit',0);
        localStorage.setItem('code','');
        localStorage.setItem(process.env.REACT_APP_JSON_LIST_IMAGE,'list-images')
        localStorage.setItem('areaId','');
        localStorage.setItem('companyId','');
        localStorage.setItem('turnId','');
        localStorage.setItem('asignados','');
        localStorage.setItem('presentes','');
        localStorage.setItem('tardanzas','');
        localStorage.setItem('faltos','');
        localStorage.setItem('calpresen','');
        localStorage.setItem('novedades','');
    }

    const handleDelete = (id) =>{        
        const arrayDelete = listImages.filter((item) => item.id !== id)
        var jsonListImage = JSON.stringify(arrayDelete);
        localStorage.setItem(process.env.REACT_APP_JSON_LIST_IMAGE,jsonListImage);
        if(arrayDelete.length !== 0){
            setFlagPhotos(false)
        }else{
            setFlagPhotos(true);
        }
        setListImages(arrayDelete);
    }

    var divShowHide = 'showDiv';
    if(listImages !== null){
        if(listImages.length === 5){
            divShowHide = 'hideDiv';
        }
    }

    if(takePicture){
        return <Redirect to="/Picture" />
    }
    
    if(returnListRegister){
        //return <Redirect to="/Main"/>
        return <Message />
    }
    
    if(returnListConfirm){
        return <MessageConfirm />
    }
    
    return (
        <Grid container style={{padding:10}}>            
            <Grid item xs={12} className="alignSubTitle">
                <Typography variant="body2" >
                    Fecha de registro
                </Typography>
            </Grid>            
            {
                editView === 0 ? (
                    <Grid item xs={12}>
                        <div className="form-group marginCustom" style={{textAlign:"center"}}>
                            <TimeLocalText/>
                        </div>
                    </Grid>
                ):(
                    <Grid item xs={12} style={{textAlign:"center"}}>
                        <div className="form-group marginCustom" >
                            <TextField
                                id="txtDateRegister"
                                //label="Fecha de Registro"
                                value={dateRegister}
                                disabled
                                className="widthCustom"
                            />
                        </div>
                    </Grid>
                )
            }
            <Grid item xs={12} className="alignSubTitle">
                <Typography variant="body2" >
                    Área
                </Typography>
            </Grid>

            <Grid item xs={12} className="">
                <div className={"form-group marginCustom " + (flagAreaId ? showBorder:hideBorder)} >                    
                    <select 
                        className="form-control selectNoBorder" 
                        id="selectListArea"
                        onChange={(e) =>handleChangeArea(e)}
                        value={areaId}
                        disabled={editView === 0 ? false : true}
                    >
                        <option key='24' value={''} style={{textAlign:"center"}} >Seleccione...</option>
                        {
                            listArea.map((item,key) =>
                            <option key={item.AreaId} 
                                    value={item.AreaId}
                            >
                            {item.AreaNombre}
                            </option>
                        )
                        }                      
                    </select>
                </div>
            </Grid>

            <Grid item xs={12} className="alignSubTitle" >
                <Typography variant="body2" >
                    Empresa
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <div className={"form-group marginCustom " + (flagCompany ? showBorder: hideBorder)} >                    
                    <select 
                        className="form-control selectNoBorder" 
                        id="selectListArea"
                        onChange={(e) =>handleChangeCompany(e)}
                        value={companyId}
                        disabled={editView === 0 ? false : true}
                    >
                        <option key='24' value={''} style={{textAlign:"center"}} >Seleccione...</option>
                        {
                            listCompany.map(item => 
                            <option key={item.EmpresaId} value={item.EmpresaId} style={{textAlign:"center"}}>{item.EmpresaNombre}</option>
                            )
                        }
                    </select>
                </div>
            </Grid>

            <Grid item xs={12} className="alignSubTitle">
                <Typography variant="body2" >
                    Turno
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <div className={"form-group marginCustom "+(flagTurn ? showBorder:hideBorder)} >                    
                    <select 
                        className="form-control selectNoBorder" 
                        id="selectListArea"
                        onChange={(e) =>handleChangeTurn(e)}
                        value={turnId}
                        disabled={editView === 0 ? false : true}
                    >
                        <option key='4' value="" style={{textAlign:"center"}} >Seleccione...</option>
                        {                            
                            listTurn.map(item =>
                                <option key={item.CodTurno} value={item.CodTurno}>{item.NombreTurno}</option>
                                )
                        }
                    </select>
                </div>

            </Grid>
            <Grid item xs={12} >
                <Grid container style={{marginLeft:5}}>
                    <Grid item xs={2} className={"paddingItems"}>
                        <TextField
                            id="txtAsignados"
                            label="Asig."
                            type="number"
                            value={asignados}
                            onChange={handleChangeAsignados}
                            autoComplete="off"
                            className={flagAsignados ? showBorder:hideBorder}
                        />
                    </Grid>
                    <Grid item xs={2} className={"paddingItems"}>
                        <TextField
                            id="txtCalPrese"
                            label="Presen."
                            type="number"                        
                            style={{color:'black'}}
                            disabled
                            value={calpresen}
                            onChange={handleChangeCalPresen}                            
                        />
                    </Grid> 
                    <Grid item xs={3} className={"paddingItems"}>
                        <TextField
                            id="txtPresentes"
                            label="A tiem."
                            type="number"
                            value={presentes}
                            onChange={handleChangePresentes}
                            autoComplete="off"
                            className={flagPresentes ? showBorder:hideBorder}
                        />
                    </Grid>
                    <Grid item xs={3} className={"paddingItems"}>
                        <TextField
                            id="txtTardanzas"
                            label="Tard."
                            type="number"
                            value={tardanzas}
                            onChange={handleChangeTardanzas}
                            autoComplete="off"
                            className={flagTardanzas ? showBorder:hideBorder}                            
                        />
                    </Grid> 
                    <Grid item xs={2} className={"paddingItems"}>
                        <TextField
                            id="txtFaltos"
                            label="Faltos"
                            type="number"
                            style={{color:'black'}}
                            disabled
                            value={faltos}
                            onChange={handleChangeFaltos}                            
                        />
                    </Grid> 
                </Grid>                
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body2" className="alignSubTitle" style={{marginTop:5}}>
                    Novedades
                </Typography>
            </Grid>

            <Grid item xs={12} style={{maxHeight: 70, overflow: 'auto',paddingRight:10,paddingLeft:10}}>
                <TextareaAutosize 
                    aria-label="empty textarea" 
                    placeholder="Escribe una novedad..."
                    rowsMax={2}
                    style={{width:'100%', borderLeft:'none', borderRight:'none', borderTop:'none',outline:'none'}}
                    value={novedades}
                    onChange={handleNovedades}
                />
            </Grid>

            <Grid item xs={12}>
                <Typography variant="body2" className="alignSubTitle" style={{marginTop:5}}>
                    Captura de fotos
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <Grid container style={{padding:5}}>
                    {                        
                        listImages.length > 0 ? (
                            listImages.map(item =>
                                <Grid item xs={4} key={item.id}>
                                    <div style={{margin:5}}  >
                                        <Card style={{maxWidth:'100%'}} >
                                            <CardContent style={{padding:0}}>
                                                <Grid container>
                                                    <Grid item xs={12} style={{textAlign:'right'}} >                          
                                                        <ButtonDeleteItem 
                                                        handleDelete={() => handleDelete(item.id.toString())}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} style={{textAlign:'center'}}>
                                                        <Typography
                                                        variant="body2"
                                                        color="textSecondary"
                                                        component="p">
                                                            {item.DescripcionTipoFoto}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <img 
                                                            src={item.PathFoto}
                                                            alt="register"
                                                            style={{width:'100%'}}
                                                        />  
                                                    </Grid>
                                                    <Typography
                                                    variant="body2"
                                                    color="textSecondary"
                                                    component="p"
                                                    >
                                                    {item.CFechaHoraFoto}
                                                    </Typography>
                                                </Grid>                                                
                                            </CardContent>
                                        </Card>
                                    </div>                        
                                </Grid>
                            )
                        ):('')
                    }
                    <Grid item xs={4} className={divShowHide}>
                        <div style={{margin:5}} >
                            <ButtonPlusImage border={flagPhotos ? showBorder:hideBorder} handleClick={handleClickTakePicture}/>
                        </div>                        
                    </Grid>                   
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <ButtonSaveRegister name={'Guardar'} handleClick={handleSaveRegister} />
            </Grid>
            <AlertDialog open={show} message={messageError} handleClose={handleCloseDialog}/>
            <Progress open={showProgress} />
            <UnauthorizedDialog open={showUnauthorizedDialog} />
            <SelectPictureType
                open={showTakePicture}
                presentes={presentes}
                tardanzas={tardanzas}
                handleClose={handleCloseDialogTakePicture}
                handleClickATiempo={handleATiempo}
                handleClickTardanza={handleTardanza}>
            </SelectPictureType>
        </Grid>
    )
}

export default RegisterData
