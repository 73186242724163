import React from 'react'
import ToolbarLogin from './ToolbarLogin'
import BodyLogin from './BodyLogin';

const Login = () => {
    return (
        <div className="overlay">
            <ToolbarLogin/>
            
            
            
        </div>
    )
}
export default Login
