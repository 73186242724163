import React from 'react'
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import TableCompany from '../table/TableCompany';
import ButtonNewRegister from '../button/ButtonNewRegister'
import TimeLocal from '../time/TimeLocal';
import Progress from '../progress/Progress';
import AlertDialog from '../dialogs/AlertDialog';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
//import JWT from 'jsonwebtoken';
//import { Redirect } from 'react-router-dom';
import TokenExpired from '../constantes/TokenExpired';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import KeyValue from '../../config/KeyValue';


const theme = createMuiTheme({
    typography: {
      body1: {
        fontSize: "1.0em",
      },
      body2: {
        fontSize: "0.8em",
      }
    },
  });

const Tray = () => {
    const USERLOCAL = `${localStorage.getItem(process.env.REACT_APP_NAME_USER_JOCKEY)}`;
    const tokenLocalStorage = localStorage.getItem(
        process.env.REACT_APP_KEY_TOKEN
      );
    //--progress
    const [showProgress, setShowProgress] = React.useState(false);
    //---message error
    const [show, setShow] = React.useState(false);
    const [messageError, setMessageError] = React.useState(false);
    //---
    const [isVisor, setIsVisor] = React.useState(true);

    const MaysPrimera =(word)=>{
        return word.charAt(0).toUpperCase() + word.slice(1);
    }
    const handleCloseDialog = () =>{
        setShow(false);
    }
    const showDialogError = (msg) =>{
        setMessageError(msg);
        setShow(true);
    }
    const greetingUser = '¡Hola ' + MaysPrimera(USERLOCAL.toLowerCase())+'!'
    React.useEffect(() => {
        GetIsVisor();
      }, []);
    const GetIsVisor = () => {
    
        const URL_BASE = process.env.REACT_APP_URL_BASE_PORT;
        const GET_IS_VISOR = process.env.REACT_APP_API_GET_VALIDATE_IS_VISOR;
        const URL = URL_BASE + GET_IS_VISOR;
        try {
          fetch(URL, {
            method: "get",
            mode: "cors",
            headers: {
              Accept: "application/json",
              Authorization: "Bearer " + tokenLocalStorage,
              "Content-type": "application/json",
            },
          })
            .then(function (res) {
              if (res.status === 401) {
                return 401;
              }
              return res.json();
            })
            .then(function (response) {
              //console.log(response);
              setShowProgress(false);
              if (response !== 401) {
                if (response.Code === 0) {
                  localStorage.setItem(KeyValue.KEY_APP_ES_USUARIO_VISOR,response.Data)
                  if (response.Data) {
                    setIsVisor(true);
                  }else{
                    setIsVisor(false);
                  }
                } else if (response.Code === 2) {
                  //console.log(response.Data.Errors[0].FieldName)
                  showDialogError(response.Data.Errors[0].Message);
                } else if (response.Code === 1) {
                  showDialogError(response.Data.Message);
                }
              }
            })
            .catch((error) => {
              showDialogError(error);
            });
        } catch (e) {
          showDialogError(e);
        }
      };

    return (
        <ThemeProvider theme={theme}>
            <Grid container>
                <TokenExpired />
                <Grid item xs={12} style={{textAlign:'right'}} >
                    <Typography variant="body2">
                        <TimeLocal />
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{textAlign:'left'}}>
                    <Typography variant="h6" color="inherit">
                        {greetingUser}
                    </Typography>
                </Grid>

                <Grid item xs={12} style={{textAlign:'left', marginTop:15}}>
                    <Typography color="inherit">
                        Registros del día
                    </Typography>
                </Grid>

                <Grid item xs={12} style={{marginTop:5}}>
                    <TableCompany />
                </Grid>
                <Grid item xs={6} style={{textAlign:'left', marginTop:10}}>
                  <RemoveRedEyeIcon style={{color:'#009DE4'}}/> Editables <br></br>
                  <RemoveRedEyeIcon style={{color:'#a5a5a5'}}/> No Editables
                </Grid>
                <Grid item className={isVisor? 'hideDiv' : 'showDiv'} xs={6} style={{textAlign:'right', marginTop:10}}>
                    <ButtonNewRegister  />
                </Grid>
                <AlertDialog open={show} message={messageError} handleClose={handleCloseDialog}/>
                <Progress open={showProgress} />
            </Grid>
        </ThemeProvider>        
    )
}

export default Tray
