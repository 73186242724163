import React from 'react'
import { Grid, Typography } from '@material-ui/core'
//import TableFilter from '../table/TableFilter';
import DateFilterBudgeted from '../record/DateFilterBudgeted';
import Progress from '../progress/Progress';
import AlertDialog from '../dialogs/AlertDialog';
//import moment from 'moment'
import '../personBudgeted/HeightDynamic.css';
import TokenExpired from '../constantes/TokenExpired';
//import TableBudgeted from '../table/TableBudgeted';
//import ButtonNewRegister from '../button/ButtonNewRegister'
//import ButtonNewPersonBudgeted from '../button/ButtonNewPersonBudgeted';

import Checkbox from '@material-ui/core/Checkbox';
//import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
//import FormControl from '@material-ui/core/FormControl';
//import FormLabel from '@material-ui/core/FormLabel';
import InputNumber from '../inputs/InputNumber';
import ButtonSavePersonBudgeted from '../button/ButtonSavePersonBudgeted';
import KeyValue from '../../config/KeyValue';
import {MenuContext} from '../../context/MenuLeftProvider';
import UnauthorizedDialog from '../dialogs/UnauthorizedDialog';
import SuccessDialog from '../dialogs/SuccessDialog';
import PersonBudgeted from '../personBudgeted/PersonBudgeted';
import ConfirmDialog from '../dialogs/ConfirmDialog';
import ToolbarPersonNewBudgeted from './ToolbarPersonNewBudgeted';
import ButtonCloseNewPersonBudgeted from '../button/ButtonCloseNewRegisterBudgeted';
import { useHistory } from "react-router-dom";

const PersonNewBudgeted = (props) => {
    //---context-----
    const {getDateLocal,getDateFormatYYYYMMDD} = React.useContext(MenuContext);
    
    //const [selectedDate, setSelectedDate] = React.useState(new Date(getDateLocal()));
    const [dateInit, setDateInit] = React.useState(new Date(getDateLocal()));
    const [dateFinal, setDateFinal] = React.useState(new Date(getDateLocal()));
    const [dateRegisterInitFilter, setDateRegisterInitFilter] = React.useState(getDateFormatYYYYMMDD().toString())
    const [dateRegisterFinalFilter, setDateRegisterFinalFilter] = React.useState(getDateFormatYYYYMMDD().toString())
    const [flagMonday, setFlagMonday] = React.useState(true);
    const [flagTuesday, setFlagTuesday] = React.useState(true);
    const [flagWednesday, setFlagWednesday] = React.useState(true);
    const [flagThursday, setFlagThursday] = React.useState(true);
    const [flagFriday, setFlagFriday] = React.useState(true);
    const [flagSaturday, setFlagSaturday] = React.useState(true);
    const [flagSunday, setFlagSunday] = React.useState(true);
    const [flagAllDays, setFlagAllDays] = React.useState(true);

    const [amountPerson, setAmountPerson] = React.useState('');
    const [amountPersonFlagError, setAmountPersonFlagError] = React.useState(false);
    const [amountPersonHelperText, setAmountPersonHelperText] = React.useState('');

    const [areaFlagError, setAreaFlagError] = React.useState(false);
    const [areaHelperText, setAreaHelperText] = React.useState('');

    const [companyFlagError, setCompanyFlagError] = React.useState(false);
    const [companyHelperText, setCompanyHelperText] = React.useState('');

    const [daysFlagError, setDaysFlagError] = React.useState(false);
    const [daysHelperText, setDaysHelperText] = React.useState('');

    const [listArea, setListArea] =React.useState([]);
    const [listCompany, setListCompany] = React.useState([]);
    //--object post      
    const [areaId, setAreaId] = React.useState(0);
    const [companyId, setCompanyId] = React.useState(0);

    let history = useHistory();

    const [returnListBudgeted, setReturnListBudgeted] = React.useState(false);
    const [showConfirmForce, setShowConfirmForce] = React.useState(false);
    const [messageResultConfirm, setMessageResultConfirm] = React.useState('');
    //const [dateRegisterFilter, setDateRegisterFilter] = React.useState(dateFilter.toString())
    //------------flag vencimiento de token------------------
    const [showUnauthorized, setShowUnauthorized] = React.useState(false);
    //---- success-----------
    const [showSuccess, setShowSuccess] = React.useState(false);
    const [messageSuccess, setMessageSuccess] = React.useState('');
    //----loader--------------
    const [loading, setLoading] = React.useState(false);
    //---message error-----
    const [show, setShow] = React.useState(false);
    const [messageError, setMessageError] = React.useState(false);    
    //---------------------    

    const handleClose = () => {
        setShow(false)       
    };
    const handleCloseSuccess = () => {
        setShowSuccess(false)
        //setReturnListBudgeted(true);
        history.push("/TrayBudgeted");
    };
    const ShowDialogMessage = (message) =>{
        setShow(true);
        setMessageError(message);
    }
    const handleCloseConfirmDialog = () => {
        setShowConfirmForce(false)
    }
    //----------- impl method async ------------------------------------
    const asyncCallMethod = (typeCall,obj,methodAction,typeAction) =>{
        const URL_BASE = process.env.REACT_APP_URL_BASE_PORT;
        const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN) 

        const paramsHeadersPost = {
            method:typeCall,
            mode: 'cors',
            headers: {
                'Accept':'application/json',
                'Authorization': 'Bearer ' + tokenLocalStorage,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(obj)
        }
        const paramsHeadersGet = {
            method:typeCall,
            mode: 'cors',
            headers: {
                'Accept':'application/json',
                'Authorization': 'Bearer ' + tokenLocalStorage,
                'Content-type': 'application/json'
            }
        }
        const header = obj !== null ? paramsHeadersPost : paramsHeadersGet ;
        const URL = URL_BASE+methodAction;    
        setLoading(true);    
            try{
                fetch(URL,header).then(function(res){
                if(res.status === 401){
                    return 401;
                }
                return res.json();
                }).then(function(response){    
                setLoading(false);

                if(response === 401){
                    setShowUnauthorized(true);

                }else if(response.Code === 0){
                    SetConfigResponse(response,typeAction);                    

                }else if (response.Code === 1){
                    errorCode1(response);
            
                }else if (response.Code === 2){
                    errorCode2(response);
                }
                }).catch((error) =>{
                setLoading(false);
                console.log(error);
                })
            }catch(e){
            setLoading(false);
            console.log(e);
            }                 
    }
    const SetConfigResponse = (response,typeAction)=>{
        if (typeAction === KeyValue.ACTION_GET_LIST_COMPANY){
            ResponseGetListCompany(response);

        } else if (typeAction === KeyValue.ACTION_SAVE_REGISTER_BUDGETED){
            ResponseSaveRegisterBudgeted(response);

        }
    }
    const errorCode1 = (response) =>{
        var message = "";
        if(response.Data.StatusCode === 500){
            ShowDialogMessage(response.Data.Message);
        }
        else if(response.Data.Errors.length > 0){
            response.Data.Errors.forEach(element => {
                //console.log(element);
                message = `${element}.`
            });
            ShowDialogMessage(message);                        
        }
    }
    const errorCode2 = (response) =>{
        var message = "";
        if(response.Data.Errors.length > 0){
            response.Data.Errors.forEach(element => {
                //console.log(element);
                message = message +  ` ${element.FieldName}. ${element.Message}. `
            });
            ShowDialogMessage(message);                        
        }
    }

    React.useEffect(() =>{
        var listA = JSON.parse(localStorage.getItem(KeyValue.KEY_LIST_AREA_BUDGETED));
        setListArea(listA)    
    },[]);

    /********** OBTENER LISTA DE COMPAÑIAS ******************* */
    const GetListCompany = (areaId) =>{
        const methodAction = process.env.REACT_APP_API_GET_LIST_COMPANY;
        const obj = {
            AreaId: parseInt(areaId)
        }
        if(areaId !== 0){
            asyncCallMethod("post",obj,methodAction,KeyValue.ACTION_GET_LIST_COMPANY);

        }else{
            setCompanyId(0);
            setListCompany([]);

        }     
    }
    const ResponseGetListCompany = (response) =>{
        setListCompany(response.Data.UsuarioEmpresas);
    }
    
    /********** GUARDAR REGISTRO ******************* */
    const SaveRegisterBudgeted = (obj) =>{
        const methodAction = process.env.REACT_APP_API_SAVE_REGISTER_BUDGETED;
        asyncCallMethod('post',obj,methodAction,KeyValue.ACTION_SAVE_REGISTER_BUDGETED)
    }
    const ResponseSaveRegisterBudgeted = (response) =>{
        
        if(response.Data.Success){
            setShowSuccess(true);
            setMessageSuccess(response.Data.Message);

        }else if (response.Data.NeedForce){
            setMessageResultConfirm(response.Data.Message);
            setShowConfirmForce(true);
            
        }else{
            ShowDialogMessage(response.Message)
        }
        
    }
    /**********MANEJADORES DE EVENTOS******************* */
    const handleChangeArea = (e) =>{
        var area = e.target.value;
        if(area.toString() !== ""){
            setAreaFlagError(false);
            setAreaHelperText('');
            setAreaId(parseInt(area))
            GetListCompany(parseInt(area))
        }else{
            setAreaFlagError(true);
            setAreaHelperText('Elija una opción.');
            setAreaId(0)
            GetListCompany(0)
        }        
    };
    const handleChangeCompany = (e) =>{
        var company = e.target.value;
        if(company !== ""){
            setCompanyFlagError(false);
            setCompanyHelperText('');
            setCompanyId(parseInt(e.target.value));
        }else{
            setCompanyFlagError(true);
            setCompanyHelperText('Elija una opción.');
            setCompanyId(0)
        }        
    };
    const handleDateInit = (date) => {
        setDateRegisterInitFilter(getFormatYYYYMMDD(date).toString());
        setDateInit(date);
    };
    const handleDateFinal = (date) => {
        setDateRegisterFinalFilter(getFormatYYYYMMDD(date).toString());
        setDateFinal(date);
    };
    const getFormatYYYYMMDD = (date) => {
        //console.log('handleDateChange month');
        //var fechaActual = new Date();
        //console.log(fechaActual)
        //moment()
        //console.log(date);
        //console.log(moment());
        //console.log(moment().format("YYYY-MM-DD[T]HH:mm:ss"))
        //console.log(date.format("YYYY-MM-DD[T]HH:mm:ss"))
        var month = date.format('M'); // date es un objeto moment
        var intMonth = parseInt(month);
        if (intMonth < 10){
            month= "0"+month;
        }
        var day   = date.format('D');
        var intDay = parseInt(day);
        if(intDay < 10){
            day = "0" + day;
        }
        var year  = date.format('YYYY'); 
        return year +"-"+month+"-"+day;
    }
    const onChangeAllDays = (event) => {
        setFlagAllDays(event.target.checked);
        setFlagMonday(event.target.checked);
        setFlagTuesday(event.target.checked);
        setFlagWednesday(event.target.checked);
        setFlagThursday(event.target.checked);
        setFlagFriday(event.target.checked);
        setFlagSaturday(event.target.checked);
        setFlagSunday(event.target.checked);
    }
    const onChangeThursday = (event) =>{
        setFlagThursday(event.target.checked);
        setDaysFlagError(false)
        setDaysHelperText('')
        if (!event.target.checked){
            setFlagAllDays(event.target.checked)
        }
        validateAllChecks(flagMonday,flagTuesday,flagWednesday,event.target.checked,flagFriday,flagSaturday,flagSunday);
    }
    const onChangeMonday = (event) =>{
        setFlagMonday(event.target.checked);
        setDaysFlagError(false)
        setDaysHelperText('') 
        /*
        if (!event.target.checked){
            setFlagAllDays(event.target.checked)
        } */
        validateAllChecks(event.target.checked,flagTuesday,flagWednesday,flagThursday,flagFriday,flagSaturday,flagSunday);
    }
    const onChangeTuesday = (event) =>{
        setFlagTuesday(event.target.checked);
        setDaysFlagError(false)
        setDaysHelperText('')
/*
        if (!event.target.checked){
            setFlagAllDays(event.target.checked)
        } */
        validateAllChecks(flagMonday,event.target.checked,flagWednesday,flagThursday,flagFriday,flagSaturday,flagSunday);
    }
    const onChangeWednesday = (event) =>{
        setFlagWednesday(event.target.checked);
        setDaysFlagError(false)
        setDaysHelperText('') 
        /*
        if (!event.target.checked){
            setFlagAllDays(event.target.checked)
        }*/
        validateAllChecks(flagMonday,flagTuesday,event.target.checked,flagThursday,flagFriday,flagSaturday,flagSunday);
    }
    const onChangeFriday = (event) =>{
        setFlagFriday(event.target.checked);
        setDaysFlagError(false)
        setDaysHelperText('')
        
        validateAllChecks(flagMonday,flagTuesday,flagWednesday,flagThursday,event.target.checked,flagSaturday,flagSunday);
    }
    const onChangeSaturday = (event) =>{
        setFlagSaturday(event.target.checked);
        setDaysFlagError(false)
        setDaysHelperText('')
/*
        if (!event.target.checked){
            setFlagAllDays(event.target.checked)
        }*/
        validateAllChecks(flagMonday,flagTuesday,flagWednesday,flagThursday,flagFriday,event.target.checked,flagSunday);
    }
    const onChangeSunday = (event) =>{
        setFlagSunday(event.target.checked);
        setDaysFlagError(false)
        setDaysHelperText('')
/*
        if (!event.target.checked){
            setFlagAllDays(event.target.checked)
        }*/
        validateAllChecks(flagMonday,flagTuesday,flagWednesday,flagThursday,flagFriday,flagSaturday,event.target.checked);
    }
    const onChangeAmountPerson = (event) =>{
        const text = event.target.value;
        if(text === ""){
            setAmountPersonFlagError(true);
            setAmountPersonHelperText('Campo vacío.');
        }else{
            setAmountPersonFlagError(false);
            setAmountPersonHelperText('');
        }
        setAmountPerson(text);
    }
    const handleSaveRegister = (option) => {
        if(option === 2 ){
            setShowConfirmForce(false); 
        }
        var countEmpty = 0;

        if(!flagMonday & !flagTuesday & !flagWednesday & !flagThursday & !flagFriday & !flagSaturday & !flagSunday ){
            setDaysFlagError(true)
            setDaysHelperText('Debe escoger al menos un día.')
            countEmpty++;
        }

        if(areaId === 0){
            setAreaFlagError(true);
            setAreaHelperText('Elija una opción.');
            countEmpty++;
        }
        if(companyId === 0){
            setCompanyFlagError(true);
            setCompanyHelperText('Elija una opción.');
            countEmpty++;
        }

        if(dateRegisterInitFilter > dateRegisterFinalFilter){
            ShowDialogMessage('La fecha inicial es mayor que la fecha final.')
            countEmpty++;
        }

        if(amountPerson === ""){
            setAmountPersonFlagError(true);
            setAmountPersonHelperText('Campo vacío.');
            countEmpty ++;
        }
        if(countEmpty === 0){
            const obj = {
                AreaId: areaId,
                EmpresaId: companyId,
                FechaDesde: dateRegisterInitFilter,
                FechaHasta: dateRegisterFinalFilter,
                Lunes: flagMonday,
                Martes: flagTuesday,
                Miercoles: flagWednesday,
                Jueves: flagThursday,
                Viernes: flagFriday,
                Sabado: flagSaturday,
                Domingo: flagSunday,
                NroPresupuesto: parseInt(amountPerson),
                ForzarGuardado: option === 2 ? true : false,
            };
            SaveRegisterBudgeted(obj)
        }else{
            console.log('vacío')
        }
    };
    const validateAllChecks = (flagMonday,flagTuesday,flagWednesday,flagThursday,flagFriday,flagSaturday,flagSunday) =>{
        if(flagMonday && flagTuesday && flagWednesday && flagThursday && flagFriday && flagSaturday && flagSunday){
            //console.log("true")
            setFlagAllDays(true);
        }else{
            //console.log("false")
            setFlagAllDays(false)
        }
    }
    const handleReturnTrayBudgeted = () => {
        history.push("/TrayBudgeted");
    }
    /************RENDERS ***************** */
    if (returnListBudgeted){
        return <PersonBudgeted />
    }
    
    return (
        <div>
            <ToolbarPersonNewBudgeted />
            <div className="ex2 heightRecord2" style={{overflow:'auto'}}>
                <Grid container style={{padding:24}}>
                <TokenExpired />

                <Grid item xs={12} md={3} ></Grid>
                {/**combo area */}
                <Grid item xs={12} md={3} className="style-bettween-div">
                    <Typography  variant="body2" className="alignSubTitle">
                        Área
                    </Typography>
                    <div className="form-group" >                    
                        <select
                            className={ (areaFlagError ? "borderColorError " : " ")+" form-control selectNoBorder"}
                            //className="form-control selectNoBorder" 
                            id="selectListArea2"
                            onChange={(e) =>handleChangeArea(e)}
                            value={areaId}
                        >
                            <option key='25' value="" style={{textAlign:"center"}} >Seleccione...</option>
                            {
                                listArea !== undefined ? (
                                    listArea !== null ? (
                                        listArea.map((item,key) =>
                                            <option key={item.AreaId} 
                                                    value={item.AreaId}
                                            >
                                            {item.AreaNombre}
                                            </option>
                                            )
                                    ):("")
                                ):("")
                            }                      
                        </select>
                        <div className={(areaFlagError ? 'showDiv': 'hideDiv')}>
                            <p className="selectColorError">{areaHelperText}</p>
                        </div>
                    </div>
                </Grid>
                
                {/**combo empresa */}
                <Grid item xs={12} md={3} className="style-bettween-div">
                    <Typography variant="body2" className="alignSubTitle">
                        Empresa
                    </Typography>
                    <div className="form-group" >                    
                        <select
                            className={ (companyFlagError ? "borderColorError " : " ")+" form-control selectNoBorder"}
                            id="selectListEmpresa3"
                            onChange={(e) =>handleChangeCompany(e)}
                            value={companyId}
                        >
                            <option key='24' value="" style={{textAlign:"center"}} >Seleccione...</option>
                            {
                                listCompany !== undefined ? (
                                    listCompany.map(item => 
                                        <option key={item.EmpresaId} value={item.EmpresaId} style={{textAlign:"center"}}>{item.EmpresaNombre}</option>
                                        )
                                ):("")
                            }
                        </select>
                        <div className={(companyFlagError ? 'showDiv': 'hideDiv')}>
                            <p className="selectColorError">{companyHelperText}</p>
                        </div>
                    </div>
                </Grid>            
                <Grid item xs={12} md={3} ></Grid>
                
                
                {/******* fecha desde ****** */}            
                <Grid item md={3} xs={12}></Grid>
                <Grid item md={3} xs={12} className="style-bettween-div" >
                    <Typography variant="body2" style={{textAlign:'left'}}>
                        Fecha Desde
                    </Typography>
                    <div className="form-group" >                    
                        <DateFilterBudgeted selectedDate={dateInit} handleDateChange={handleDateInit}/>
                    </div>                
                </Grid>
                
                {/********* fecha hasta ********* */}
                <Grid item md={3} xs={12} className="style-bettween-div">
                    <Typography variant="body2" style={{textAlign:'left'}} >
                        Fecha Hasta
                    </Typography>
                    <div className="form-group" >                    
                        <DateFilterBudgeted selectedDate={dateFinal} handleDateChange={handleDateFinal}/>
                    </div>
                </Grid>
                <Grid item md={3} xs={12}></Grid>
                
                {/************* */}
                
                <Grid item md={3} xs={12}></Grid>
                {/** CHECKS DIAS DE LA SEMANA */}
                <Grid item xs={12} md={6} style={{marginBottom:5}}>
                    <div style={{display:'inline-flex'}}>
                        <div style={{display:'flex',alignItems:'center'}}>
                            <div>
                                <Typography variant="body2" style={{marginLeft:10}}>
                                    Días Presupuestados
                                </Typography>
                            </div>                        
                        </div>                    
                        
                        <FormControlLabel
                            style={{marginLeft: 20,marginTop:5}}
                            checked={flagAllDays}
                            control={<Checkbox onChange={onChangeAllDays} color="primary" />}
                            label={KeyValue.CHECK_ALL_DAYS}
                            labelPlacement="right"
                        />
                    </div>                
                    
                    <div className = {daysFlagError ? 'borderColorError':''}>
                        <Grid container>
                        <Grid item xs={3} md={1} style={{textAlign:'center'}}>
                            <FormControlLabel
                                //value={"bottom"}
                                checked={flagMonday}
                                control={<Checkbox onChange={onChangeMonday} color="primary" />}
                                label={KeyValue.CHECK_MONDAY}
                                labelPlacement="bottom"
                            />
                        </Grid>
                        <Grid item xs={3} md={1} style={{textAlign:'center'}}>
                            <FormControlLabel
                                checked={flagTuesday}
                                control={<Checkbox onChange={onChangeTuesday} color="primary" />}
                                label={KeyValue.CHECK_TUESDAY}
                                labelPlacement="bottom"
                            />
                        </Grid>
                        <Grid item xs={3} md={1} style={{textAlign:'center'}}>
                            <FormControlLabel
                                checked={flagWednesday}
                                control={<Checkbox onChange={onChangeWednesday} color="primary" />}
                                label={KeyValue.CHECK_WEDNESDAY}
                                labelPlacement="bottom"
                            />
                        </Grid>
                        <Grid item xs={3} md={1} style={{textAlign:'center'}}>
                            <FormControlLabel
                                checked={flagThursday}
                                control={<Checkbox onChange={onChangeThursday} color="primary" />}
                                label={KeyValue.CHECK_THURSDAY}
                                labelPlacement="bottom"
                            />
                        </Grid>
                        <Grid item xs={3} md={1} style={{textAlign:'center'}}>
                            <FormControlLabel
                                checked={flagFriday}
                                control={<Checkbox onChange={onChangeFriday} color="primary" />}
                                label={KeyValue.CHECK_FRIDAY}
                                labelPlacement="bottom"
                            />
                        </Grid>
                        <Grid item xs={3} md={1} style={{textAlign:'center'}}>
                            <FormControlLabel
                                checked={flagSaturday}
                                control={<Checkbox onChange={onChangeSaturday} color="primary" />}
                                label={KeyValue.CHECK_SATURDAY}
                                labelPlacement="bottom"
                            />
                        </Grid>
                        <Grid item xs={3} md={1} style={{textAlign:'center'}}>
                            <FormControlLabel
                                checked={flagSunday}
                                control={<Checkbox onChange={onChangeSunday} color="primary" />}
                                label={KeyValue.CHECK_SUNDAY}
                                labelPlacement="bottom"
                            />
                        </Grid>
                    </Grid>
                    </div>
                    <div className={(daysFlagError ? 'showDiv': 'hideDiv')}>
                        <p className="selectColorError">{daysHelperText}</p>
                    </div>
                </Grid>
                <Grid item xs={12} md={3}></Grid>

                <Grid item xs={12} md={3} ></Grid>
                <Grid item xs={12} md={6} style={{textAlign:'center'}}>
                    <InputNumber
                        id="inputIdNumberBudgeted"
                        onChange={onChangeAmountPerson}
                        helperText={amountPersonHelperText}
                        flagError={amountPersonFlagError}
                        label={'Cantidad'} 
                    />
                </Grid>
                <Grid item xs={12} md={3}></Grid>

                <Grid container>
                    <Grid item xs={12} md={4}></Grid>
                    <Grid item xs={6} md={2} style={{textAlign:'center', marginTop:25}}>
                        < ButtonCloseNewPersonBudgeted onClick={handleReturnTrayBudgeted} />
                    </Grid>
                    <Grid item xs={6} md={2} style={{textAlign:'center', marginTop:25}}>
                        < ButtonSavePersonBudgeted onClick={e => handleSaveRegister(1)} />
                    </Grid>
                    <Grid item xs={12} md={4}></Grid>
                </Grid>
                
                <UnauthorizedDialog open={showUnauthorized} />
                <AlertDialog open={show} message={messageError} handleClose={handleClose}/>
                <Progress open={loading} />
                <SuccessDialog open={showSuccess} message={messageSuccess} handleClose={handleCloseSuccess}/>  
                <ConfirmDialog 
                open={showConfirmForce} 
                handleForceUdpdate={e =>handleSaveRegister(2)} 
                message = {messageResultConfirm}
                handleClose={handleCloseConfirmDialog} />
            </Grid>
        
            </div>
        </div>
        
    )
}

export default PersonNewBudgeted
