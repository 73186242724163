import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import RestoreIcon from '@material-ui/icons/Restore';
import './BottomNavigationCustom.css';
import Tray from '../tray/Tray';
import Record from '../record/Record';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import ToolbarMain from './ToolbarMain'
import {MenuContext} from '../../context/MenuLeftProvider';
import PersonBudgeted from '../personBudgeted/PersonBudgeted';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    '& .MuiButtonBase-root': {
      fontSize: "1.2em",
    },
    '& .MuiTab-wrapper': {
      display: 'inline-block',
      textTransform: 'none'
    },
    '& .MuiSvgIcon-root':{
      marginRight: 10
    },
    '& .MuiTab-labelIcon': {
      minHeight: '50px'
    }
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  //console.log('tab panel');
  //console.log(parseInt(value)); 
  if(value === 0){
    localStorage.setItem('tabCurrent', 0);
  } else{
    localStorage.setItem('tabCurrent', 1);
  }
  
  
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

export default function IconTabs(props) {
  const classes = useStyles();
  //const [value, setValue] = React.useState(0);  
  //const [tabDetail, setTabDetail] = React.useState(0);  


  const {menuLeft,handleMenuLeft} = React.useContext(MenuContext);

  function a11yProps(index) {
    //localStorage.setItem('tabRequest', index)

    return {
      id: `scrollable-force-tab-${index}`,
      'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
  }

  return (
    <div>
        <ToolbarMain name={props.nameTab}/>

        {/**OCULTAR TODO EL DISEÑO ANTERIOR */}
        <div className={menuLeft.hide ? 'hideDiv' : 'showDiv'}>
        <TabPanel id="tabTray" value={props.value} index={0}  >
          <Tray/>
        </TabPanel>
        
        <TabPanel value={props.value} index={1}  >
          <div className="ex1 heightRecord">
            <Record/>
          </div>          
        </TabPanel>
        
        <Paper square className={classes.root} style={{position:"absolute",bottom:0,width:"100%"}}>          
            <Tabs
                value={props.value}
                onChange={props.handleChange}
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
                aria-label="icon tabs"
            > 
                <Tab 
                    label="Bandeja" 
                    icon={<AllInboxIcon />} 
                    aria-label="account"  {...a11yProps(0)}
                    className="borderBottom"
                >
                  
                </Tab>             
            
                <Tab 
                    label="Historial" 
                    icon={<RestoreIcon />} 
                    aria-label="dashboard"  {...a11yProps(1)} 
                    className="borderBottom"
                >            
                </Tab>
            </Tabs>
        </Paper>    
    
        </div>
        {/** DISEÑO DE VISTA PERSONAS PRESUPUESTADAS */}
        <div className={menuLeft.hide ? 'showDiv' : 'hideDiv'}>
          <div style={{padding:15}}>
            <PersonBudgeted />
          </div>          
        </div>
        
    </div>
  );
}
