import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Redirect } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: "white",
    backgroundColor: "#47525E !important"
  },
  toolbarCamera: {
    background: "transparent !important",
    position: "fixed"
  },
  title: {
    flexGrow: 1,
  },
}));

export default function ToolbarTakePicture() {
  const classes = useStyles();
  const [returnRegister, setReturnRegister] = React.useState(false);

  const handleReturn = () => {
    setReturnRegister(true);
  };

  if(returnRegister){
      return <Redirect to="/Register" />
  }

  return (    
      <AppBar position="static" className={classes.toolbarCamera}>
        <Toolbar>
            <IconButton 
                edge="start" 
                className={classes.menuButton} 
                color="inherit" 
                aria-label="menu"
                onClick={handleReturn}
            >
                <KeyboardBackspaceIcon />
            </IconButton>
            <Typography 
                variant="h6" 
                className={classes.title} 
                style={{textAlign:'Center', display:"none"}}
            >
                Tomar Foto
            </Typography>          
        </Toolbar>
      </AppBar>
  );
}
