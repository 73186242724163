import React from 'react'
import moment from 'moment'
import { TextField } from '@material-ui/core'


    moment().locale('es')
    //moment().locale('pe').format('LLLL')}
    //moment.locale(window.navigator.userLanguage || window.navigator.language)

    var fecha = new Date();
    var day = fecha.getDate();
    var anio = fecha.getFullYear();

    moment.updateLocale('es', {
        weekdays : [
            "Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"
        ],
        months: [
            'Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'
        ]
    });

    const getNameMonth = (val) =>{
        switch(val){
            case 0:
                return 'Enero';
            case 1: 
                return 'Febrero'
            case 2:
                return 'Marzo';
            case 3:
                return 'Abril'
            case 4:
                return 'Mayo'
            case 5:
                return 'Junio'
            case 6:
                return 'Julio'
            case 7:
                return 'Agosto'
            case 8:
                return 'Septiembre'
            case 9:
                return 'Octubre'
            case 10:
                return 'Noviembre'
            case 11:
                return 'Diciembre'
        }
    }

const TimeLocal = () => {
    return (
        <>
            {//                 
                //moment().format('dddd')+" | "+
                //moment().format('LLLL')+" | "+
                //day +" | "+
                //anio+" | "+
                //moment().month()+" | "+
                //getNameMonth(1)+" | "+
                moment().format('dddd') + ", "+day+" de "+getNameMonth(moment().month())+" del "+anio
            }                    
        </> 
    )
}

export const TimeLocalText = () => {
    return (
        <TextField
            id="txtDateRegister"
            value={moment().format('dddd') + ", "+day+" de "+getNameMonth(moment().month())+" del "+anio}
            disabled
            className="widthCustom"
        />
        
    )
}

export default TimeLocal

