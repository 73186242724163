import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Fab from '@material-ui/core/Fab';
import {Grid} from '@material-ui/core'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SelectPictureType(props) { 

  const eventListner = React.useCallback(()=>{

  },[props.presentes,props.tardanzas]);

  React.useEffect(()=>{
    
  },[eventListner])

  return (    
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description" style={{textAlign: "center" }}>
            <Grid container>
                <Grid item xs={12}>
                    {"Foto de Personal"}
                </Grid>
                {
                  props.presentes !== undefined ? (
                    props.presentes !== null ? (
                      parseInt(props.presentes) > 0 ? (
                        <Grid item xs={12} style={{marginTop: 15}}>
                          <Fab 
                              variant="extended"
                              className={"colorBtnConfirmBackground letterCapitalize "}
                              onClick={props.handleClickATiempo}>
                              A tiempo
                          </Fab>
                        </Grid>
                      ):("")
                    ):("")
                  ):("")
                }
                {
                  props.tardanzas !== undefined ? (
                    props.tardanzas !== null ? (
                      parseInt(props.tardanzas) > 0 ? (
                        <Grid item xs={12} style={{marginTop: 15}}>
                          <Fab 
                              variant="extended"
                              className={"colorBtnConfirmBackground letterCapitalize "}
                              onClick={props.handleClickTardanza}>
                              Tardanza
                          </Fab>
                      </Grid>
                      ):("")
                    ):("")
                  ):("")
                }                
            </Grid>
          </DialogContentText>
        </DialogContent>
      </Dialog>
  );
}
