import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Redirect } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function ToolbarRegisterDetail() {
  const classes = useStyles();
  const [returnMain, setReturnMain] = React.useState(false);

  const handleReturn = () => {
    setReturnMain(true);
  };

  if(returnMain){
    return <Redirect to="/Main" />
  }

  return (    
      <AppBar position="static">
        <Toolbar>
            <IconButton 
                edge="start" 
                className={classes.menuButton} 
                color="inherit" 
                aria-label="menu"
                onClick={handleReturn}
            >
                <KeyboardBackspaceIcon />
            </IconButton>
            <Typography 
                variant="h6" 
                className={classes.title} 
                style={{textAlign:'Center'}}
            >
                Ver Detalle
            </Typography>          
        </Toolbar>
      </AppBar>
  );
}
