import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import './Login.css';
import BodyLogin from './BodyLogin';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height:'150%'
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));

export default function ToolbarLogin() {
  
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" className="heightCustomLogin">
        <Toolbar variant="dense">
            <Grid container >
              <Grid item xs={12} style={{textAlign:'center',marginTop:80}}>
                  <Typography variant="h6" color="inherit">
                      {process.env.REACT_APP_NAME_APPLICATION}
                  </Typography>
              </Grid>
              
              <Grid item xs={12} style={{marginTop:30, textAlign: "center"}}>                            
                <img 
                    src={process.env.PUBLIC_URL + '/images/jockeyLogo.png'} 
                    className="widthLogoJockey"
                    alt="login"
                />                            
              </Grid>
          
            </Grid>          
        </Toolbar>
      </AppBar>
      <BodyLogin/>
      
    </div>
  );
}

