import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import ToolbarPreview from '../toolbar/ToolbarPreview';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ImagePreview(props) {
    const urlprev = localStorage.getItem('image-preview');
    //const classes = useStyles();
    //const [open, setOpen] = React.useState(false);
    /*
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };*/

  return (    
      <Dialog style={{width:'100%'}} /*fullScreen*/ open={props.open} onClose={props.handleClose} TransitionComponent={Transition}>        
        <ToolbarPreview handleClose = {props.handleClose}/>
        <Zoom>
          <img  src={urlprev}  alt="image" style={{width:'100%'}} /> 
        </Zoom>        
      </Dialog>
  );
}

