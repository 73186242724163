import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import TableFilter from '../table/TableFilter';
import DateFilterBudgeted from '../record/DateFilterBudgeted';
import Progress from '../progress/Progress';
import AlertDialog from '../dialogs/AlertDialog';
import moment from 'moment'
import '../record/Record.css';
import './HeightDynamic.css';
import TokenExpired from '../constantes/TokenExpired';
import TableBudgeted from '../table/TableBudgeted';
//import ButtonNewRegister from '../button/ButtonNewRegister'
//import ButtonNewPersonBudgeted from '../button/ButtonNewPersonBudgeted';
//import PersonNewBudgeted from '../personNewBudgeted/PersonNewBudgeted';

import {MenuContext} from '../../context/MenuLeftProvider';
import KeyValue from '../../config/KeyValue';
import UnauthorizedDialog from '../dialogs/UnauthorizedDialog';
//import NewPersonBudgetedDialog from '../dialogs/NewPersonBudgetedDialog';
import ToolbarPersonBudgeted from './ToolbarPersonBudgeted';
import { useHistory } from "react-router-dom";
import DateFilterTrayBudgeted from '../record/DateFilterTrayBudgeted';

const PersonBudgeted = (props) => {
    const {menuLeft,getDateLocalFirstDayOfMonthDate,getDateLocalDate,getDateFormatYYYYMMDD,getDateFormatYYYYMMDD_FirstDayOfMonth} = React.useContext(MenuContext);    
    // The first commit of Material-UI
    //------------------------flag vencimiento de token------------------------------
    let history = useHistory();
    const [showUnauthorized, setShowUnauthorized] = React.useState(false);
    //--------------
    //const [selectedDate, setSelectedDate] = React.useState(new Date(getDateLocal()))//React.useState(new Date(fechaString));    
    const [dateInit, setDateInit] = React.useState(new Date(getDateLocalFirstDayOfMonthDate()));
    const [dateFinal, setDateFinal] = React.useState(new Date(getDateLocalDate()));
    
    const [listArea, setListArea] =React.useState([]);
    const [listCompany, setListCompany] = React.useState([]);    
    //---------
    //const [renderPersonNewBudgeted, setRenderPersonNewBudgeted] = React.useState(false);
    //--object post      
    const [areaId, setAreaId] = React.useState(0);
    const [companyId, setCompanyId] = React.useState(0);
    const [dateRegisterInitFilter, setDateRegisterInitFilter] = React.useState(getDateFormatYYYYMMDD_FirstDayOfMonth().toString())
    const [dateRegisterFinalFilter, setDateRegisterFinalFilter] = React.useState(getDateFormatYYYYMMDD().toString())
    const [daySelected, setDaySelected] = React.useState("0");
    
    //----loader----
    const [loading, setLoading] = React.useState(false);
    //---message error-----
    const [show, setShow] = React.useState(false);
    const [messageError, setMessageError] = React.useState(false);    
    //---------
    const handleClose = () => {
        setShow(false)       
    };
    const ShowDialogMessage = (message) =>{
        setShow(true);
        setMessageError(message);
    }
    //----------- impl method async ------------------------------------
    const asyncCallMethod = (typeCall,obj,methodAction,typeAction) =>{
        const URL_BASE = process.env.REACT_APP_URL_BASE_PORT;
        const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN) 

        const paramsHeadersPost = {
            method:typeCall,
            mode: 'cors',
            headers: {
                'Accept':'application/json',
                'Authorization': 'Bearer ' + tokenLocalStorage,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(obj)
        }
        const paramsHeadersGet = {
            method:typeCall,
            mode: 'cors',
            headers: {
                'Accept':'application/json',
                'Authorization': 'Bearer ' + tokenLocalStorage,
                'Content-type': 'application/json'
            }
        }
        const header = obj !== null ? paramsHeadersPost : paramsHeadersGet ;
        const URL = URL_BASE+methodAction;    
        setLoading(true);    
            try{
                fetch(URL,header).then(function(res){
                if(res.status === 401){
                    return 401;
                }
                return res.json();
                }).then(function(response){    
                setLoading(false);

                if(response === 401){
                    setShowUnauthorized(true);

                }else if(response.Code === 0){
                    SetConfigResponse(response,typeAction);                    

                }else if (response.Code === 1){
                    errorCode1(response);
            
                }else if (response.Code === 2){
                    errorCode2(response);
                }
                }).catch((error) =>{
                setLoading(false);
                console.log(error);
                })
            }catch(e){
            setLoading(false);
            console.log(e);
            }                 
    }
    const SetConfigResponse = (response,typeAction)=>{
        if(typeAction === KeyValue.ACTION_GET_LIST_AREA){
            ResponseGetListArea(response);
        }else if (typeAction === KeyValue.ACTION_GET_LIST_COMPANY){
            ResponseGetListCompany(response);
        } 
    }
    const errorCode1 = (response) =>{
        var message = "";
        if(response.Data.StatusCode === 500){
            ShowDialogMessage(response.Data.Message);
        }
        else if(response.Data.Errors.length > 0){
            response.Data.Errors.forEach(element => {
                //console.log(element);
                message = `${element}.`
            });
            ShowDialogMessage(message);                        
        }
    }
    const errorCode2 = (response) =>{
        var message = "";
        if(response.Data.Errors.length > 0){
            response.Data.Errors.forEach(element => {
                //console.log(element);
                message = message +  ` ${element.FieldName}. ${element.Message}. `
            });
            ShowDialogMessage(message);                        
        }
    }
    //-------------------INICIO ---------------------------------
    const eventListner = React.useCallback(() => {
        //console.log(state); // use the stateful variable in event listener
    }, [menuLeft]);

    React.useEffect(() =>{
        /*
        if(menuLeft){
            setRenderPersonNewBudgeted(false);
        }*/
        GetListArea();        
    },[eventListner]);

    /** OBTENER LISTADO DE AREAS */
    const GetListArea = () =>{
        const methodAction = process.env.REACT_APP_API_GET_LIST_AREA;
        asyncCallMethod('get',null,methodAction,KeyValue.ACTION_GET_LIST_AREA);
        /*
        setShowProgress(true);
        const URL_BASE = process.env.REACT_APP_URL_BASE_PORT;
        const GET_LIST_AREA = process.env.REACT_APP_API_GET_LIST_AREA;
        const URL = URL_BASE+GET_LIST_AREA;
        const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN)
        try{
            fetch(URL,{
                method:'get',
                mode: 'cors',
                headers: {
                    'Accept':'application/json',                                    
                    'Authorization': 'Bearer ' + tokenLocalStorage,
                    'Content-type': 'application/json'
                }

            }).then(function(res){
                return res.json();
            }).then(function(response){
                //console.log(response);                
                setShowProgress(false);

                if(response.Code === 0){
                    setListArea(response.Data.UsuarioAreas);

                }else if(response.Code === 2){
                    //console.log(response.Data.Errors[0].FieldName)
                    showDialogError(response.Data.Errors[0].Message)
                }

            }).catch((error) =>{
                showDialogError(error);
            })
        }catch(e){
            showDialogError(e);
        }
        */
    };
    const ResponseGetListArea = (response) =>{
        setListArea(response.Data.UsuarioAreas);
    }
    /** OBTENER LISTADO DE COMPAÑÍAS */
    const GetListCompany = (areaId) =>{
        const methodAction = process.env.REACT_APP_API_GET_LIST_COMPANY;
        const obj = {
            AreaId: parseInt(areaId)
        }        
        if(areaId !== 0){
            asyncCallMethod("post",obj,methodAction,KeyValue.ACTION_GET_LIST_COMPANY);
        }else{
            setCompanyId(0);
            setListCompany([]);
        }
    };
    const ResponseGetListCompany = (response) => {
        setListCompany(response.Data.UsuarioEmpresas);
    }
    //---------- MANEJADORES DE EVENTOS ---------------

    const handleDateInit = (date) => {
        setDateRegisterInitFilter(getFormatYYYYMMDD(date).toString());
        setDateInit(date);
    };
    const handleDateFinal = (date) => {
        setDateRegisterFinalFilter(getFormatYYYYMMDD(date).toString());
        setDateFinal(date);
    };
    const getFormatYYYYMMDD = (date) => {
        //console.log('handleDateChange month');
        //var fechaActual = new Date();
        //console.log(fechaActual)
        //moment()
        //console.log(date);
        //console.log(moment());
        //console.log(moment().format("YYYY-MM-DD[T]HH:mm:ss"))
        //console.log(date.format("YYYY-MM-DD[T]HH:mm:ss"))
        var month = date.format('M'); // date es un objeto moment
        var intMonth = parseInt(month);
        if (intMonth < 10){
            month= "0"+month;
        }
        var day   = date.format('D');
        var intDay = parseInt(day);
        if(intDay < 10){
            day = "0" + day;
        }
        var year  = date.format('YYYY'); 
        return year +"-"+month+"-"+day;
    }
    const handleChangeArea = (e) =>{
        var area = e.target.value;
        if(area.toString() !== ""){            
            setAreaId(parseInt(area))
            GetListCompany(parseInt(area))
        }else{
            setAreaId(0)
            GetListCompany(0)
        }        
    };
    const handleChangeCompany = (e) =>{
        var company = e.target.value;
        if(company !== ""){
            setCompanyId(parseInt(e.target.value))
        }else{
            setCompanyId(0)
        }        
    };
    const handleClickPersonNewBudgeted = () =>{
        localStorage.setItem(KeyValue.KEY_LIST_AREA_BUDGETED,JSON.stringify(listArea))
        history.push("/NewBudgeted");
    };/*
    const handleCloseNewPersonBudgetedDialog = () =>{
        setRenderPersonNewBudgeted(false);
    }*/
    const handleChangeAllDays = (e) =>{
        setDaySelected(e.target.value)
    }
    //-----------------Renders----------------------
    
    
    return (
        <div>
            <ToolbarPersonBudgeted />
            <div className="ex2 heightRecord2" style={{overflow:'auto'}}>
                    
                <Grid container style={{padding:24}}>
                    <TokenExpired />

                    <Grid item md={3} xs={12}></Grid>

                    <Grid item md={3} xs={12} className="style-bettween-div">
                        <Typography variant="body2" style={{textAlign:'left'}}>
                            Fecha Desde
                        </Typography>
                        <div className="form-group" >                    
                            <DateFilterTrayBudgeted selectedDate={dateInit} handleDateChange={handleDateInit}/>
                        </div>                
                    </Grid>

                    <Grid item md={3} xs={12} className="style-bettween-div">
                        <Typography variant="body2" style={{textAlign:'left'}}>
                            Fecha Hasta
                        </Typography>
                        <div className="form-group">                    
                            <DateFilterTrayBudgeted selectedDate={dateFinal} handleDateChange={handleDateFinal}/>
                        </div>
                    </Grid>
                    <Grid item md={3} xs={12}></Grid>
                    
                    <Grid item md={3} xs={12}></Grid>
                    {/** listado de areas */}
                    <Grid item xs={12} md={2} className="style-bettween-div">
                        <Typography variant="body2" className="alignSubTitle">
                            Área
                        </Typography>
                        <div className="form-group" >                    
                            <select 
                                className="form-control selectNoBorder" 
                                id="selectListArea2"
                                onChange={(e) =>handleChangeArea(e)}
                                value={areaId}
                            >
                                <option key='25' value="" style={{textAlign:"center"}} >Todos</option>
                                {
                                    listArea.map((item,key) =>
                                    <option key={item.AreaId} 
                                            value={item.AreaId}
                                    >
                                    {item.AreaNombre}
                                    </option>
                                )
                                }                      
                            </select>
                        </div>
                    </Grid>
                    
                    {/** listado de empresas */}
                    <Grid item xs={12} md={2} className="style-bettween-div">
                        <Typography variant="body2" className="alignSubTitle">
                            Empresa
                        </Typography>
                        <div className="form-group" >                    
                            <select 
                                className="form-control selectNoBorder" 
                                id="selectListEmpresa3"
                                onChange={(e) =>handleChangeCompany(e)}
                                value={companyId}
                            >
                                <option key='24' value="" style={{textAlign:"center"}} >Todos</option>
                                {
                                    listCompany.map(item => 
                                    <option key={item.EmpresaId} value={item.EmpresaId} style={{textAlign:"center"}}>{item.EmpresaNombre}</option>
                                    )
                                }
                            </select>
                        </div>
                    </Grid>
                    {/** listado de  */}
                    <Grid item xs={12} md={2} className="style-bettween-div">
                        <Typography variant="body2" className="alignSubTitle">
                            Día Presupuestado
                        </Typography>
                        <div className="form-group" >                    
                            <select 
                                className="form-control selectNoBorder" 
                                id="selectListEmpresa3"
                                onChange={(e) =>handleChangeAllDays(e)}
                                value={daySelected}
                            >
                                <option key={'0'} value={"0"} style={{textAlign:"center"}} >Todos</option>
                                <option key={'1'} value={'1'} style={{textAlign:"center"}}>Lunes</option>
                                <option key={'2'} value={'2'} style={{textAlign:"center"}}>Martes</option>
                                <option key={'3'} value={'3'} style={{textAlign:"center"}}>Miercoles</option>
                                <option key={'4'} value={'4'} style={{textAlign:"center"}}>Jueves</option>
                                <option key={'5'} value={'5'} style={{textAlign:"center"}}>Viernes</option>
                                <option key={'6'} value={'6'} style={{textAlign:"center"}}>Sábado</option>
                                <option key={'7'} value={'7'} style={{textAlign:"center"}}>Domingo</option>
                            </select>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={3}></Grid>

                    <Grid item xs={12} md={3}></Grid>
                    <Grid item xs={12} md={6}>
                        <TableBudgeted 
                            dateRegisterInit={dateRegisterInitFilter} 
                            dateRegisterFinal={dateRegisterFinalFilter} 
                            AreaId={areaId} 
                            EmpresaId={companyId}
                            DiaId={daySelected}
                            handleClickPersonNewBudgeted={handleClickPersonNewBudgeted}/>
                    </Grid>
                    <Grid item xs={12} md={3}></Grid>

                    <UnauthorizedDialog open={showUnauthorized} />
                    <AlertDialog open={show} message={messageError} handleClose={handleClose}/>
                    <Progress open={loading} />                      
                </Grid>    
            
            </div>
            
        </div>
        
    )
}

export default PersonBudgeted

