import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from "@material-ui/core/styles";
import './Inputs.css';

const TextNumberCustom = withStyles({
    root: {
      '& label': {
        color: '#808080',
        fontFamily:'Montserrat-Medium',
      },
      '& label.Mui-focused': {
        color: '#808080',
      },
      '& .MuiInput-underline': {
        borderBottomColor: '#808080',
        fontFamily:'Montserrat-Medium',
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#808080',
        fontFamily:'Montserrat-Medium',
      }, 
      '& .MuiInput-underline:after': {  
        borderBottomColor: '#808080',
        fontFamily:'Montserrat-Medium',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#808080',
          borderRadius:18,
        },
        '&:hover fieldset': {
          borderColor: '#808080',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#808080',
        }
      },
    },
  })(TextField);

export default function InputNumber(props) {
  //const classes = useStyles();

  return (
        <TextNumberCustom
            error={props.flagError}
            id={props.id}
            label={props.label}
            onChange={props.onChange}
            helperText={props.flagError ? props.helperText : ""}
            className={'styleWidth80'}
            type="number"
            //variant="outlined"
            autoComplete="off"
            size="small"
            onInput = {(e) =>{
                    e.target.value = e.target.value.toString().slice(0,props.maxLength)//Math.max(0, parseInt(e.target.value) ).toString().slice(0,8)//este codigo no permite poner ceros delante del numero
                }
            }
        />
  );
}
