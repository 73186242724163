import React from 'react'
import ToolbarNewRegister from '../toolbar/ToolbarNewRegister';
import TokenExpired from '../constantes/TokenExpired';

const NewRegister = () => {

    return (
        <div>
            <TokenExpired />
            <ToolbarNewRegister />            
        </div>
    )
}

export default NewRegister
