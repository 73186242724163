import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
//import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import CloseIcon from '@material-ui/icons/Close';
import { Redirect } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    color: "white",
    backgroundColor: "#47525E !important"
  },
  toolbarCamera: {
    background: "transparent !important",
    position: "fixed"
  },
  title: {
    flexGrow: 1,
  },
}));

export default function ToolbarPreview(props) {
  const classes = useStyles();
  /*
  const [returnDetail, setReturnDetail] = React.useState(false);

  const handleReturn = () => {
    setReturnDetail(true);
  };

  if(returnDetail){
      return <Redirect to="/RegisterDetail" />
  }
*/
  return (    
      <AppBar position="static" className={classes.toolbarCamera}>
        <Toolbar style={{width:'100%',paddingRight:50,flexDirection:'row-reverse'}}>
          <div>
            <IconButton 
              edge="start" 
              className={classes.menuButton} 
              color="inherit" 
              aria-label="menu"
              onClick={props.handleClose}
            >
                <CloseIcon />
            </IconButton>  
          </div>          
                   
        </Toolbar>
      </AppBar>
  );
}