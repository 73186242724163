import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LoginApp from "./components/login/Login";
import MainApp from "./components/main/Main";
import RegisterApp from "./components/newRegister/NewRegister";
import TakePictureApp from "./components/camera/Camera";
import MessageFinal from "./components/newRegister/Message";
import RegisterDetail from "./components/registerDetail/RegisterDetail";
import ImagePreview from "./components/ImagePreview/ImagePreview";
import PersonBudgeted from "./components/personBudgeted/PersonBudgeted";
import PersonNewBudgeted from "./components/personNewBudgeted/PersonNewBudgeted";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path={`/`} exact component={LoginApp} />
          <Route path={`/Main`} exact component={MainApp} />
          <Route path={`/Register`} component={RegisterApp} />
          <Route path={`/Picture`} component={TakePictureApp} />
          <Route path={`/MessageFinal`} component={MessageFinal} />
          <Route path={`/RegisterDetail`} component={RegisterDetail} />
          <Route path={`/EditRegister`} component={RegisterApp} />
          <Route path={`/Preview`} component={ImagePreview} />
          <Route path={'/TrayBudgeted'} component={PersonBudgeted} />
          <Route path={'/NewBudgeted'} component={PersonNewBudgeted} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
