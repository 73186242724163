import React from 'react'
import ToolbarRegisterDetail from '../toolbar/ToolbarRegisterDetail'
import RegisterViewDetail from './RegisterViewDetail'
import TokenExpired from '../constantes/TokenExpired'

const RegisterDetail = () => {

    return (
        <div>
            <TokenExpired />
            <ToolbarRegisterDetail />
            <RegisterViewDetail />
        </div>
    )
}

export default RegisterDetail
