import React from "react";
import Progress from "../progress/Progress";
import AlertDialog from "../dialogs/AlertDialog";
import {
  Grid,
  TextField,
  TextareaAutosize,
  Card,
  CardContent,
  Typography,
  IconButton,
  makeStyles,
  red,
  Collapse
} from "@material-ui/core";
import "./Detail.css";
import moment from "moment";
import ButtonCloseDetail from "../button/ButtonCloseDetail";
import ButtonEditDetail from "../button/ButtonEditDetail";
import ButtonConfirmDetail from "../button/ButtonConfirmDetail";
import { Redirect } from "react-router-dom";
import ImagePreview from "../ImagePreview/ImagePreview";
import MessageConfirm from "../newRegister/MessageConfirm";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import Alert from '@material-ui/lab/Alert';
import Swal from 'sweetalert2';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  }
}));

const RegisterViewDetail = (props) => {
  const overElipse = "block-ellipsis";
  const classes = useStyles();
  const tokenLocalStorage = localStorage.getItem(
    process.env.REACT_APP_KEY_TOKEN
  );
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  //---FLAG EDITIONS
  const [showEdition, setShowEdition] = React.useState(false);
  //FLAG CONFIRM IS SUPERVISOR
  const [showConfirm, setShowConfirm] = React.useState(false);
  //ES VALIDO LA CONFIRMACION
  const [showIsValidConfirm, setIsValidConfirm] = React.useState(false);

  const [goViewEdition, setGoViewEdition] = React.useState(false);
  const [returnTray, setReturnTray] = React.useState(false);
  //--progress----
  const [showProgress, setShowProgress] = React.useState(false);
  //---message error---
  const [show, setShow] = React.useState(false);
  const [messageError, setMessageError] = React.useState(false);
  //--- object detail---
  const [dateRegister, setDateRegister] = React.useState("");
  const [areaDetail, setAreaDetail] = React.useState(0);
  const [companyDetail, setCompanyDetail] = React.useState(0);
  const [turnDetail, setTurnDetail] = React.useState("");
  const [asignDetail, setAsignDetail] = React.useState(0);
  const [presDetail, setPresDetail] = React.useState(0);
  const [faltDetail, setFaltoDetail] = React.useState(0);
  const [tardDetail, setTardDetail] = React.useState(0);
  const [calpresenDetail, setCalPresenDetail] = React.useState(0);
  const [novDetail, setNovDetail] = React.useState("");
  const [listImageDetail, setListImageDetail] = React.useState([]);

  //auditorias
  const [nameusugraboDetail, setNameUsuGraboDetail] = React.useState("");
  const [fechahorausugraboDetail, setFechaHoraUsuGraboDetail] = React.useState("");
  const [nameusumodificoDetail, setNameUsuModificoDetail] = React.useState("");
  const [fechahorausumodificoDetail, setFechaHoraUsuModificoDetail] = React.useState("");
  const [nameusuautorizoDetail, setNameUsuAutorizoDetail] = React.useState("");
  const [fechahorausuautorizoDetail, setFechaHoraUsuAutorizoDetail] = React.useState("");

  //Autorizado
  const [autorizadoDetail, setAutorizadoDetail] = React.useState("");
  

  //---
  const [showDescription, setShowDescription] = React.useState(false);
  const [hideDescriptionMore, setHideDescriptionMore] = React.useState("");
  const [urlPreview, setUrlPreview] = React.useState("");
  const [goImagePreview, setGoImagePreview] = React.useState(false);
  const [returnListConfirm, setReturnListConfirm] = React.useState(false);

  //---moment options--
  moment.updateLocale("en", {
    weekdays: [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado",
    ],
    months: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ],
  });
  const getNameMonth = (val) => {
    switch (val) {
      case 0:
        return "Enero";
      case 1:
        return "Febrero";
      case 2:
        return "Marzo";
      case 3:
        return "Abril";
      case 4:
        return "Mayo";
      case 5:
        return "Junio";
      case 6:
        return "Julio";
      case 7:
        return "Agosto";
      case 8:
        return "Septiembre";
      case 9:
        return "Octubre";
      case 10:
        return "Noviembre";
      case 11:
        return "Diciembre";
    }
  };
  //----moment options--

  const handleCloseDialog = () => {
    setShow(false);
  };

  const showDialogError = (msg) => {
    setMessageError(msg);
    setShow(true);
  };

  React.useEffect(() => {
    var code = "";
    code = JSON.parse(localStorage.getItem("code"));

    GetDetail(code);
    GetValidateEdition(code);
    GetValidateConfirm(code);
    GetIsValidConfirm(code);
  }, []);

  const handleClosePreview = () => {
    setGoImagePreview(false);
  };

  if (returnListConfirm) {
    return <MessageConfirm />;
  }
  const GetValidateEdition = (code) => {
    setShowProgress(true);

    const URL_BASE = process.env.REACT_APP_URL_BASE_PORT;
    const POST_VALIDATION_EDITION =
      process.env.REACT_APP_API_POST_VALIDATION_EDITION + "/" + code.toString();
    const URL = URL_BASE + POST_VALIDATION_EDITION;

    try {
      fetch(URL, {
        method: "get",
        mode: "cors",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + tokenLocalStorage,
          "Content-type": "application/json",
        },
      })
        .then(function (res) {
          return res.json();
        })
        .then(function (response) {
          //console.log(response);
          setShowProgress(false);

          if (response.Code === 0) {
            if (response.Data.Success) {
              setShowEdition(true);
            }
          } else if (response.Code === 2) {
            //console.log(response.Data.Errors[0].FieldName)
            showDialogError(response.Data.Errors[0].Message);
          } else if (response.Code === 1) {
            showDialogError(response.Data.Message);
          }
        })
        .catch((error) => {
          showDialogError(error);
        });
    } catch (e) {
      showDialogError(e);
    }
  };

  const GetValidateConfirm = (code) => {
    setShowProgress(true);

    const URL_BASE = process.env.REACT_APP_URL_BASE_PORT;
    const GET_VALIDATION_CONFIRM =
      process.env.REACT_APP_API_POST_IS_SUPERVISOR + "/" + code.toString();
    const URL = URL_BASE + GET_VALIDATION_CONFIRM;
    console.log(URL);
    try {
      fetch(URL, {
        method: "get",
        mode: "cors",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + tokenLocalStorage,
          "Content-type": "application/json",
        },
      })
        .then(function (res) {
          if (res.status === 401) {
            return 401;
          }
          return res.json();
        })
        .then(function (response) {
          //console.log(response);
          setShowProgress(false);
          if (response !== 401) {
            if (response.Code === 0) {
              if (response.Data) {
                setShowConfirm(true);
              }
            } else if (response.Code === 2) {
              //console.log(response.Data.Errors[0].FieldName)
              showDialogError(response.Data.Errors[0].Message);
            } else if (response.Code === 1) {
              showDialogError(response.Data.Message);
            }
          }
        })
        .catch((error) => {
          showDialogError(error);
        });
    } catch (e) {
      showDialogError(e);
    }
  };

  const GetIsValidConfirm = (code) => {
    setShowProgress(true);

    const URL_BASE = process.env.REACT_APP_URL_BASE_PORT;
    const GET_IS_CONFIRM =
      process.env.REACT_APP_API_POST_VALIDATE_IS_CONFIRM +
      "/" +
      code.toString();
    const URL = URL_BASE + GET_IS_CONFIRM;
    console.log(URL);
    try {
      fetch(URL, {
        method: "get",
        mode: "cors",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + tokenLocalStorage,
          "Content-type": "application/json",
        },
      })
        .then(function (res) {
          if (res.status === 401) {
            return 401;
          }
          return res.json();
        })
        .then(function (response) {
          //console.log(response);
          setShowProgress(false);
          if (response !== 401) {
            if (response.Code === 0) {
              if (response.Data) {
                setIsValidConfirm(true);
              }
            } else if (response.Code === 2) {
              //console.log(response.Data.Errors[0].FieldName)
              showDialogError(response.Data.Errors[0].Message);
            } else if (response.Code === 1) {
              showDialogError(response.Data.Message);
            }
          }
        })
        .catch((error) => {
          showDialogError(error);
        });
    } catch (e) {
      showDialogError(e);
    }
  };

  const GetDetail = (code) => {
    setShowProgress(true);
    var obj = {
      CodeTercero: code,
    };

    const URL_BASE = process.env.REACT_APP_URL_BASE_PORT;
    const POST_DETAIL_REGISTER = process.env.REACT_APP_API_POST_DETAIL_REGISTER;
    const URL = URL_BASE + POST_DETAIL_REGISTER;

    try {
      fetch(URL, {
        method: "post",
        mode: "cors",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + tokenLocalStorage,
          "Content-type": "application/json",
        },
        body: JSON.stringify(obj),
      })
        .then(function (res) {
          return res.json();
        })
        .then(function (response) {
          setShowProgress(false);

          if (response.Code === 0) {
            setDataDetail(response.Data);
          } else if (response.Code === 2) {
            //console.log(response.Data.Errors[0].FieldName)
            showDialogError(response.Data.Errors[0].Message);
          } else if (response.Code === 1) {
            showDialogError(response.Data.Message);
          }
        })
        .catch((error) => {
          showDialogError(error);
        });
    } catch (e) {
      showDialogError(e);
    }
  };

  const PostUpdateFlagConfirmRegister = () => {
    var code = JSON.parse(localStorage.getItem("code"));
    var obj = {
      CodeTercero: code,
    };

    setShowProgress(true);
    const URL_BASE = process.env.REACT_APP_URL_BASE_PORT;
    const POST_UPDATE_CONFIRM_REGISTER =
      process.env.REACT_APP_API_POST_CONFIRMATION_REGISTER;
    const URL = URL_BASE + POST_UPDATE_CONFIRM_REGISTER;

    try {
      fetch(URL, {
        method: "post",
        mode: "cors",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + tokenLocalStorage,
          "Content-type": "application/json",
        },
        body: JSON.stringify(obj),
      })
        .then(function (res) {
          if (res.status === 401) {
            return 401;
          }
          return res.json();
        })
        .then(function (response) {
          setShowProgress(false);

          if (response !== 401) {
            if (response.Code === 0) {
              if (response.Data.Success) {
                setReturnListConfirm(true);
              } else {
                showDialogError(response.Data.Message);
              }
            } else if (response.Code === 2) {
              //console.log(response.Data.Errors[0].FieldName)
              showDialogError(response.Data.Errors[0].Message);
            } else if (response.Code === 1) {
              showDialogError(response.Data.Message);
            }
          }
        })
        .catch((error) => {
          showDialogError(error);
        });
    } catch (e) {
      showDialogError(e);
    }
  };

  const handleUpdateFlagConfirmRegister = () => {
    Swal.fire({
      title: 'Confirmar',
      text: "¿Esta seguro que desea Confirmar?",
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#47525E',
      cancelButtonColor: '#8F9394',
      confirmButtonText: 'Si, Confirmar!'
    }).then((result) => {
      if (result.isConfirmed) {
        PostUpdateFlagConfirmRegister();
      }
    })
  };

  const setDataDetail = (detail) => {
    //fecha hora cliente
    var mydate = new Date(detail.FechaHoraCliente);
    var day = mydate.getDate();
    var anio = mydate.getFullYear();

    var newDate = moment(mydate);

    var form =
      newDate.format("dddd") +
      ", " +
      day +
      " de " +
      getNameMonth(newDate.month()) +
      " del " +
      anio;

    setDateRegister(form);
    setAreaDetail(detail.AreaNombre);
    setCompanyDetail(detail.EmpresaNombre);
    setTurnDetail(detail.Turno);
    setAsignDetail(detail.NroAsignados);
    setPresDetail(detail.NroPresentes);
    setFaltoDetail(detail.NroFaltas);
    setTardDetail(detail.NroTardanzas);
    setCalPresenDetail(detail.NroCalPresen);
    //auditorias
    setNameUsuGraboDetail(detail.UsuarioGuardo);
    setFechaHoraUsuGraboDetail(detail.CFechayHoraGuardo);
    setNameUsuModificoDetail(detail.UsuarioActualizo);
    setFechaHoraUsuModificoDetail(detail.CFechayHoraActualizo);
    setNameUsuAutorizoDetail(detail.UsuarioAutorizo);
    setFechaHoraUsuAutorizoDetail(detail.CFechayHoraAutorizo);
    setAutorizadoDetail(detail.Autorizado);
    if (detail.Novedades !== null) {
      setNovDetail(detail.Novedades);
    } else {
      setNovDetail("");
    }
    setListImageDetail(detail.ListaTerceroFoto);
    var ancho = window.screen.width;
    if (ancho > 1000) {
      setHideDescriptionMore("hideDescriptionMore");
    } else {
      setHideDescriptionMore("showDescriptionMore");
    }
  };

  const handleClose = () => {
    setReturnTray(true);
  };

  const handleEditDetail = () => {
    localStorage.setItem("edit", 1);
    localStorage.setItem("editView", 1);
    setGoViewEdition(true);
  };

  const handleMoreDescription = () => {
    setShowDescription(true);
  };
  const handleHideDescription = () => {
    setShowDescription(false);
  };

  const handlePreviewImage = (url) => {
    setGoImagePreview(true);
    localStorage.setItem("image-preview", url.toString());
    setUrlPreview(url.toString());
    //console.log(url);
  };
  /*
    if(goImagePreview){
        return <Redirect to="/Preview" />
    }*/

  if (returnTray) {
    return <Redirect to="/Main" />;
  }

  if (goViewEdition) {
    return <Redirect to="/EditRegister" />;
  }

  return (
    <Grid container style={{ paddingLeft: 20, paddingRight: 20 }}>
      <Grid item xs={12} style={{ textAlign: "left", marginTop: 10 }}>
        <TextField
          id="txtDateRegister"
          label="Fecha de Registro"
          value={dateRegister}
          disabled
          className="widthCustom"
        />
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center", marginTop: 10 }}>
        <TextField
          id="txtAreaDetail"
          label="Área"
          value={areaDetail}
          disabled
          className="widthCustom"
        />
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center", marginTop: 10 }}>
        <TextField
          id="txtCompanyDetail"
          label="Empresa"
          value={companyDetail}
          disabled
          className="widthCustom"
        />
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center", marginTop: 10 }}>
        <TextField
          id="txtTurnDetail"
          label="Turno"
          value={turnDetail}
          disabled
          className="widthCustom"
        />
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center", marginTop: 10 }}>
        <Grid container>
          <Grid item xs={2} style={{ textAlign: "center", paddingRight: 10 }}>
            <TextField
              id="txtAsignDetail"
              label="Asig."
              value={asignDetail}
              disabled
              className="widthCustom"
            />
          </Grid>
          <Grid item xs={2} style={{ textAlign: "center", paddingRight: 0 }}>
            <TextField
              id="txtCalPresenDetail"
              label="Presen."
              value={calpresenDetail}
              disabled
              className="widthCustom"
            />
          </Grid>
          <Grid item xs={3} style={{ textAlign: "center", paddingRight: 0 }}>
            <TextField
              id="txtPresDetail"
              label="A tiem."
              value={presDetail}
              disabled
              className="widthCustom"
            />
          </Grid>
          <Grid item xs={3} style={{ textAlign: "center", paddingRight: 10 }}>
            <TextField
              id="txtTardDetail"
              label="Tard."
              value={tardDetail}
              disabled
              className="widthCustom"
            />
          </Grid>
          <Grid item xs={2} style={{ textAlign: "center" }}>
            <TextField
              id="txtfaltDetail"
              label="Faltos"
              value={faltDetail}
              disabled
              className="widthCustom"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center", marginTop: 10 }}>
        {novDetail === "" ? (
          <TextareaAutosize
            aria-label="minimum height"
            rowsMin={2}
            value={novDetail}
            placeholder=""
            className="widthCustom"
            //style={{display:'none'}}
            disabled
          />
        ) : (
          <div>
            <div id="detailp" style={{ width: "100%" }}>
              <div class={!showDescription ? overElipse : "fontSizeCustom"}>
                {novDetail}
              </div>
            </div>
            {novDetail.length > 75 ? (
              <div
                style={{ textAlign: "right" }}
                className={hideDescriptionMore}
              >
                {!showDescription ? (
                  <a href="#" onClick={handleMoreDescription}>
                    Ver más
                  </a>
                ) : (
                  <a
                    href="#"
                    onClick={handleHideDescription}
                    style={{ textAlign: "right" }}
                  >
                    Ver menos
                  </a>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </Grid>
      <Grid item xs={12} style={{ textAlign: "left" }}>
        <Typography variant="body2">Captura de Fotos</Typography>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center" }}>
        <Grid container>
          {listImageDetail.length !== 0 ? (
            listImageDetail.map((item) => (
              <Grid item xs={4}>
                <div style={{ margin: 5 }}>
                  <Card style={{ maxWidth: "100%" }}>
                    <CardContent style={{ padding: 0 }}>
                      <Grid container>
                        <Grid item xs={12} style={{textAlign:'center'}}>
                          <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p">
                              {item.DescripcionTipoFoto}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <a
                            href="#"
                            onClick={(e) => handlePreviewImage(item.PathFoto)}
                            value={item.PathFoto}
                          >
                            <img
                              src={item.PathFoto}
                              alt="image"
                              style={{ width: "100%" }}
                            />
                          </a>
                          <Grid style={{ textAlign: "center" }}>
                            <Typography variant="body2">
                              {item.CFechaHoraFoto}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </div>
              </Grid>
            ))
          ) : (
            <Grid
              item
              xs={12}
              style={{ textAlign: "left", marginTop: 10, marginBottom: 20 }}
            >
              <Typography>Sin fotos cargadas.</Typography>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center", marginTop: 15, marginBottom: 15, display: autorizadoDetail? "block": "none" }}>
        <Alert severity="success">
            <Typography style={{fontWeight: "bold"}}>CONFIRMADO</Typography>
        </Alert>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "right" }}>
        <Typography style={{display: "inline-block"}}>
          Auditoría
        </Typography>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </IconButton>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Grid item xs={12} className={"divRounded"}>
                <table className={"roundTable2 " + classes.table} aria-label="customized table">
                  <tr className="headerTitle" >                                        
                    <td colSpan='4' style={{textAlign:'center'}}>Auditorias</td>
                  </tr>
                  <tr>
                    <td align="left" style={{paddingLeft:8}}>Usuario registró</td>
                    <td align="left" style={{textAlign:'left'}}>{nameusugraboDetail}</td>
                  </tr>
                  <tr>
                    <td align="left" style={{paddingLeft:8}}>Fecha y hora registró</td>
                    <td align="center" style={{textAlign:'left'}}>{fechahorausugraboDetail}</td>
                  </tr>
                  <tr>
                    <td align="left" style={{paddingLeft:8}}>Usuario modificó</td>
                    <td align="left" style={{textAlign:'left'}}>{nameusumodificoDetail}</td>
                  </tr>
                  <tr>
                    <td align="left" style={{paddingLeft:8}}>Fecha y hora modificó</td>
                    <td align="left" style={{textAlign:'left'}}>{fechahorausumodificoDetail}</td>
                  </tr>
                  <tr>
                    <td align="left" style={{paddingLeft:8}}>Usuario autorizó</td>
                    <td align="left" style={{textAlign:'left'}}>{nameusuautorizoDetail}</td>
                  </tr>
                  <tr>
                    <td align="left" style={{paddingLeft:8}}>Fecha y hora autorizó</td>
                    <td align="left" style={{textAlign:'left'}}>{fechahorausuautorizoDetail}</td>
                  </tr>
                  <tr >
                  </tr>                                       
                </table>
              </Grid>
        </CardContent>
      </Collapse>
      </Grid>
      <Grid container style={{ backgroundColor: '#F5F5F5',marginTop: '2%',paddingTop:'3%' }}>
        {showEdition && showIsValidConfirm ? (
          <Grid container style={{ textAlign: "center"}}>
            <Grid item xs={6} style={{ textAlign: "center" }}>
              <ButtonCloseDetail handleClick={handleClose} hide={showEdition} />
            </Grid>
            <Grid item xs={6} style={{ textAlign: "center" }}>
              <ButtonEditDetail handleClick={handleEditDetail} />
            </Grid>
          </Grid>
        ) : (
          <Grid container style={{ textAlign: "center" }}>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <ButtonCloseDetail handleClick={handleClose} />
            </Grid>
          </Grid>
        )}

        {showConfirm ? (
          <Grid container>
            <Grid item xs={12} style={{ textAlign: "center" }}>
              <ButtonConfirmDetail
                handleClick={handleUpdateFlagConfirmRegister}
                hide={showConfirm}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container></Grid>
        )}
      </Grid>
      <ImagePreview open={goImagePreview} handleClose={handleClosePreview} />
      <AlertDialog
        open={show}
        message={messageError}
        handleClose={handleCloseDialog}
      />
      <Progress open={showProgress} />
    </Grid>
  );
};

export default RegisterViewDetail;
