import React from 'react'
import JWT from 'jsonwebtoken';
import { Redirect } from 'react-router-dom';

const TokenExpired = () => {
    const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN);
    //---
    const [tokenExpired, setTokenExpired] = React.useState(false);

    const convertTimeStampToDatetime = (timeExp) =>{
        var date = new Date(timeExp * 1000);        
        return date;
    }
    const validateTokenExpiration =()=>{
        const tokenDecode = JWT.decode(tokenLocalStorage);
        var dateExpire = convertTimeStampToDatetime(tokenDecode.exp);
        const PublicKey = 'MIIBCgKCAQEAui/osDjyG4wiXbDD+1RsazW6tv2Xuf6BTJY3+C0o2HmxLCyNYRAJ/JLoF3aE7VbPFEbE6948GnqGqFICy3VGYRebSSEreOgfW6ZGQ+jKszZYZ5mWXlq+urdIkoxNPDHdGI2W/1Nb34vHZEFZnPbSxT4HPn5njUcvMTaLdOkKjVoM3VWlA91yVM6xmJ6qW9b+B4r32IIoKkXo4S2AqSS9H34iAvhhBRX3FU9UifGqawkO8mKeqgmoii8bqNnLiYccs/EPVEeVqdJUeMJq8ExYNri26bmslbm6BUhmHeeAq6+jDwR743DHOREGKXmeM8/e9j5wSUK8x1H+cItHcEpJiQIDAQAB';
        //console.log(dateExpire);
        /*
        JWT.verify(tokenLocalStorage, PublicKey, function(err, decoded) {
            console.log(decoded);
                if (err) {
                    console.log(err)
                    console.log('expiró el token');                
                }else{
                    console.log('aun no expira el token')
                }
            }
        );*/
        var hoy = new Date();
        if(hoy === dateExpire || hoy > dateExpire){
            //console.log('token expirado');
            setTokenExpired(true);
        }else{
            setTokenExpired(false);
            //console.log('aun no expira el token');
        }
    }

    React.useEffect(()=>{
        validateTokenExpiration();
    },[]);

    if (tokenExpired){
        return <Redirect to="/" />
    }

    return (
        <div style={{display:"none"}}></div>
    )
}

export default TokenExpired
