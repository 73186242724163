import React from 'react'
import KeyValue from '../../config/KeyValue'

const VersionApp = () => {
    return (
        <div style={{position:'absolute',bottom:18,right:18}}>
            <p style={{color:'white',fontSize:'0.9rem',fontWeight:500}}>
                {
                    KeyValue.VERSION_APP
                }
            </p>            
        </div>
    )
}

export default VersionApp
