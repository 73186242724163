import React from 'react'
import Webcam from "react-webcam";
import {Grid, Typography} from '@material-ui/core'
import ButtonCancelPicture from '../button/ButtonCancelPicture';
import ButtonTakePicture from '../button/ButtonTakePicture';
import ToolbarTakePicture from '../toolbar/ToolbarTakePicture';
import { Redirect } from 'react-router-dom';
import Progress from '../progress/Progress';
import moment from 'moment'
import AlertDialog from '../dialogs/AlertDialog';
import './Camera.css';
import TokenExpired from '../constantes/TokenExpired';
import UnauthorizedDialog from '../dialogs/UnauthorizedDialog';


const videoConstraints = {
    width:'100%',
    height:'100%',
    facingMode: 'environment'
};

const Camera = () => {

    const webcamRef = React.useRef(null);
    const [screenShot, setScreenShot ] = React.useState('');
    const [returnRegister, setReturnRegister] = React.useState(false);
    //--
    const [buttonCapture, setButtonCapture] = React.useState('showGrid')
    const [buttonOk, setButtonOk] = React.useState('hideGrid')
    const [buttonCancel, setButtonCancel] = React.useState('hideGrid')
    const [imagenPreview, setImagenPreview] = React.useState('hideGrid')
    const [webCam, setWebCam] = React.useState('showGrid');
    //--progress
    const [showProgress, setShowProgress] = React.useState(false);
    //---message error
    const [show, setShow] = React.useState(false);
    const [messageError, setMessageError] = React.useState(false);
    //---
    const [fechaHora, setFechaHora] = React.useState('');
    //--
    const [deviceId, setDeviceId] = React.useState({});
    const [devices, setDevices] = React.useState([]);
    const [cameraDeviceId, setCameraDeviceId] = React.useState('')
    const [cameraDeviceName, setCameraDeviceName] = React.useState('');
    //---
    const [showUnauthorizedDialog, setShowUnauthorizedDialog] = React.useState(false);
    
    const handleCloseDialog = () =>{
        setShow(false);
    }

    const showDialogError = (msg) =>{
        setMessageError(msg);
        setShow(true);
    }

    const capture = React.useCallback(
        () => {
            const imageSrc = webcamRef.current.getScreenshot();//({width:750 , height: 1334});
            //console.log(imageSrc);
            setScreenShot(imageSrc);
            setFechaHora(moment().format());
            changeButtons();            
        },
        [webcamRef]
    );

    const handleDevices = React.useCallback(
        mediaDevices =>
          setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
        [setDevices]
    );
     
    React.useEffect(
        () => {
          navigator.mediaDevices.enumerateDevices().then(handleDevices);
        },
        [handleDevices]
    );

    const captureOk = () => {
        uploadImage(screenShot);
    }

    const changeButtons = () =>{
        setButtonCapture('hideGrid');
        setButtonOk('showGrid');
        setButtonCancel('showGrid');
        setImagenPreview('showGrid')
        setWebCam('hideGrid')
    }


    const uploadImage = (img64) =>{
        setShowProgress(true)
        var ImageURL = img64
        // Divide la cadena de base64 en información real y tipo de información
        var block = ImageURL.split(";");
        // Obtener tipo de contenido, en este caso "image/gif"
        var contentType = block[0].split(":")[1];// In this case "image/gif"
        // Obtener información real en base64, en este caso "iVBORw0KGg...."
        var realData = block[1].split(",")[1];

        // Convertir a blob
        var blob = b64toBlob(realData, contentType);
        //console.log(blob);

        var nameFile = CreateGuid() + ".png"
        // Crear FormaData y agrega información de archivo        
        //var file = new File([blob], nameFile);
        //console.log(file);

        const formDataToUpload = new FormData();

        //formDataToUpload.append('FileImage', file);
        //formDataToUpload.append('Id', "Id");
        //formDataToUpload.append('FileImage', file);
        formDataToUpload.append('FileImage', blob,nameFile);


        //var file = dataURLtoFile('data:text/plain;base64,aGVsbG8gd29ybGQ=','file.txt');
        /*
        formDataToUpload.append("FileImage", {
                uri: img64,
                type: 'image/jpeg', // or photo.type
                name: 'testPhotoName'
        });*/
        
        const URL_BASE = process.env.REACT_APP_URL_BASE_PORT_UPLOAD;
        const POST_UPLOAD_IMAGE = process.env.REACT_APP_API_POST_UPLOAD_IMAGE;
        const URL = URL_BASE+POST_UPLOAD_IMAGE;
        const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN)
        //console.log(tokenLocalStorage);
        try{
            fetch(URL,{
                method:'post',
                mode: 'cors',
                headers: {
                    //'Accept':'application/json',
                    //'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + tokenLocalStorage,
                    //'Content-Type': undefined,
                },
                body: formDataToUpload

            }).then(function(res){
                if(res.status === 401){
                    return 401;
                }
                return res.json();
            }).then(function(response){               
                //console.log(response);
                setShowProgress(false);

                if (response !== 401){
                    var typePhoto = localStorage.getItem('type-photo-register');
                    var typePhotoDescription = localStorage.getItem('type-photo-description');
                    var jsonList = localStorage.getItem(process.env.REACT_APP_JSON_LIST_IMAGE)
                    var arrImages = JSON.parse(jsonList);
                    arrImages.push({
                        id: CreateGuid(),
                        FechaHoraCliente: fechaHora,
                        PathFoto: response.Data.PathImage,
                        CFechaHoraFoto: response.Data.CFechaHoraFoto,
                        TipoFoto : typePhoto,
                        DescripcionTipoFoto: typePhotoDescription
                    });
                    var jsonLst = JSON.stringify(arrImages);
                    localStorage.setItem(process.env.REACT_APP_JSON_LIST_IMAGE,jsonLst);
                    setReturnRegister(true);

                }else{
                    setShowUnauthorizedDialog(true)
                }               

            }).catch((error) =>{
                showDialogError(error);
            })
        }catch(e){
            showDialogError(e);
        }
    }

    const CreateGuid = ()=> {  
        const _p8 = (s) => {  
           var p = (Math.random().toString(16)+"000000000").substr(2,8);  
           return s ? "-" + p.substr(0,4) + "-" + p.substr(4,4) : p ;  
        }  
        return _p8() + _p8(true) + _p8(true) + _p8();  
    }

    //convertir imagen64 a blob
    const b64toBlob = (b64Data, contentType, sliceSize) => {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        var blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }

    const handleChange = (e) =>{
        //console.log(e.currentTarget)
    }

    const handleReturn = () =>{
        setButtonCapture('showGrid');
        setButtonCancel('hideGrid')
        setButtonOk('hideGrid');
        setImagenPreview('hideGrid');
        setWebCam('showGrid');
    }

    const handleChangeCamera = (e) =>{
        //console.log(e.target.value)
        var objString = e.target.value.toString();
        var splitDevice = objString.split("|");
        var deviceId = splitDevice[0];
        var deviceName = splitDevice[1];
        setCameraDeviceName(deviceName)
        setCameraDeviceId(deviceId)
    }

    if(returnRegister){
        return <Redirect to="/Register"/>
    }

    //console.log(devices);
    //console.log(devices[0]);
    //console.log(devices[1]);
    //console.log(webcamRef);

    return (
        <div>
            <TokenExpired />
            <UnauthorizedDialog open={showUnauthorizedDialog} />
            <ToolbarTakePicture />            
            <Grid container>
                <Grid item xs={12} style={{paddingLeft:20, paddingRight:20, paddingTop:10, display:"none"}}>
                    <Typography>
                        Seleccione cámara:
                    </Typography>
                </Grid>
                <Grid item xs={12} style={{paddingLeft:10, paddingRight:10,display:"none"}} className={"selectCamera"}>
                    <div className="form-group" >                    
                        <select 
                            className="form-control selectNoBorder" 
                            id="selectCamera"
                            onChange={(e) =>handleChangeCamera(e)}
                            value={cameraDeviceId}
                        >
                            <option key='0' value="" style={{textAlign:"center"}} >Seleccione...</option>
                            {                            
                                devices.map(item =>
                                    <option key={item.deviceId} value={item.deviceId+"|"+item.label}>{item.label}</option>
                                    )
                            }
                        </select>
                    </div>
                </Grid>
                <Grid item xs={12} style={{padding:0}} className={webCam, "cameraVideo"}>
                    {
                        cameraDeviceId !== undefined ? (
                            
                                <Webcam
                                    audio={false}
                                    height={'100%'}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                    
                                    //screenshotQuality={2}
                                    width={'100%'}
                                    videoConstraints={{
                                        width:{ ideal: 1280 },
                                        height:{ ideal: 720 },
                                        facingMode: 'environment',
                                        deviceId: cameraDeviceId
                                    }}
                                />
                                
                        ):('')
                    }
                </Grid>
                <Grid item xs={12} style={{paddingTop:0}} className={imagenPreview}>
                    <img src={screenShot} className="cameraImageCapture"/>
                </Grid>
                <Grid item xs={12}>
                    <Grid container className={"containerButton"}>
                        <Grid item className={buttonCapture}>
                            <ButtonTakePicture name={'Capturar'} type={1} handleClick={capture} />
                        </Grid>
                    </Grid>
                    <Grid container className={"containerButton2"}>
                        <Grid item className={buttonCancel}>
                            <ButtonCancelPicture handleClick={handleReturn}/>
                        </Grid>
                        <Grid item className={buttonOk}>
                            <ButtonTakePicture name={'Ok'} type={2} handleClick={captureOk} />
                        </Grid>
                    </Grid>                      
                </Grid>
                <Progress open={showProgress} />
                <AlertDialog open={show} message={messageError} handleClose={handleCloseDialog}/>               
            </Grid>
        </div>       
    )
}

export default Camera
