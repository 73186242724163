import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
//import AccountCircle from '@material-ui/icons/AccountCircle';
//import Switch from '@material-ui/core/Switch';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import FormGroup from '@material-ui/core/FormGroup';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Progress from '../progress/Progress';
import AlertDialog from '../dialogs/AlertDialog';
import { Redirect } from 'react-router-dom';
import UnauthorizedDialog from '../dialogs/UnauthorizedDialog';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';
import {MenuContext} from '../../context/MenuLeftProvider';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import KeyValue from '../../config/KeyValue';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import LogoTeQuiereFeliz from '../../jockeytequierefeliz.svg'
import Names from '../../config/Names';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding:0
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign: "center"
  },
}));

const ToolbarPersonBudgeted = () => {
    //----------------
    const classes = useStyles();
    //const [auth, setAuth] = React.useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const [returnLogin, setReturnLogin] = React.useState(false);
    const [goBudgeted, setGoBudgeted] = React.useState(false);

    const [returnMenuMain, setReturnMenuMain] = React.useState(false);

    const [showUnauthorizedDialog, setShowUnauthorizedDialog] = React.useState(false);
    //--progress
    const [showProgress, setShowProgress] = React.useState(false);
    //---message error
    const [show, setShow] = React.useState(false);
    const [messageError, setMessageError] = React.useState(false);
    //---
    let history = useHistory();
    //-*---
    const handleCloseDialog = () =>{
        setShow(false);
    }
    const showDialogError = (msg) =>{
        setMessageError(msg);
        setShow(true);
    }
    const handleMenu = (event) => { 
        setAnchorEl(event.currentTarget);
    };
    const handleMenu2 = (event) => { 
        setAnchorEl2(event.currentTarget);
    };

    const handleClose = () => {
        //console.log('logout');
        Logout();    
        //setOpen(false);
    };
    const handleClose2 = () => {
        console.log('nueva funcion');
        //Logout();    
        //setOpen(false);
    };

    const cleanCredentials = () =>{
        localStorage.setItem(process.env.REACT_APP_KEY_TOKEN, 'key-token');
        localStorage.setItem(process.env.REACT_APP_KEY_REFRESH_TOKEN, 'key-refresh-token');
        localStorage.setItem(process.env.REACT_APP_NAME_USER_JOCKEY,'usuario');
        localStorage.setItem(process.env.REACT_APP_SESSION_ID,'sesion-id');
    };
    const Logout = () =>{
        var obj = {
        Token: localStorage.getItem(process.env.REACT_APP_KEY_TOKEN),
        RefreshToken: localStorage.getItem(process.env.REACT_APP_KEY_REFRESH_TOKEN),
        SesionId: localStorage.getItem(process.env.REACT_APP_SESSION_ID),
        };
        //console.log(obj);
        const URL_BASE = process.env.REACT_APP_URL_BASE;
        const LOGOUT = process.env.REACT_APP_API_LOGOUT;
        const URL = URL_BASE+LOGOUT;

        setShowProgress(true);
        try{
            fetch(URL,{
                method:'post',
                mode: 'cors',
                headers: {
                    'Accept':'application/json',                                    
                    'ApiKey': process.env.REACT_APP_API_KEY.toString(),
                    'Content-type': 'application/json'
                },
                body: JSON.stringify(obj)

            }).then(function(res){
            if (res.status === 401){
                return 401;
            }
                return res.json();
            }).then(function(response){
                //console.log(response);
                setShowProgress(false);
                
                if(response !== 401){
                if(response.Code === 0){

                    if(response.Data){
                    cleanCredentials();
                    setReturnLogin(true);
                    }else{                  
                    showDialogError('No se pudo cerrar sesión.')                  
                    }
    
                }else if(response.Code === 2){
                    //console.log(response.Data.Errors[0].FieldName)
                    showDialogError(response.Data.Errors[0].Message)
                }else if(response.Code === 3){
                    showDialogError(response.Message);
                    setAnchorEl(null);
                }

                }else{
                setShowUnauthorizedDialog(true);
                }
            }).catch((error) =>{
            showDialogError(error);
            })
        }catch(e){
        showDialogError(e);
        }
    };

    const handleCloseMenu = () =>{
        setAnchorEl(null);
    }
    const handleClickBackMenu = () =>{
        //setReturnMenuMain(true)
        history.push("/Main");
    }


    if(returnLogin){
        localStorage.setItem('tabCurrent', 0);
        return <Redirect to="/"/>
    } 
    /*
    if(returnMenuMain) {
        return <Redirect to="/Main" />
    }*/

    return (
        <div className={classes.root}>      
            <AppBar position="static">
                <Toolbar>
                    <IconButton                
                        style={{outline:'none'}}
                        onClick={handleClickBackMenu}>
                        < ArrowBackIcon style={{color:'white'}} />
                    </IconButton>
                
                <Typography variant="h6" className={classes.title}>
                    {
                    Names.NAME_TRAY_REGISTER
                    }
                </Typography >
                {true && (
                    <div>
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                        style={{outline:'none'}}
                    >
                        <PowerSettingsNewIcon />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleCloseMenu}
                    >
                        <MenuItem onClick={handleClose}>Cerrar Sesión</MenuItem>
                    </Menu>
                    </div>
                )}
                </Toolbar>
            </AppBar>
            <AlertDialog open={show} message={messageError} handleClose={handleCloseDialog}/>
            <Progress open={showProgress} />
            <UnauthorizedDialog open={showUnauthorizedDialog} />
        </div>
    )
}

export default ToolbarPersonBudgeted
