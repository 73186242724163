import React from 'react'

import BottomNavigationCustom from './BottomNavigationCustom';
import MenuLeftProvider from '../../context/MenuLeftProvider';

const Main = () => {
    const tabLocal = localStorage.getItem('tabCurrent');
    //console.log(tabLocal);
    var name = '';  
    if(parseInt(tabLocal) === 0){
        name = 'Bandeja';
    }else{
        name = 'Historial';
    }

    const [value, setValue] = React.useState(parseInt(tabLocal));
    const [nameTab, setNameTab] =React.useState(name);

    const handleChange = (event, newValue) => {
        //
        if(value === 0){
          setNameTab('Historial');
        }else {
          setNameTab('Bandeja');
        }
        setValue(newValue);
    };

    return (
        
            <BottomNavigationCustom value={value} handleChange={handleChange} nameTab={nameTab}/>      
    )
}

export default Main
