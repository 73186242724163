import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import { Redirect } from 'react-router-dom';
import { capitalize } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  widthCs:{
    width:150,
    backgroundColor:'#009DE4',
    paddingLeft:20,
    paddingRight:20
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  }/*,
  root:{
    display:'none'
  }*/
}));

export default function ButtonNewRegister(props) {
    /*const tokenLocalStorage = localStorage.getItem(
      process.env.REACT_APP_KEY_TOKEN
    );*/
    const classes = useStyles();

    const [goRegister, setGoRegister] = React.useState(false);
    //const [isVisor, setIsVisor] = React.useState(false);

    const handleNewRegister = () =>{
        setGoRegister(true);
    }

    const cleanLocalStorage = () =>{
      localStorage.setItem('edit',0);
      localStorage.setItem('editView',0);
      localStorage.setItem(process.env.REACT_APP_JSON_LIST_IMAGE,'list-images')
      localStorage.setItem('areaId','');
      localStorage.setItem('companyId','');
      localStorage.setItem('turnId','');
      localStorage.setItem('asignados','');
      localStorage.setItem('presentes','');
      localStorage.setItem('faltos','');
      localStorage.setItem('tardanzas','');
      localStorage.setItem('novedades','');
  }
  /*
  React.useEffect(() => {
    GetIsVisor();
  }, []);
  const GetIsVisor = () => {
    
    const URL_BASE = process.env.REACT_APP_URL_BASE_PORT;
    const GET_IS_VISOR = process.env.REACT_APP_API_GET_VALIDATE_IS_VISOR;
    const URL = URL_BASE + GET_IS_VISOR;
    try {
      fetch(URL, {
        method: "get",
        mode: "cors",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + tokenLocalStorage,
          "Content-type": "application/json",
        },
      })
        .then(function (res) {
          if (res.status === 401) {
            return 401;
          }
          return res.json();
        })
        .then(function (response) {
          setShowProgress(false);
          if (response !== 401) {
            if (response.Code === 0) {
              if (response.Data) {
                setIsVisor(true);
              }
            } else if (response.Code === 2) {
              showDialogError(response.Data.Errors[0].Message);
            } else if (response.Code === 1) {
              showDialogError(response.Data.Message);
            }
          }
        })
        .catch((error) => {
          showDialogError(error);
        });
    } catch (e) {
      showDialogError(e);
    }
  };
*/
    if (goRegister){
      cleanLocalStorage();
      localStorage.setItem('tabHistorialEdit',0)
      return <Redirect to = "/Register"/>
    }

    
    return (
      <Fab
        id="btnNewRegister"
        variant="extend"
        size="medium"
        color="primary"
        className={classes.margin,classes.widthCs}
        onClick={handleNewRegister}
        style={{textTransform:'capitalize'}}
      >
        Nuevo
      </Fab>
    );
}

