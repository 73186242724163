import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';

import { Grid, Typography } from '@material-ui/core';
import Progress from '../progress/Progress';
import AlertDialog from '../dialogs/AlertDialog';
import ButtonFilter from '../button/ButtonFilter';

import './Table.css';
import { Redirect } from 'react-router-dom';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#009DE4',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs };
}

const rows = [
  createData('Mañana', 6, 24, 18),
  createData('Tarde', 9, 37, 28),
  createData('Noche', 16, 24, 8),
];

const useStyles = makeStyles({
  table: {
    minWidth: '100%',
  },
  tableContainer:{
    borderRadius: 10,
    backgroundColor: "#efefef",
    padding: 10,
    display: "block !important"
  }
});

export default function TableCompany(props) {
  const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN)        

  const classes = useStyles();
  //---
  const [openDetail, setOpenDetail] = React.useState(false);
  const [codeTercero, setCodeTercero] = React.useState('');
  //--progress
  const [showProgress, setShowProgress] = React.useState(false);
  //---message error
  const [show, setShow] = React.useState(false);
  const [messageError, setMessageError] = React.useState(false);
  //---
  const [listRegister, setListRegister] = React.useState([])
  const handleCloseDialog = () =>{
    setShow(false);
  }

  const showDialogError = (msg) =>{
      setMessageError(msg);
      setShow(true);
  }
  
  React.useEffect(() =>{
    var obj = {
      FechaRegistro: props.FechaRegistro.toString(),
      AreaId:props.AreaId,
      EmpresaId:props.EmpresaId
    }
    //console.log(obj);
    GetListHistorial(obj);
  },[])


  const GetListHistorial = (obj) =>{
    setShowProgress(true);

    const URL_BASE = process.env.REACT_APP_URL_BASE_PORT;
    const POST_FILTER_REGISTER = process.env.REACT_APP_API_POST_FILTER_REGISTER;
    const URL = URL_BASE+POST_FILTER_REGISTER;    
    
    try{
        fetch(URL,{
            method:'post',
            mode: 'cors',
            headers: {
                'Accept':'application/json',                                    
                'Authorization': 'Bearer ' + tokenLocalStorage,
                'Content-type': 'application/json'
            },
            body:JSON.stringify(obj)

        }).then(function(res){
            return res.json();
        }).then(function(response){
            //console.log(response);
            setShowProgress(false);

            if(response.Code === 0){
              const filtrado = FilterArrayRegister(response.Data.UsuarioBandeja)
              setListRegister(filtrado)

            }else if(response.Code === 2){
                //console.log(response.Data.Errors[0].FieldName)
                showDialogError(response.Data.Errors[0].Message)
            }

        }).catch((error) =>{
          showDialogError(error);
        })
    }catch(e){
      showDialogError(e);
    }
    
  }

  const FilterArrayRegister = (listFromService) =>{

    const arrFilter = listFromService.filter((item) => item.UsuarioAreaBandeja.length > 0)
                
    const firstArray = []

    for(var i = 0;i < arrFilter.length; i++){
      
      const array1 = [];
      var found = false;
      for(var j = 0; j < arrFilter[i].UsuarioAreaBandeja.length ; j++){

        if(arrFilter[i].UsuarioAreaBandeja[j].EmpresaRegistro.length > 0){
          
          found = true
          array1.push(arrFilter[i].UsuarioAreaBandeja[j])
        }
      }
      if(found){
        firstArray.push({                      
          AreaId: arrFilter[i].AreaId,
          AreaNombre: arrFilter[i].AreaNombre,
          UsuarioAreaBandeja: array1,
        });
      }
    }
    return firstArray;
  }

  const handleSeeDetail = (code) =>{
    var stringCode = JSON.stringify(code)
    localStorage.setItem('code',stringCode);
    setOpenDetail(true);
  }


  const handleFilter = () =>{
    var obj = {
      FechaRegistro:props.FechaRegistro.toString(),
      AreaId:props.AreaId,
      EmpresaId:props.EmpresaId
    }
    //console.log(obj)
    GetListHistorial(obj);
  }

  if(openDetail){
    localStorage.setItem('tabCurrent',1)
    localStorage.setItem('tabHistorialEdit',1)    
    return <Redirect to="/RegisterDetail" />
  }

  /**
   * return (
    <Grid container >
      
      <Grid item xs={12} style={{marginTop:10}}>
          <ButtonFilter handleClick={handleFilter} />
      </Grid>
      <Grid item xs={12} style={{marginTop:10}}>
        <Grid container style={{overflow: 'auto'}} className={classes.tableContainer + " heightCustom2"} >
          {
            listRegister.length > 0 ? (
              listRegister.map((item) =>

              <Grid item xs={12}>
                <Grid container style={{marginTop:10}}>
                  <Grid item xs={12} style={{textAlign:'left'}}>
                    <Typography variant="body1" color="inherit" className={'boldText'}>
                      {item.AreaNombre}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {
                      item.UsuarioAreaBandeja.map((el)=>
                      <Grid container style={{marginTop:2,marginBottom:2}}>
                        <Grid item xs={12} style={{textAlign:'left'}}>
                          <Typography variant="body1" color="inherit">
                            {el.EmpresaNombre}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="customized table">
                              <TableHead>
                                <TableRow>            
                                  <StyledTableCell align="left">Turno</StyledTableCell>
                                  <StyledTableCell align="left">Asign.</StyledTableCell>
                                  <StyledTableCell className="paddingPresent" align="left">Presentes</StyledTableCell>
                                  <StyledTableCell align="left">Faltos</StyledTableCell>
                                  <StyledTableCell align="left">Ver</StyledTableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {el.EmpresaRegistro.map((row) => (
                                  <StyledTableRow key={row.name}>              
                                    <StyledTableCell align="left">{row.Turno}</StyledTableCell>
                                    <StyledTableCell align="left">{row.NroAsignados}</StyledTableCell>
                                    <StyledTableCell align="left">{row.NroPresentes}</StyledTableCell>
                                    <StyledTableCell align="left">{row.NroFaltas}</StyledTableCell>
                                    <StyledTableCell align="left">
                                      <div onClick={()=>{handleSeeDetail(row.CodeTercero)}}>
                                        <RemoveRedEyeIcon style={{color:'#009DE4'}}/> 
                                      </div> 
                                    </StyledTableCell>
                                  </StyledTableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      </Grid>
                      )
                    }
                  </Grid>
                </Grid>
              </Grid>
              )
            ):(
              <Grid item xs={12} style={{textAlign:'left'}}>
                <Typography variant="body1" color="inherit">
                  Sin Registros
                </Typography>
              </Grid>
            )
          }    
          <AlertDialog open={show} message={messageError} handleClose={handleCloseDialog}/>
          <Progress open={showProgress} />
        </Grid>         
      </Grid>
    </Grid>
      
    );
   */

  return (
    <Grid container >
      
      <Grid item xs={12} style={{marginTop:10}}>
          <ButtonFilter handleClick={handleFilter} />
      </Grid>
      <Grid item xs={12} style={{marginTop:10}}>
        <Grid container /*style={{overflow: 'auto'}} */ className={classes.tableContainer /*+ " heightCustom2" */ } >
          {
            listRegister.length > 0 ? (
              listRegister.map((item) =>

              <Grid item xs={12}>
                <Grid container style={{marginTop:10}}>
                  <Grid item xs={12} style={{textAlign:'left'}}>
                    <Typography variant="body1" color="inherit" className={'boldText'}>
                      {item.AreaNombre}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {
                      item.UsuarioAreaBandeja.map((el)=>
                      <Grid container style={{marginTop:2,marginBottom:2}}>
                        <Grid item xs={12} style={{textAlign:'left'}}>
                          <Typography variant="body1" color="inherit">
                            {el.EmpresaNombre}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} className={"divRounded"}>
                            <table className={"roundTable2 " + classes.table} aria-label="customized table">
                              
                                <tr className="headerTitle">            
                                  <th align="left" style={{paddingLeft:8}}>Turno</th>
                                  <th align="left">Asign.</th>
                                  <th align="left">Pres.</th>
                                  <th className="paddingPresent" align="left">A Tiem.</th>
                                  <th align="left">Tard.</th>
                                  <th align="left">Falt.</th>
                                  <th align="left">Ver</th>   
                                </tr>
                                {el.EmpresaRegistro.map((row) => (
                                  <tr className="bodyRows">
                                    <td align="left" style={{paddingLeft:8,backgroundColor:'#FAFAFA'}}>{row.Turno}</td>
                                    <td align="left" style={{paddingLeft:8,backgroundColor:'#FAFAFA'}}>{row.NroAsignados}</td>
                                    <td align="left" style={{paddingLeft:8,backgroundColor:'#FAFAFA'}}>{row.NroCalPresen}</td>
                                    <td align="left" style={{paddingLeft:8,backgroundColor:'#FAFAFA'}}>{row.NroPresentes}</td>
                                    <td align="left" style={{paddingLeft:8,backgroundColor:'#FAFAFA'}}>{row.NroTardanzas}</td>
                                    <td align="left" style={{paddingLeft:8,backgroundColor:'#FAFAFA'}}>{row.NroFaltas}</td>
                                    <td align="left" style={{paddingLeft:8,backgroundColor:'#FAFAFA'}}>
                                      {row.Autorizado ? (
                                        <div onClick={()=>{handleSeeDetail(row.CodeTercero)}}>
                                          <RemoveRedEyeIcon style={{color:'#a5a5a5'}}/> 
                                        </div>
                                      ) : (
                                        <div onClick={()=>{handleSeeDetail(row.CodeTercero)}}>
                                          <RemoveRedEyeIcon style={{color:'#009DE4'}}/> 
                                        </div>
                                      )}
                                    </td>
                                  </tr>
                                ))}                              
                            </table>
                        </Grid>
                      </Grid>
                      )
                    }
                  </Grid>
                </Grid>
              </Grid>
              )
            ):(
              <Grid item xs={12} style={{textAlign:'left'}}>
                <Typography variant="body1" color="inherit">
                  Sin Registros
                </Typography>
              </Grid>
            )
          }    
          <AlertDialog open={show} message={messageError} handleClose={handleCloseDialog}/>
          <Progress open={showProgress} />
        </Grid>         
      </Grid>
      <Grid item xs={6} style={{textAlign:'left', marginTop:10}}>
        <RemoveRedEyeIcon style={{color:'#009DE4'}}/> Editables <br></br>
        <RemoveRedEyeIcon style={{color:'#a5a5a5'}}/> No Editables
      </Grid>
    </Grid>
      
    );
}
