import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import './Button.css'

const useStyles = makeStyles((theme) => ({    
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  padd:{
    paddingLeft:2,
    paddingRight:2,
    paddingBottom:15
  },
}));

export default function ButtonSaveRegister(props) {
  const classes = useStyles();

  return (
    <div className={classes.root,classes.padd} >
        <Fab
          //id="btnSaveAmount"
          variant="extended"
          style={{width:'100%'}}
          className="colorBackground letterCapitalize"
          onClick={props.handleClick}
        >
            {props.name}
        </Fab>
    </div>
  );
}
