import React from 'react';
import moment from 'moment';
export const MenuContext = React.createContext();

const MenuLeftProvider = (props) => {

    const menuChange = {
        hide: false,
    }

    const [menuLeft, setMenuLeft] = React.useState(menuChange);

    const handleMenuLeft = (valor) => {
        setMenuLeft({...menuChange, hide: valor });
    }

    const getDateLocal = () => {
        var fechaActual = moment().format("YYYY-MM-DD[T]HH:mm:ss");
        var fechJson = JSON.stringify(fechaActual);
        var fechaString = JSON.parse(fechJson)
        return fechaString;
    }
    const getDateLocalFirstDayOfMonth = () => {
        //var fechaActual = moment().format("YYYY-MM-DD[T]HH:mm:ss");
        var fechaActual = moment().clone().startOf('month').format("YYYY-MM-DD[T]HH:mm:ss");
        var fechJson = JSON.stringify(fechaActual);
        var fechaString = JSON.parse(fechJson)
        return fechaString;
    }

    const getDateLocalDate = () => {
        return moment().clone();
    }
    const getDateLocalFirstDayOfMonthDate = () => {
        console.log(moment().clone().startOf('month'));
        return moment().clone().startOf('month');
    }


    const getDateFormatYYYYMMDD = () => {
        //---fecha filtro por defecto---------
        var month = moment().format('M'); // date es un objeto moment
        var intMonth = parseInt(month);
        if (intMonth < 10) {
            month = "0" + month;
        }
        var day = moment().format('D');
        var dayInt = parseInt(day);
        if (dayInt < 10) {
            day = "0" + day;
        }
        var year = moment().format('YYYY');
        var dateFilter = year + "-" + month + "-" + day;
        return dateFilter;
    }

    const getDateFormatYYYYMMDD_FirstDayOfMonth = () => {
        //---fecha filtro por defecto---------
        var month = moment().clone().startOf('month').format('M'); // date es un objeto moment
        var intMonth = parseInt(month);
        if (intMonth < 10) {
            month = "0" + month;
        }
        var day = moment().clone().startOf('month').format('D');
        var dayInt = parseInt(day);
        if (dayInt < 10) {
            day = "0" + day;
        }
        var year = moment().clone().startOf('month').format('YYYY');
        var dateFilter = year + "-" + month + "-" + day;
        return dateFilter;
    }

    return ( 
        <MenuContext.Provider value = {{ menuLeft, handleMenuLeft, getDateLocal,getDateLocalFirstDayOfMonth, getDateLocalDate, getDateLocalFirstDayOfMonthDate, getDateFormatYYYYMMDD,getDateFormatYYYYMMDD_FirstDayOfMonth } } > 
            { props.children } 
        </MenuContext.Provider>
    )
}

export default MenuLeftProvider