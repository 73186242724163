import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import ButtonCloseNewPersonBudgeted from '../button/ButtonCloseNewRegisterBudgeted';
import { Grid } from '@material-ui/core';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SuccessDialog(props) { 

  return (    
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        //onClose={props.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title" style={{textAlign:"center"}}>
            {"¡Enhorabuena!"}
        </DialogTitle>
        <DialogContent>
          <Grid container >
            <Grid item xs={12}style={{textAlign:'center'}}>
                {props.message}
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={6} style={{textAlign:'center', marginTop:10}}>
              <ButtonCloseNewPersonBudgeted onClick={props.handleClose} />
            </Grid >
            <Grid item xs={3}></Grid>
          </Grid>
        </DialogContent>
      </Dialog>
  );
}
