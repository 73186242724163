import React from 'react'
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';

const ButtonDeleteItem = (props) => {
    return (
        <IndeterminateCheckBoxIcon 
            onClick={props.handleDelete}
            style={{color:'red'}}
        />
    )
}

export default ButtonDeleteItem
