import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
//import AccountCircle from '@material-ui/icons/AccountCircle';
//import Switch from '@material-ui/core/Switch';
//import FormControlLabel from '@material-ui/core/FormControlLabel';
//import FormGroup from '@material-ui/core/FormGroup';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Progress from '../progress/Progress';
import AlertDialog from '../dialogs/AlertDialog';
import { Redirect } from 'react-router-dom';
import UnauthorizedDialog from '../dialogs/UnauthorizedDialog';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ListItemText from '@material-ui/core/ListItemText';
import {MenuContext} from '../../context/MenuLeftProvider';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import KeyValue from '../../config/KeyValue';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import GroupIcon from '@material-ui/icons/Group';
import LogoTeQuiereFeliz from '../../jockeytequierefeliz.svg'
import Names from '../../config/Names';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding:0
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign: "center"
  },
}));

const useStyles_drawer = makeStyles({
  list: {
    width: 290,
    height:'100%',
    backgroundColor:'#009DE4 !important'
  },
  fullList: {
    width: 'auto',
  },
});

export default function ToolbarMain(props) {
  const classes_drawer = useStyles_drawer();
  let history = useHistory();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  //----------------
  const classes = useStyles();
  //const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [returnLogin, setReturnLogin] = React.useState(false);
  const [goBudgeted, setGoBudgeted] = React.useState(false);

  const {menuLeft,handleMenuLeft} = React.useContext(MenuContext);

  const [showUnauthorizedDialog, setShowUnauthorizedDialog] = React.useState(false);
  //--progress
  const [showProgress, setShowProgress] = React.useState(false);
  //---message error
  const [show, setShow] = React.useState(false);
  const [messageError, setMessageError] = React.useState(false);
  //---
  const handleCloseDialog = () =>{
    setShow(false);
  }
  const showDialogError = (msg) =>{
      setMessageError(msg);
      setShow(true);
  }
  
/*
  const handleChange = (event) => {
    setAuth(event.target.checked);
  };
*/
  const handleMenu = (event) => { 
    setAnchorEl(event.currentTarget);
  };
  const handleMenu2 = (event) => { 
    setAnchorEl2(event.currentTarget);
  };

  const handleClose = () => {
    //console.log('logout');
    Logout();    
    //setOpen(false);
  };
  const handleClose2 = () => {
    console.log('nueva funcion');
    //Logout();    
    //setOpen(false);
  };

  const cleanCredentials = () =>{
    localStorage.setItem(process.env.REACT_APP_KEY_TOKEN, 'key-token');
    localStorage.setItem(process.env.REACT_APP_KEY_REFRESH_TOKEN, 'key-refresh-token');
    localStorage.setItem(process.env.REACT_APP_NAME_USER_JOCKEY,'usuario');
    localStorage.setItem(process.env.REACT_APP_SESSION_ID,'sesion-id');
  }

  const Logout = () =>{
    var obj = {
      Token: localStorage.getItem(process.env.REACT_APP_KEY_TOKEN),
      RefreshToken: localStorage.getItem(process.env.REACT_APP_KEY_REFRESH_TOKEN),
      SesionId: localStorage.getItem(process.env.REACT_APP_SESSION_ID),
    };
    //console.log(obj);
    const URL_BASE = process.env.REACT_APP_URL_BASE;
    const LOGOUT = process.env.REACT_APP_API_LOGOUT;
    const URL = URL_BASE+LOGOUT;

    setShowProgress(true);
    try{
        fetch(URL,{
            method:'post',
            mode: 'cors',
            headers: {
                'Accept':'application/json',                                    
                'ApiKey': process.env.REACT_APP_API_KEY.toString(),
                'Content-type': 'application/json'
            },
            body: JSON.stringify(obj)

        }).then(function(res){
          if (res.status === 401){
            return 401;
          }
            return res.json();
        }).then(function(response){
            //console.log(response);
            setShowProgress(false);
            
            if(response !== 401){
              if(response.Code === 0){

                if(response.Data){
                  cleanCredentials();
                  setReturnLogin(true);
                }else{                  
                  showDialogError('No se pudo cerrar sesión.')                  
                }
  
              }else if(response.Code === 2){
                //console.log(response.Data.Errors[0].FieldName)
                showDialogError(response.Data.Errors[0].Message)
              }else if(response.Code === 3){
                showDialogError(response.Message);
                setAnchorEl(null);
              }

            }else{
              setShowUnauthorizedDialog(true);
            }
        }).catch((error) =>{
          showDialogError(error);
        })
    }catch(e){
      showDialogError(e);
    }
  }

  const handleCloseMenu = () =>{
    setAnchorEl(null);
  }
  const handleCloseMenuLeft = () =>{
    setAnchorEl2(null);
  }

  const handleClickMenuLeft = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleOpenViewPersonBudgeted = () =>{
    //setGoBudgeted(true);
    //handleMenuLeft(true);
    //handleCloseMenuLeft();
    history.push("/TrayBudgeted");
  }
  const handleClickBackMenu = () =>{
    handleMenuLeft(false);
  }
  const list = (anchor) => (
      <div
        className={clsx(classes_drawer.list, {
          [classes_drawer.fullList]: anchor === 'top' || anchor === 'bottom',
        })}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
        style={{backgroundColor:'#009DE4 !important'}}
      >
        <div style={{width:'100%'}}>
          <div style={{textAlign:'center'}}>
            <img 
              src={LogoTeQuiereFeliz} 
              className="widthLogoStoDomingo2"
              alt="drawer"
              //onClick={handleDrawerClose}
              style={{cursor:'pointer',marginRight:'auto',marginLeft:'auto',marginTop:20,marginBottom:20}}
            />
          </div>            
        </div>
        <Divider /> 
        <List>         
            <ListItem button key={'Registro_de_Presupuesto'} onClick={handleOpenViewPersonBudgeted}>
              <ListItemIcon>
                <GroupIcon style={{color:'white'}}/>
              </ListItemIcon>
              <ListItemText primary={Names.NAME_SIDERBAR_MENU_PERSONAL_BUDGETED} style={{color:'white'}} />
            </ListItem>          
        </List>
        <Divider />        
      </div>
  );

  if(returnLogin){
    localStorage.setItem('tabCurrent', 0);
    return <Redirect to="/"/>
  } 
  /*
  if(goBudgeted) {
    return <Redirect to="/Presupuesto" />
  }
*/
  return (
    <div className={classes.root}>      
      <AppBar position="static">
        <Toolbar>
          
          {['left'/*, 'right', 'top', 'bottom'*/].map((anchor) => (
            <React.Fragment key={anchor}>
              <IconButton 
                className={localStorage.getItem(KeyValue.KEY_ACCESS_SERVICE_RESPONSE).toString() === KeyValue.KEY_APP_ACCESS_BUDGET_AUTHORIZED.toString() ? 'showDiv' : 'hideDiv' }        
                style={{outline:'none'}}
                onClick={menuLeft.hide ? handleClickBackMenu : toggleDrawer(anchor, true)}>
                {
                  menuLeft.hide ? (
                    < ArrowBackIcon style={{color:'white'}} />
                  ):(< MenuIcon style={{color:'white'}} />)
                }
                
              </IconButton>
              <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
                {list(anchor)}
              </Drawer>
            </React.Fragment>
            ))
          }
          {/*
            <Button
              aria-controls="customized-menu"
              aria-haspopup="true"
              onClick={toggleDrawer(anchor, true)}
              className={localStorage.getItem(KeyValue.KEY_ACCESS_SERVICE_RESPONSE).toString() === KeyValue.KEY_APP_ACCESS_BUDGET_AUTHORIZED.toString() ? 'showDiv' : 'hideDiv' }        
            >
                           
            </Button>
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorEl2}
              keepMounted
              open={Boolean(anchorEl2)}
              onClose={handleCloseMenuLeft}
            >
              <StyledMenuItem onClick={handleOpenViewPersonBudgeted}>
                <ListItemText primary="Personas Presupuestadas" />
              </StyledMenuItem>
            </StyledMenu>*/
            }
          
          <Typography variant="h6" className={classes.title}>
            {
              menuLeft.hide ? Names.NAME_TRAY_REGISTER : props.name
            }
          </Typography >
          {true && (
            <div>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                style={{outline:'none'}}
              >
                <PowerSettingsNewIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
              >
                <MenuItem onClick={handleClose}>Cerrar Sesión</MenuItem>
              </Menu>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <AlertDialog open={show} message={messageError} handleClose={handleCloseDialog}/>
      <Progress open={showProgress} />
      <UnauthorizedDialog open={showUnauthorizedDialog} />
    </div>
  );
}
