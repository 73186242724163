import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import { Redirect } from 'react-router-dom';
import RegisterData from '../newRegister/RegisterData';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function ToolbarNewRegister(props) {
  const classes = useStyles();  

  const [returnMain, setReturnMain] = React.useState(false);
  const [code, setCode] = React.useState('');
  const [edit, setEdit] = React.useState(0);
  const [name, setName] = React.useState('Nuevo Registro');

  React.useEffect(() =>{
    
    var edit = localStorage.getItem('editView');
    if(parseInt(edit) === 1){
      setName('Editar Registro');
    }

  },[]);

  const handleReturn = () => {
    setReturnMain(true);
  };

  const cleanLocalStorage = () =>{
    localStorage.setItem('edit',0);
    localStorage.setItem('code','');
    localStorage.setItem(process.env.REACT_APP_JSON_LIST_IMAGE,'list-images')
    localStorage.setItem('areaId','');
    localStorage.setItem('companyId','');
    localStorage.setItem('turnId','');
    localStorage.setItem('asignados','');
    localStorage.setItem('presentes','');
    localStorage.setItem('tardanzas','');
    localStorage.setItem('faltos','');
    localStorage.setItem('calpresen','');
    localStorage.setItem('novedades','');
  }

  if(returnMain){
    cleanLocalStorage();
    localStorage.setItem('tabCurrent',0)
    return <Redirect to="/Main" />
  }

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
            <IconButton 
                edge="start" 
                className={classes.menuButton} 
                color="inherit"
                aria-label="menu"
                onClick={handleReturn}
            >
                <KeyboardBackspaceIcon />
            </IconButton>
            <Typography 
                variant="h6" 
                className={classes.title} 
                style={{textAlign:'Center'}}
            >
                {name}
            </Typography>          
        </Toolbar>
      </AppBar>
      <RegisterData />
    </div>  
      
  );
}
