import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
    Grid,
    TextField,
    TextareaAutosize,
    Card,
    CardContent,
    Typography,
    IconButton,
    makeStyles,
    red,
    Collapse
  } from "@material-ui/core";
import ButtonCloseNewPersonBudgeted from '../button/ButtonCloseNewRegisterBudgeted';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles_accordion = makeStyles((theme) => ({
    root: {
      maxWidth: 345,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    }
  }));

export default function AuditDialog(props) { 
    const classes_accordion = useStyles_accordion();
    const [expanded, setExpanded] = React.useState(true);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

  return (    
        <Dialog
            open={props.open}
            TransitionComponent={Transition}
            keepMounted
            //onClose={props.handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <DialogTitle id="alert-dialog-slide-title" className="hideDiv" style={{textAlign:"center"}}>
                {"¡Alerta!"}
            </DialogTitle>
            <DialogContent >
                <Grid container>
                    <Grid item xs={12} style={{ textAlign: "right"}}>
                        <Typography style={{display: "inline-block"}}>
                            Auditorías
                        </Typography>
                        <IconButton
                        className={clsx(classes_accordion.expand, {
                            [classes_accordion.expandOpen]: expanded,
                        })}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <CardContent>
                                <Grid item xs={12} className={"divRounded"} style={{overflow:'auto'}} >
                                    <table className="roundTable2" aria-label="customized table">
                                        <thead >
                                            <tr className="headerTitle" >                                        
                                                <th style={{textAlign:'left', paddingLeft:10}}>Fecha</th>
                                                <th style={{textAlign:'left', paddingLeft:10}}>Hora</th>
                                                <th style={{textAlign:'left', paddingLeft:10}}>Usuario</th>
                                                <th style={{textAlign:'left', paddingLeft:10}}>Cantidad</th>
                                            </tr>
                                        </thead>
                                        <tbody style={{marginLeft:10}}>
                                            {
                                                props.list !== undefined ? (
                                                    props.list !== null ? (
                                                    props.list.map(item => 
                                                        <tr>
                                                        <td style={{textAlign:'left', paddingLeft:10}}>{item.Fecha}</td>
                                                        <td style={{textAlign:'left', paddingLeft:10}}>{item.Hora}</td>
                                                        <td style={{textAlign:'left', paddingLeft:10}}>{item.Usuario}</td>
                                                        <td style={{textAlign:'left', paddingLeft:10}}>{item.Cantidad}</td>
                                                    </tr>)
                                                    ):("")
                                                ):("")
                                            }
                                        </tbody>
                                    </table>
                                </Grid>
                            </CardContent>
                        </Collapse>
                    </Grid>
                    <Grid item xs={3}></Grid>
                    <Grid item xs={6} style={{ textAlign: "center" }} >
                        <ButtonCloseNewPersonBudgeted name={"Cerrar"} onClick={props.handleClose} />
                    </Grid>
                    <Grid item xs={3}></Grid>
                </Grid>
            </DialogContent>
        </Dialog>
  );
}
