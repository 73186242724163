import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Grid, Button } from '@material-ui/core';
import './Login.css'
import { Redirect } from 'react-router-dom';
import Progress from '../progress/Progress';
//import SnackBarCustom from '../snackbarCustom/SnackBarCustom';
//import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import AlertDialog from '../dialogs/AlertDialog';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import {faEye,faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import EyeIcon from '@material-ui/icons/Visibility';
import EyeSlashIcon from '@material-ui/icons/VisibilityOff';
import KeyValue from '../../config/KeyValue';
import VersionApp from '../version/VersionApp';


  const useStyles = makeStyles((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    margin: {
      margin: theme.spacing(1),
    },
  }));

  const TextFieldLogin = withStyles({
    root: {
      '& label': {
        color: 'white',
      },
      '& label.Mui-focused': {
        color: 'white',
      },
      '& .MuiInput-underline': {
        borderBottomColor: 'silver',
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: 'white',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'white',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'red',
        },
        '&:hover fieldset': {
          borderColor: 'yellow',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'white',
        }
      },
    },
  })(TextField);

export default function MultilineTextFields() {
    const classes = useStyles();
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [goMain, setGoMain] = React.useState(false);

    const inputPasswordRef = React.useRef();
    //--progress
    const [showProgress, setShowProgress] = React.useState(false);
    //---message error
    const [show, setShow] = React.useState(false);
    const [messageError, setMessageError] = React.useState(false); 
    //---
    const [isRevealPassword, setIsRevealPassword] = React.useState(false)

    const handleCloseDialog = () =>{
        setShow(false);
    }

    const showDialogError = (msg) =>{
        setMessageError(msg);
        setShow(true);
    }

    const handleChangeUsername = (event) => {
        //.toUpperCase()
        var user = event.target.value;
        if(user !== ""){
            setUsername(user.toString().toUpperCase());
        }else{
            setUsername("");
        }
        
    };
    const handleChangePassword = (event) => {
        setPassword(event.target.value);
    };

    const validateFields = () =>{        
        if(username !== ""){
            if(password !== ""){
                PostLogin();
            }else{
                //showErrorMessage("Ingrese su contraseña.");
                showDialogError("Ingrese su contraseña.");
            }
        }else{
            //showErrorMessage("Ingrese su nombre de usuario.")
            showDialogError("Ingrese su nombre de usuario.");
        }      
    }

    const PostLogin = () =>{
        setShowProgress(true);
        var obj = {
            UserLogin: username,
            Password: password,
            TypeUser: process.env.REACT_APP_TYPE_USER.toString()
        }
        //console.log(obj);
        const URL_BASE = process.env.REACT_APP_URL_BASE;
        const POST_LOGIN = process.env.REACT_APP_API_POST_LOGIN;
        const URL = URL_BASE+POST_LOGIN;
        //const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN)        
        
        try{
            fetch(URL,{
                method:'post',
                mode: 'cors',
                headers: {
                    'Accept':'application/json',
                    'ApiKey': process.env.REACT_APP_API_KEY.toString(),
                    //'Authorization': 'Bearer ' + tokenLocalStorage,
                    'Content-type': 'application/json'
                },
                body:JSON.stringify(obj)

            }).then(function(res){
                return res.json();
            }).then(function(response){
                //console.log(response);
                setShowProgress(false);
                if(response.Code === 0){

                    if(response.Data.Result.RESULT_VAL === 1){
                        setDataLocal(response);
                        setGoMain(true);
                    }else{
                        showDialogError(response.Data.AuthenticationServiceError)
                    }
                    
                }else if(response.Code === 2){
                    showDialogError(response.Data.Errors[0].Message)

                }else if(response.Code === 1){
                    showDialogError(response.Data.Message)
                }

            }).catch((error) =>{
                showDialogError(error);
            })
        }catch(e){
            showDialogError(e);
        }
    }

    const setDataLocal = (response) =>{
        localStorage.setItem(process.env.REACT_APP_KEY_TOKEN, response.Data.AuthenticationResult.Token);
        localStorage.setItem(process.env.REACT_APP_KEY_REFRESH_TOKEN, response.Data.AuthenticationResult.RefreshToken);
        localStorage.setItem(process.env.REACT_APP_NAME_USER_JOCKEY, response.Data.Result.DATOS_USUARIO[0].NOMBRE);
        localStorage.setItem(process.env.REACT_APP_SESSION_ID,response.Data.Result.DATOS_USUARIO[0].SESION_ID);
        
        const tokenDecode = parseJwt(response.Data.AuthenticationResult.Token);
        localStorage.setItem(KeyValue.KEY_ACCESS_SERVICE_RESPONSE,tokenDecode.AccesoPresupuesto);
    }
    const parseJwt = (token) => {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace('-', '+').replace('_', '/');
        //console.log(base64)
        //console.log(b64DecodeUnicode(base64));
        return JSON.parse(b64DecodeUnicode(base64));
    };
    const b64DecodeUnicode = (str)  => {
        return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        }).join(''))
    }

    const togglePassword = () =>{
        setIsRevealPassword(!isRevealPassword);
    }
    const handlePwdKeyUp = (e) =>{
        console.log(e.keyCode);
        if (e.keyCode === 13) {
            validateFields();
        }
    }

    if(goMain){
        //Setear el tab inicial
        localStorage.setItem('tabCurrent', 0);
        localStorage.setItem('tabHistorialEdit', 0)
        return <Redirect to="/Main" />
    }


    return (
        <div style={{marginRight:'auto',marginLeft:'auto',width:300}}>
            <Grid container /*justify="center" alignItems="center" spacing={1}*/  >
        
                <Grid item xs={12} >
                    <TextFieldLogin
                        id="txtUsername"
                        label="Usuario"
                        value={username}
                        onChange={handleChangeUsername}
                        className="widthCustom"
                        autoComplete="off"
                        //onKeyPress={(e) => runScript(e)}
                    /> 
                </Grid>
                
                <Grid item xs={12} style={{marginTop:5}}>
                    <TextFieldLogin
                        id="txtPassword"
                        label="Contraseña"
                        type={isRevealPassword? 'text' : 'password'}
                        value={password}
                        onChange={handleChangePassword}
                        className="widthCustom"
                        autoComplete="off"
                        onKeyUp={handlePwdKeyUp}
                        ref={inputPasswordRef}
                        //onKeyPress={(e) => runScript(e)}
                    />
                    <span onClick={togglePassword}>
                        <span>
                            {
                                isRevealPassword? (
                                    <EyeIcon className="customIcon" />
                                ):( <EyeSlashIcon className="customIcon" /> )
                            }
                        </span>
                    </span>
                </Grid>

                <Grid item xs={12} style={{marginTop:30}}>
                    <Button
                        //variant="contained" 
                        color="primary"
                        onClick={validateFields}
                        className="buttonCustom "
                    >
                        Entrar
                    </Button>
                </Grid>
                <VersionApp />
                <AlertDialog open={show} message={messageError} handleClose={handleCloseDialog}/>
                <Progress open={showProgress} />
            </Grid>
        </div>
    
  );
}

