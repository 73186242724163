import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { Redirect } from 'react-router-dom';
import ButtonReturnTray from '../button/ButtonReturnTray';


const MessageConfirm = () => {
    const [returnTray, setReturnTray] = React.useState(false);

    const handleReturn = () =>{        
        setReturnTray(true);
    }

    setTimeout(()=>{
        setReturnTray(true)
    },10000);

    if(returnTray){
        localStorage.setItem('tabCurrent',0)
        return <Redirect to="/Main" />
    }

    return (
        <Grid container style={{marginTop:100, textAlign: "center"}}>
            <Grid item xs={12}>
                <Typography variant="h4">
                ¡Genial!
                </Typography>                
            </Grid>

            <Grid item xs={12} style={{marginTop:30}}>
                <Typography>
                    Se realizó satisfactoriamente la confirmación del registro.
                </Typography>                 
            </Grid>

            <Grid item xs={12} style={{marginTop:30}}>
                <ButtonReturnTray handleClick={handleReturn}/>
            </Grid>
            
        </Grid>
    )
}

export default MessageConfirm
