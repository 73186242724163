import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { Grid } from '@material-ui/core';
import KeyValue from '../../config/KeyValue';
import UnauthorizedDialog from '../dialogs/UnauthorizedDialog';
import Progress from '../progress/Progress';
import AlertDialog from '../dialogs/AlertDialog';
import {TextField} from '@material-ui/core';
import ButtonAddRegisterBudgeted from '../button/ButtonAddRegisterBudgeted';
import './Dialog.css';
import ButtonCloseNewPersonBudgeted from '../button/ButtonCloseNewRegisterBudgeted';
import ButtonSaveAmountPopupBudgeted from '../button/ButtonSaveAmountPopupBudgeted';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PersonBudgetedDialog(props) {

  const [amountPersonBudgeted, setAmountPersonBudgeted] = React.useState('');
  const [codeBudgeted, setCodeBudgeted] = React.useState('');
  const [messageResult, setMessageResult] = React.useState('');
  const [showResult, setShowResult] = React.useState(false);
  //------------------------flag vencimiento de token------------------------
  const [showUnauthorized, setShowUnauthorized] = React.useState(false);
  //--------------

  //----loader----
  const [loading, setLoading] = React.useState(false);
  //---message error-----
  const [show, setShow] = React.useState(false);
  const [messageError, setMessageError] = React.useState(false);    
  //---------
  const handleClose = () => {
      setShow(false)       
  };
  const ShowDialogMessage = (message) =>{
      setShow(true);
      setMessageError(message);
  }
  //----------- impl method async ------------------------------------
  const asyncCallMethod = (typeCall,obj,methodAction,typeAction) =>{
      const URL_BASE = process.env.REACT_APP_URL_BASE_PORT;
      const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN) 

      const paramsHeadersPost = {
          method:typeCall,
          mode: 'cors',
          headers: {
              'Accept':'application/json',
              'Authorization': 'Bearer ' + tokenLocalStorage,
              'Content-type': 'application/json'
          },
          body: JSON.stringify(obj)
      }
      const paramsHeadersGet = {
          method:typeCall,
          mode: 'cors',
          headers: {
              'Accept':'application/json',
              'Authorization': 'Bearer ' + tokenLocalStorage,
              'Content-type': 'application/json'
          }
      }
      const header = obj !== null ? paramsHeadersPost : paramsHeadersGet ;
      const URL = URL_BASE+methodAction;    
      setLoading(true);    
          try{
              fetch(URL,header).then(function(res){
              if(res.status === 401){
                  return 401;
              }
              return res.json();
              }).then(function(response){    
              setLoading(false);

              if(response === 401){
                  setShowUnauthorized(true);

              }else if(response.Code === 0){
                  SetConfigResponse(response,typeAction);                    

              }else if (response.Code === 1){
                  errorCode1(response);
          
              }else if (response.Code === 2){
                  errorCode2(response);
              }
              }).catch((error) =>{
              setLoading(false);
              console.log(error);
              })
          }catch(e){
          setLoading(false);
          console.log(e);
          }                 
  }
  const SetConfigResponse = (response,typeAction)=>{
      if(typeAction === KeyValue.ACTION_UPDATE_AMOUNT_PERSON_BUDGETED){
          ResponseUpdateAmountPersonBudgeted(response);
      }
  }
  const errorCode1 = (response) =>{
      var message = "";
      if(response.Data.StatusCode === 500){
          ShowDialogMessage(response.Data.Message);
      }
      else if(response.Data.Errors.length > 0){
          response.Data.Errors.forEach(element => {
              //console.log(element);
              message = `${element}.`
          });
          ShowDialogMessage(message);                        
      }
  }
  const errorCode2 = (response) =>{
      var message = "";
      if(response.Data.Errors.length > 0){
          response.Data.Errors.forEach(element => {
              //console.log(element);
              message = message +  ` ${element.FieldName}. ${element.Message}. `
          });
          ShowDialogMessage(message);                        
      }
  }
  //-------------------INICIO ---------------------------------
  const eventListener = React.useCallback(() =>{
  },[props.init])

  React.useEffect(() =>{
    if(props.init === 1){
      setShowResult(false)
    }
    setAmountPersonBudgeted(props.amount);
    setCodeBudgeted(props.code);

  },[eventListener])

  /*********** ACTUALIZAR CANTIDAD***************** */
  const handleUpdateAmount = () =>{
    if(amountPersonBudgeted !== ""){      
      const obj = {
        PresupuestoCode: codeBudgeted,
        NroPresupuesto: parseInt(amountPersonBudgeted),
      };      
      UpdateAmountBudgeted(obj);

    }else{
      ShowDialogMessage('Debe ingresar una cantidad.')
    }
  }
  const UpdateAmountBudgeted = (obj) =>{
    const methodAction = process.env.REACT_APP_API_UPDATE_AMOUNT_BUDGETED;
    asyncCallMethod("post",obj,methodAction,KeyValue.ACTION_UPDATE_AMOUNT_PERSON_BUDGETED);
  }
  const ResponseUpdateAmountPersonBudgeted = (response) =>{
    console.log(response);
    if (response.Data.Success){
      setMessageResult(response.Data.Message);
      setShowResult(true);
    }else{
      ShowDialogMessage(response.Data.Message);
    }
  }
  /************** HANDLE EVENTS **********************/
  const onChangeAmountPersonBudgeted = (e) => {
    setAmountPersonBudgeted(e.target.value)
  }

  return (    
      <Dialog
        open={props.open}
        //TransitionComponent={Transition}
        keepMounted
        //onClose={props.handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle 
          id="alert-dialog-slide-title" 
          //className={showResult? 'hideDiv' : 'showDiv'}
          style={{textAlign:"center"}}>
            {"Edición de cantidad"}
        </DialogTitle>
        <DialogContent style={{width:300}}>
          <Grid container className={showResult? 'hideDiv' : 'showDiv'}>
            <Grid item xs={12} style={{textAlign:'center',marginBottom:20}}>
              <TextField
                id='inputIdAmountDialog'
                label="Cantidad"
                type="number"                        
                style={{color:'black',textAlign:'center'}}
                value={amountPersonBudgeted}
                onChange={onChangeAmountPersonBudgeted}                            
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={6}>
                  <ButtonCloseNewPersonBudgeted  onClick={props.handleClose} />
                </Grid>
                <Grid item xs={6}>
                  <ButtonSaveAmountPopupBudgeted name={'Guardar'} handleClick={handleUpdateAmount} />
                </Grid>
              </Grid>
            </Grid>            
          </Grid>
          
          <DialogContentText 
            id="alert-dialog-slide-description" 
            className={showResult? 'showDiv' : 'hideDiv'}
            style={{textAlign:'center'}}>
            {messageResult}
          </DialogContentText>          
        </DialogContent>
        <DialogActions className={showResult? 'showDiv' : 'hideDiv'}>
          <ButtonCloseNewPersonBudgeted name={'Cerrar'} onClick={props.handleClose} />          
        </DialogActions>
        
        <UnauthorizedDialog open={showUnauthorized} />
        <AlertDialog open={show} message={messageError} handleClose={handleClose}/>
        <Progress open={loading} />
      </Dialog>
  );
}
