import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import './Button.css'

const useStyles = makeStyles((theme) => ({    
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  padd:{
    paddingLeft:20,
    paddingRight:20,
    paddingBottom:15
  },
}));

export default function ButtonCloseDetail(props) {  
  const classes = useStyles();
  
  var width='width40';

  if(props.hide){
    width='width100';
  }

  return (
    <div className={classes.root, classes.padd} >
        <Fab 
            variant="extended"
            className={"colorBtnCloseBackground letterCapitalize "+width}
            onClick={props.handleClick}
        >
            Cerrar
        </Fab>
    </div>
  );
}
