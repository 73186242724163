const KeyValue = {
    /** días de la semana */
    CHECK_ALL_DAYS: 'Todos',
    CHECK_MONDAY: 'Lun',
    CHECK_TUESDAY: 'Mar',
    CHECK_WEDNESDAY: 'Mie',
    CHECK_THURSDAY: 'Jue',
    CHECK_FRIDAY: 'Vie',
    CHECK_SATURDAY: 'Sab',
    CHECK_SUNDAY: 'Dom',
    /*** */
    /** llaves */
    KEY_ACCESS_SERVICE_RESPONSE: "ACCESS-BUDGET",
    KEY_APP_ACCESS_BUDGET_AUTHORIZED: "1",
    KEY_APP_ACCESS_BUDGET_DENIED: "0",
    KEY_APP_ES_USUARIO_VISOR: "EsUsuarioVisor",
    /*** */
    /***TIPOS DE ACCIONES API REST */
    ACTION_GET_LIST_AREA: 1,
    ACTION_GET_LIST_COMPANY: 2,
    ACTION_GET_LIST_BUDGETED: 3,
    ACTION_UPDATE_AMOUNT_PERSON_BUDGETED: 4,
    ACTION_SAVE_REGISTER_BUDGETED: 5,
    ACTION_GET_DETAIL_AUDIT: 6,
    ACTION_DOWNLOAD_EXCEL_BUDGETED: 7,
    /** KEY VALUE STORAGE */
    KEY_LIST_AREA_BUDGETED: 'list-area-budgeted',
    //************ */
    VERSION_APP: 'Version 1.2'

}
export default KeyValue