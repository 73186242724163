import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddCircleIcon from '@material-ui/icons/AddCircle';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

export default function ButtonPlusImage(props) {
  const classes = useStyles();

    return (
        <Fab 
            color="secondary" 
            aria-label="add" 
            className={classes.margin,props.border}
            style={{borderRadius:'20%',backgroundColor:'#009DE4'}}
            onClick={props.handleClick}
            
        >
            <AddCircleIcon />
        </Fab>
    );
}
